import type { ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { OrganizationEntity } from 'entities/rw/organization/Organization';
import type { RoleEntityBase } from 'entities/rw/Role';
import { createRoleFields } from 'entities/rw/Role';
import type { UserEntity } from 'entities/rw/user/User';

export interface OrganizationRoleEntity extends RoleEntityBase {
    organization: OrganizationEntity;
    user: UserEntity;
}

const shorthand: ShorthandEntityDefinition<OrganizationRoleEntity> = {
    key: rw.organization.Role,
    shortLabel: 'Membership',
    creatable: true,
    softDeletable: true,
    fields: createRoleFields(rw.organization.Organization, rw.user.User),
};

export default shorthand;

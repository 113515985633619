import { allEntities } from 'entities/Entities'; // important that this is imported before `App`, as it resolves circular dependency issues.
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import 'antd/dist/antd.css';
import App from 'App';
import 'app.scss';
import React from 'react';
import ReactDOM from 'react-dom';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from 'serviceWorker';

import { isDev } from 'util/environment';

// Only here to reference this var, to make the compiler include it up top to resolve circular deps.
console.log(allEntities.length);

console.log('REACT_APP_ENVIRONMENT:', process.env.REACT_APP_ENVIRONMENT);
console.log('REACT_APP_VERSION:', process.env.REACT_APP_VERSION);
console.log('REACT_APP_GRAPHQL_HOST:', process.env.REACT_APP_GRAPHQL_HOST);

if (!isDev() && process.env.REACT_APP_ENVIRONMENT) {
    Sentry.init({
        dsn: 'https://c7cf3f94e97b4d0b90e38bcc3d967f59@sentry.srv.axds.co/12',
        environment: process.env.REACT_APP_ENVIRONMENT,
        release: `workspace-admin@${process.env.REACT_APP_VERSION}`,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 0.05,
        ignoreErrors: [/ResizeObserver loop limit exceeded/], // https://stackoverflow.com/questions/49384120/resizeobserver-loop-limit-exceeded
        beforeSend(event) {
            if (event.exception) {
                Sentry.showReportDialog({ eventId: event.event_id });
            }
            return event;
        },
    });
}

ReactDOM.render(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

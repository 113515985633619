import {
    ArrowLeftOutlined,
    ArrowRightOutlined,
    CloseCircleOutlined,
    CloseOutlined,
    DeleteOutlined,
    EditOutlined,
    PlusOutlined,
    WarningOutlined,
} from '@ant-design/icons';
import { Alert, Button, Form, Input, InputNumber, List, Row, Select, Space, Switch, Tooltip } from 'antd';
import { useAuth } from 'AuthContext';
import { EntityFormProps } from 'components/form/EntityFormProps';
import SubmitButton, { SubmitOptions } from 'components/form/SubmitButton';
import EntitySearchSelect from 'components/search/EntitySearchSelect';
import ExternalLink from 'components/text/ExternalLink';
import { entityByKey } from 'entities/Entities';
import type { Entity, ObjectLiteral } from 'entities/EntityDefinition';
import {
    EntityDefinition,
    findField,
    getEntityDefinitionLabel,
    getIdPath,
    getIndexPath,
    isEditable,
} from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import {
    convertStringFieldValue,
    EntityFields,
    Field,
    FieldType,
    formatEnumOption,
    formatField,
    isFieldEditable,
    isMany,
    isOne,
    isRelation,
    OneField,
} from 'entities/Field';
import Event, { EventType, EventTypeType } from 'entities/rw/event/Event';
import { print } from 'graphql';
import gql from 'graphql-tag';
import { History } from 'history';
import pluralize from 'pluralize';
import React, { ReactElement, useEffect, useState } from 'react';
import colors from 'util/colors';
import { crudResponseGraphQl, fieldsMutation } from 'util/graphql';
import { capitalize } from 'util/string';
import { useQueryParams } from 'util/url';

const { useForm } = Form;

const transformRelationValuesForMutation = (values: ObjectLiteral, fields: EntityFields) => {
    const oneRelationKeys = Object.keys(values).filter(key => fields.some(field => isOne(field) && field.key === key));
    oneRelationKeys.forEach(relationKey => {
        values[`${relationKey}Id`] = values[relationKey]?.id;
        delete values[relationKey];
    });
    // Note that unchanged keys have `undefined` values, and thus won't get updated in the mutation.
    return values;
};

interface RelationFieldProps<EntityType extends Entity> {
    field: Field | OneField;
    isCreate?: boolean;
    value?: EntityType;
    onChange: (value?: EntityType) => void;
    entityKey?: string;
}

function RelationFormField<EntityType extends Entity>({
    field,
    isCreate,
    value,
    onChange,
    entityKey,
}: RelationFieldProps<EntityType>) {
    const { editable, label } = field;
    const allowEdits = editable || isCreate;
    const [editing, setEditing] = useState<boolean | undefined>(allowEdits && (!value || isCreate));
    const [initialValue] = useState(value);

    entityKey = entityKey || (isOne(field) ? field.one : undefined);
    if (!entityKey) return null;

    const entityDefinition = entityByKey[entityKey];
    if (!entityDefinition) return null;

    return (
        <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center' }}>
            {editable && !!value && (
                <Tooltip title={editing ? `Cancel ${label} change` : `Change ${label}`}>
                    <Button
                        type="link"
                        icon={editing ? <CloseCircleOutlined /> : <EditOutlined />}
                        onClick={() => {
                            if (editing && onChange) onChange(initialValue);
                            setEditing(!editing);
                        }}
                    />
                </Tooltip>
            )}
            <EntitySearchSelect value={value} onChange={onChange} entityDefinition={entityDefinition} editing={editing} />
        </div>
    );
}

interface FormItemContentsProps<EntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    entity?: EntityType;
    field: Field | OneField;
    isCreate: boolean | undefined;
    value?: any;
    onChange?: (value: any) => void;
    isUserViewingSelf?: boolean;
    dependsOnField?: Field;
    mutationValues?: EntityType;
}

function FormItemContents<EntityType extends Entity>({
    entityDefinition,
    entity,
    field,
    isCreate,
    value,
    onChange,
    isUserViewingSelf,
    dependsOnField,
    mutationValues,
}: FormItemContentsProps<EntityType>): ReactElement {
    const { key, editable, required, default: defaultValue } = field; // TODO only boolean default is handled currently
    const valueForDependsOnField = dependsOnField && (mutationValues?.[dependsOnField.key] || entity?.[dependsOnField.key]);

    if (dependsOnField && !valueForDependsOnField) {
        return (
            <span style={{ fontStyle: 'italic' }}>
                A value for the <span style={{ fontWeight: 'bold' }}>'{capitalize(dependsOnField.label)}'</span> field must be
                provided before <span style={{ fontWeight: 'bold' }}>'{capitalize(field.label)}'</span>.
            </span>
        );
    }

    const valueInEntity = entity?.[key];
    if (isOne(field) || field.type === FieldType.entityId) {
        const convertValueToEntity = (value?: any): EntityType | undefined => {
            if (value === undefined) return undefined;
            if (typeof value === 'number') return { id: value } as EntityType;
            if (typeof value === 'string') return { id: Number(value) } as EntityType;
            return value;
        };

        return (
            <RelationFormField
                field={field}
                isCreate={isCreate}
                value={value ? convertValueToEntity(value) : convertValueToEntity(valueInEntity)}
                onChange={(value?: EntityType) => {
                    onChange?.(value);
                }}
                entityKey={dependsOnField?.key === 'entityKey' ? valueForDependsOnField : undefined}
            />
        );
    }

    const externalLink = entity && entityDefinition.createExternalFieldLink?.(entity, key);
    if (externalLink) return <ExternalLink href={externalLink}>{formatField(valueInEntity)}</ExternalLink>;

    const { type, options } = field;
    if (editable || (isCreate && required)) {
        switch (type) {
            case undefined:
            case FieldType.string:
            case FieldType.email:
                // See https://ant.design/components/input/#FAQ for explanation on empty <span>
                return <Input value={value} onChange={onChange} suffix={<span />} />;
            case FieldType.longString:
                // TODO add editing toggle, and only show in text area when editing
                return <Input.TextArea value={value} onChange={onChange} rows={10} />;
            case FieldType.boolean:
                return <Switch checked={value} onChange={onChange} defaultChecked={defaultValue} />;
            case FieldType.number:
                return <InputNumber value={value} onChange={onChange} min={0} />;
            case FieldType.password:
                // Only use a password type field if the user is viewing their own profile.
                // Otherwise, just show a plaintext placeholder value if the user has a password set.
                // This avoids browsers like FireFox bugging you to save your password every time you
                // navigate away from another user's profile. Downside is that manually setting another user's
                // password does not allow for a hidden input mode. But this should basically never happen anyway.
                return isUserViewingSelf ? (
                    <Input.Password value={value} onChange={onChange} suffix={<span />} />
                ) : (
                    <Input
                        value={value}
                        onChange={onChange}
                        defaultValue={entity?.hasPassword ? '<<hidden_user_password>>' : undefined}
                        suffix={<span />}
                    />
                );
            case FieldType.enum:
            case FieldType.enumList:
            case FieldType.jsonPrimitiveList:
                return (
                    <Select
                        value={value || (type !== FieldType.enum ? [] : undefined)}
                        onChange={onChange}
                        mode={type !== FieldType.enum ? 'tags' : undefined}
                        placeholder={`Select ${field.label}`}
                    >
                        {(options || []).map(option => (
                            <Select.Option key={option} value={option}>
                                {formatEnumOption(option, field)}
                            </Select.Option>
                        ))}
                    </Select>
                );
        }
    }
    if (type === FieldType.htmlString) return <div dangerouslySetInnerHTML={{ __html: valueInEntity }} />;

    return <span>{formatField(valueInEntity, field, { shouldTruncate: false })}</span>;
}

const getChangedValues = (initialValues: Record<string, unknown>, newValues: Record<string, unknown>) =>
    Object.fromEntries(
        Object.entries(newValues).filter(([key, newValue]) => {
            const initialValue = initialValues[key];

            // For `FieldType:boolean` fields (e.g. `published`):
            // If the field was `null` or `undefined`, then toggled to `true`,
            // then toggled back (to `false`), do not show as changed.
            if ((initialValue === undefined || initialValue === null) && newValue === false) return false;

            // If the field was `undefined`, then toggled to set a date string of now,
            // then toggled back (to `null`), do not show as changed.
            if (initialValue === undefined && newValue === null) return false;

            // For string fields that start as empty, get changed, then get set back to empty.
            if (initialValue === undefined && newValue === '') return false;

            // For `FieldType:enum` fields (e.g. `roles`):
            // If the field was `null` or `undefined`, then toggled to a non-empty list,
            // then toggled back (to `[]`), do not show as changed.
            if ((initialValue === undefined || initialValue === null) && Array.isArray(newValue) && newValue.length === 0)
                return false;

            return JSON.stringify(initialValue) !== JSON.stringify(newValue);
        })
    );

const filterEmptyStringValues = (values: Record<string, unknown>) =>
    Object.fromEntries(Object.entries(values).filter(([_, value]) => !(typeof value === 'string' && value === '')));

export default function EntityForm<EntityType extends Entity>({
    entityDefinition,
    initialEntity,
    submits = [],
    initialValues = {},
    hideFields,
    hideSubmits,
    children,
}: EntityFormProps<EntityType>): ReactElement {
    const [form] = useForm();
    const { authenticatedUser } = useAuth();
    const [errorMessage, setErrorMessage] = useState<string>('');
    const [changedValues, setChangedValues] = useState<Record<string, unknown>>({});
    const [entity, setEntity] = useState<EntityType | undefined>(initialEntity);

    const isCreate = !entity;
    const id = entity?.id;
    const { key, creatable, softDeletable, deletable } = entityDefinition;
    const fields = entityDefinition.fields.filter(field => !isMany(field)) as (Field | OneField)[];

    // Fields can be pre-populated via URL params, e.g. '/subscription/create?subscriberId=1&entityKey=organization&entityId=123'
    const urlFieldValues = useQueryParams();
    useEffect(() => {
        if (Object.keys(urlFieldValues).length > 0) {
            const convertedUrlFieldValues = Object.fromEntries(
                Object.entries(urlFieldValues)
                    .map(([k, v]) => {
                        const field = findField(entityDefinition, k);
                        return field ? [k, convertStringFieldValue(field, v)] : [undefined, undefined];
                    })
                    .filter(([k]) => k)
            );
            form.setFieldsValue(convertedUrlFieldValues);
            setChangedValues({}); // Hack: `form.setFieldsValue` doesn't trigger a re-render, so let's trivially change state (this is the initial render).
        }
    }, []);

    const editable = isEditable(entityDefinition);
    const isUserViewingSelf = key === rw.user.User && !!id && id === authenticatedUser?.id;

    const submitTypes: EventTypeType[] = [];
    if (isCreate) {
        if (creatable) submitTypes.push(EventType.create);
    } else {
        if (editable) submitTypes.push(EventType.update);
        if (softDeletable) {
            if (entity.deleted) submitTypes.push(EventType.restore);
            else submitTypes.push(EventType.softdelete);
        }
        if (deletable) submitTypes.push(EventType.delete);
    }
    if (entityDefinition.key === rw.event.Event) submitTypes.push(EventType.undo);

    const label = getEntityDefinitionLabel(entityDefinition);
    const editableFields = fields.filter(field => isFieldEditable(field, isCreate));
    const missingRequiredFields = editableFields.some(({ key, required }) => required && (!key || !form.getFieldValue(key)));
    const hasErrors = form.getFieldsError().some(({ errors }) => errors.length);
    const createOrUpdateEnabled = !hasErrors && (isCreate ? !missingRequiredFields : Object.keys(changedValues).length);
    // Create form may have values pre-populated, but we want all fields sent.
    // But like in the update case, we need to filter out fields where the user entered text and deleted it (which results in empty string values).
    const mutationValues = isCreate ? (filterEmptyStringValues(form.getFieldsValue(true)) as any) : changedValues;

    const setEntityValues = (response: ObjectLiteral) => {
        const entity = response[Object.keys(response)[0]] as EntityType;
        setEntity(entity);
        form.resetFields();
        setChangedValues({});
    };

    // TODO use `formatEvent` to generate messages & messageKeys
    // It's not noticing that all types are covered.
    // eslint-disable-next-line array-callback-return
    const primarySubmits: SubmitOptions<EntityType>[] = submitTypes.map(type => {
        switch (type) {
            case EventType.create:
                return {
                    messageKey: 'create',
                    type: EventType.create,
                    children: (
                        <>
                            <PlusOutlined /> Create {label}
                        </>
                    ),
                    loadingMessage: `Creating ${label}...`,
                    successMessage: `${capitalize(label)} created`,
                    mutationValues,
                    transformMutationValues: values => transformRelationValuesForMutation(values, entityDefinition.fields),
                    submitMutation: fieldsMutation(entityDefinition, true),
                    onSubmitComplete: (response, history: History) => {
                        const id = response[`create${capitalize(key)}`]?.id;
                        if (id) history.push(getIdPath(entityDefinition, id));
                    },
                    onError: error => setErrorMessage(error.message),
                    disabled: !createOrUpdateEnabled,
                };
            case EventType.update:
                return {
                    messageKey: 'update',
                    type: EventType.update,
                    children: `Update ${label}`,
                    loadingMessage: `Updating ${label}...`,
                    successMessage: `${capitalize(label)} updated`,
                    mutationValues,
                    onSubmitComplete: response => setEntityValues(response),
                    transformMutationValues: values =>
                        transformRelationValuesForMutation({ id, ...values }, entityDefinition.fields),
                    submitMutation: fieldsMutation(entityDefinition, false),
                    onError: error => setErrorMessage(error.message),
                    disabled: !createOrUpdateEnabled,
                };
            case EventType.softdelete:
                return {
                    messageKey: 'soft-delete',
                    type: EventType.softdelete,
                    children: (
                        <>
                            <ArrowRightOutlined style={{ fontSize: 9, lineHeight: 1, verticalAlign: 'middle' }} />
                            <DeleteOutlined /> Delete {label}
                        </>
                    ),
                    help: `Move this ${label.toLowerCase()} to the recycling bin. You will still be able to restore it at any time.`,
                    loadingMessage: `Soft-deleting ${label}...`,
                    successMessage: `${capitalize(label)} soft-deleted`,
                    mutationValues,
                    transformMutationValues: () => ({ id }),
                    onSubmitComplete: response => setEntityValues(response),
                    submitMutation: gql`mutation($id: Float!, $preview: Boolean) { softDelete${capitalize(
                        key
                    )}(id: $id, preview: $preview) ${print(crudResponseGraphQl(entityDefinition))} }`,
                    danger: true,
                    dashed: true,
                    onError: error => setErrorMessage(error.message),
                };
            case EventType.restore:
                return {
                    messageKey: 'restore',
                    type: EventType.restore,
                    children: (
                        <>
                            <ArrowLeftOutlined style={{ fontSize: 9, lineHeight: 1, verticalAlign: 'middle' }} />
                            <DeleteOutlined /> Restore {label}
                        </>
                    ),
                    help: `Move this ${label} out of the recycling bin`,
                    loadingMessage: `Restoring ${label}...`,
                    successMessage: `${capitalize(label)} restored`,
                    mutationValues,
                    transformMutationValues: () => ({ id }),
                    onSubmitComplete: response => setEntityValues(response),
                    submitMutation: gql`mutation($id: Float!, $preview: Boolean) { restore${capitalize(
                        key
                    )}(id: $id, preview: $preview) ${print(crudResponseGraphQl(entityDefinition))} }`,
                    success: true,
                    dashed: true,
                    onError: error => setErrorMessage(error.message),
                };
            case EventType.delete:
                return {
                    messageKey: 'delete',
                    type: EventType.delete,
                    children: (
                        <>
                            <CloseOutlined /> Delete {label}
                        </>
                    ),
                    help: `Permanently delete this ${label}`,
                    loadingMessage: `Deleting ${label}...`,
                    successMessage: `${capitalize(label)} deleted`,
                    mutationValues,
                    transformMutationValues: () => ({ id }),
                    submitMutation: gql`mutation($id: Float!, $preview: Boolean) { delete${capitalize(
                        key
                    )}(id: $id, preview: $preview) { id event { id } } }`,
                    onSubmitComplete: (_, history) => history.push(getIndexPath(entityDefinition)),
                    onError: error => setErrorMessage(error.message),
                    danger: true,
                    modal: {
                        title: (
                            <Space>
                                <WarningOutlined style={{ color: colors.warning, fontSize: 20 }} />
                                <>Delete {label} confirmation</>
                            </Space>
                        ),
                        contents: (
                            <>
                                <p>
                                    Are you sure you want to completely remove this {label} and its related entities from the DB?
                                </p>
                                {softDeletable && <p style={{ fontSize: 12 }}>(You may just want to soft-delete the {label}.)</p>}
                            </>
                        ),
                        okText: 'Delete',
                        okType: 'danger',
                    },
                };
            case EventType.undo:
                return {
                    messageKey: 'undo',
                    type: EventType.undo,
                    children: `Undo ${label}`,
                    loadingMessage: `Undoing ${label}...`,
                    successMessage: `${capitalize(label)} undone`,
                    help: 'Undo the effects of this event.',
                    mutationValues,
                    transformMutationValues: () => ({ id }),
                    submitMutation: Event.mutations?.undoEvent,
                    onError: error => setErrorMessage(error.message),
                };
        }
    });

    const combinedInitialValues = {
        ...initialValues,
        ...Object.fromEntries(
            editableFields.filter(({ key }) => key && !(key in initialValues)).map(({ key }) => [key, key && entity?.[key]])
        ),
    };

    const links = entity && entityDefinition.createLinks?.(entity);

    return (
        <>
            <Form
                form={form}
                labelCol={{ sm: { span: 10 }, md: { span: 6 }, lg: { span: 4 }, xl: { span: 3 } }}
                validateMessages={{
                    // eslint-disable-next-line no-template-curly-in-string
                    required: '${label} is required',
                    types: {
                        email: 'Not a valid email',
                    },
                }}
                onFieldsChange={() => setChangedValues(getChangedValues(combinedInitialValues, form.getFieldsValue(true)))}
                initialValues={combinedInitialValues}
            >
                {links && (
                    <Form.Item label="Links">
                        <List
                            size="small"
                            bordered
                            dataSource={links}
                            renderItem={({ label, href }) => (
                                <List.Item>
                                    <ExternalLink href={href}>{label}</ExternalLink>
                                </List.Item>
                            )}
                        />
                    </Form.Item>
                )}
                {!hideFields &&
                    fields.map(field => {
                        const { key, hide, hideNull, excludeFromCreate, required, editable, help, dependsOnField } = field;
                        const value = entity?.[key];
                        if (hide || (isCreate && (excludeFromCreate || !(editable || required)))) return undefined;
                        if (hideNull && (value === undefined || value === null)) return undefined;

                        const allowEdits = editable || (isCreate && required);
                        return (
                            <Form.Item
                                key={key}
                                label={capitalize(field.label)}
                                name={allowEdits ? key : undefined}
                                rules={[
                                    {
                                        required,
                                        ...(!isRelation(field) && field.type === FieldType.email ? { type: 'email' } : {}),
                                    },
                                ]}
                                hasFeedback={key in changedValues && allowEdits}
                                extra={!isOne(field) && entity && entityDefinition.createFieldExtra?.(entity, key)}
                                tooltip={
                                    help ||
                                    (key === 'deleted'
                                        ? `Deleted ${pluralize(label)} are hidden, but are still in the database.`
                                        : undefined)
                                }
                            >
                                <FormItemContents
                                    entityDefinition={entityDefinition}
                                    entity={entity}
                                    field={field}
                                    isCreate={isCreate}
                                    isUserViewingSelf={isUserViewingSelf}
                                    dependsOnField={dependsOnField && findField(entityDefinition, dependsOnField.key)}
                                    mutationValues={mutationValues}
                                />
                            </Form.Item>
                        );
                    })}
                {children}
                {!hideSubmits && (
                    <Form.Item wrapperCol={hideFields ? {} : { md: { offset: 6 }, lg: { offset: 4 }, xl: { offset: 3 } }}>
                        {primarySubmits.length > 0 && (
                            <Row style={hideFields ? { marginTop: 8 } : {}}>
                                <Space>
                                    {primarySubmits.map(submit => (
                                        <SubmitButton
                                            key={submit.messageKey}
                                            entityDefinition={entityDefinition}
                                            {...submit}
                                            modal={
                                                ((submit.type === EventType.create || submit.type === EventType.update) &&
                                                    entityDefinition.createUpdateConfirmationModal?.(
                                                        entityByKey,
                                                        { ...(entity || {}), ...mutationValues },
                                                        submit.type
                                                    )) ||
                                                submit.modal
                                            }
                                        />
                                    ))}
                                </Space>
                            </Row>
                        )}
                        {submits.length > 0 && (
                            <Row style={{ marginTop: 8 }}>
                                <Space align="start">
                                    {submits.map(submit => (
                                        <SubmitButton
                                            key={submit.messageKey}
                                            entityDefinition={entityDefinition}
                                            mutationValues={mutationValues}
                                            onError={error => setErrorMessage(error.message)}
                                            {...submit}
                                        />
                                    ))}
                                </Space>
                            </Row>
                        )}
                    </Form.Item>
                )}
            </Form>
            {!!errorMessage && <Alert message={errorMessage} type="error" />}
        </>
    );
}

import { Entity, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { oikos, rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { CampaignEntity } from 'entities/rw/campaign/Campaign';
import type { OrganizationEntity } from 'entities/rw/organization/Organization';

export interface PortalEntity extends Entity {
    creationTime: Date;
    label: string;
    backgroundColor: string;
    backgroundColor2: string;
    minLng: number;
    minLat: number;
    maxLng: number;
    maxLat: number;
    baseLayerCode: string;
    zoom: number;
    config: string;
    feedbackSiteId: number;
    siteUrl: string;
    siteLogo: string;
    analyticsDomain: string;
    analyticsAccount: string;
    centerPointLat: number;
    centerPointLng: number;
    erddapUrl: string;
    configJson: string;
    stageConfigJson: string;
    organization: OrganizationEntity;
    campaign: CampaignEntity;
}

const shorthand: ShorthandEntityDefinition<PortalEntity> = {
    key: oikos.Portal,
    mainNav: true,
    labelFields: 'label',
    defaultSortField: 'label',
    immutable: true,
    fields: [
        { key: 'label', required: true },
        { key: 'description', type: FieldType.htmlString, excludeFromTable: true },
        { key: 'configJson', label: 'Config JSON', type: FieldType.json },
        { key: 'stageConfigJson', label: 'Stage config JSON', type: FieldType.json, excludeFromTable: true },
        { key: 'backgroundColor', excludeFromTable: true },
        { key: 'backgroundColor2', excludeFromTable: true },
        { key: 'minLng', type: FieldType.number, excludeFromTable: true },
        { key: 'minLat', type: FieldType.number, excludeFromTable: true },
        { key: 'maxLng', type: FieldType.number, excludeFromTable: true },
        { key: 'maxLat', type: FieldType.number, excludeFromTable: true },
        { key: 'baseLayerCode', excludeFromTable: true },
        { key: 'zoom', type: FieldType.number, excludeFromTable: true },
        { key: 'feedbackSiteId', type: FieldType.number, excludeFromTable: true },
        { key: 'siteUrl', excludeFromTable: true },
        { key: 'siteLogo', excludeFromTable: true },
        { key: 'analyticsDomain', excludeFromTable: true },
        { key: 'analyticsAccount', excludeFromTable: true },
        { key: 'centerPointLat', type: FieldType.number, excludeFromTable: true },
        { key: 'centerPointLng', type: FieldType.number, excludeFromTable: true },
        { key: 'config', label: 'Config file', type: FieldType.longString, excludeFromTable: true },
        { key: 'erddapUrl', excludeFromTable: true },
        { many: rw.portal.Organization, label: 'Organizations' },
        { many: rw.portal.Campaign, label: 'Campaigns' },
    ],
};

export default shorthand;

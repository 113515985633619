import type { DeletableEntityBase, EditProps, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { findField } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType, formatField } from 'entities/Field';
import { createExternalLink, createLinks } from 'entities/rw/archive/shared';
import type { CampaignEntity } from 'entities/rw/campaign/Campaign';
import type { FolderEntity } from 'entities/rw/file/Folder';
import type { ProjectEntity } from 'entities/rw/project/Project';
import type { UserEntity } from 'entities/rw/user/User';
import React from 'react';
import { capitalize } from 'util/string';

export interface ArchivePackageEntity extends DeletableEntityBase {
    submitted: string;
    received: string;
    indexed: string;
    doi: string;
    lastDoi: string;
    pid: string;
    metadataPid: string;
    submittedPid: string;
    citation: string;
    seriesId: string;
    metadataId: number;
    submitter: UserEntity;
    campaign: CampaignEntity;
    project: ProjectEntity;
    folder: FolderEntity;
    parentPackage: ArchivePackageEntity;
}

const shorthand: ShorthandEntityDefinition<ArchivePackageEntity> = {
    key: rw.archive.Package,
    label: 'archive package',
    shortLabel: 'package',
    labelFields: 'doi',
    softDeletable: true,
    fields: [
        { key: 'submitted', type: FieldType.date },
        { key: 'received', type: FieldType.date, excludeFromTable: true },
        { key: 'indexed', type: FieldType.date, excludeFromTable: true },
        { key: 'doi', label: 'DOI' },
        { key: 'lastDoi', label: 'Last DOI', hideNull: true, excludeFromTable: true },
        { key: 'pid', label: 'PID', excludeFromTable: true },
        { key: 'metadataPid', label: 'Metadata PID', hideNull: true, excludeFromTable: true },
        { key: 'submittedPid', label: 'Submitted PID', hideNull: true, excludeFromTable: true },
        { key: 'citation' },
        { key: 'seriesId', label: 'Series ID', hideNull: true, excludeFromTable: true },
        { key: 'submitter', one: rw.user.User },
        { one: rw.project.Project },
        { one: rw.file.Folder },
        { key: 'parentPackage', one: rw.archive.Package, excludeFromTable: true },
        { many: rw.archive.Package, label: 'Child packages' },
        { many: rw.archive.File },
        { many: rw.archive.Attachment, useShortLabel: true },
        { many: rw.archive.SubmissionHistory, useShortLabel: true },
    ],
    createLinks,
    createExternalLink,
    Edit: ({ EntityForm, entityDefinition, entity }: EditProps<ArchivePackageEntity>) => {
        const links = entityDefinition.createLinks?.(entity);
        const detailsForDataOneSlack = [
            ...['doi', 'pid', 'metadataPid', 'citation', 'submitted', 'received', 'indexed']
                .map(key => findField(entityDefinition, key))
                .map(field => {
                    if (!field) return undefined;

                    const { key } = field;
                    const getDisplayLabel = () => {
                        switch (key) {
                            case 'submitted':
                                return 'Submitted to RW member node';
                            case 'received':
                                return 'Found in D1 coordinating node';
                            case 'indexed':
                                return 'Indexed in D1 SOLR';
                            default:
                                return capitalize(field.label);
                        }
                    };
                    return {
                        label: getDisplayLabel(),
                        value: formatField(entity[key], field, { stringOnly: true, shouldTruncate: false }),
                    };
                }),
            ...(links ? links.slice(0, 4).map(({ label, href }) => ({ label, value: href })) : []),
        ].map(detailItem => {
            if (!detailItem) return '';
            const { label, value } = detailItem;
            return `${label}: ${value}`;
        });

        return (
            <EntityForm
                entityDefinition={entityDefinition}
                initialEntity={entity}
                submits={[
                    {
                        messageKey: 'copy',
                        children: 'Copy details for DataOne Slack',
                        loadingMessage: 'Copying details...',
                        successMessage: 'Details copied to clipboard',
                        doAction: async () => navigator.clipboard.writeText(detailsForDataOneSlack.join('\n')),
                        modal: {
                            title: 'Copy details for DataOne Slack',
                            okText: 'Copy',
                            contents: (
                                <div>
                                    {detailsForDataOneSlack.map(value => (
                                        <div key={value}>{value}</div>
                                    ))}
                                </div>
                            ),
                        },
                    },
                ]}
            />
        );
    },
};

export default shorthand;

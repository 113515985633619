import { Breadcrumb, Layout, message } from 'antd';
import EntityDetail from 'components/entity/EntityDetail';
import EntityIndex from 'components/entity/EntityIndex';
import MainHeader from 'components/MainHeader';
import { getIdPath, getIndexPath, getPageOrEntityDefinitionLabel } from 'entities/EntityDefinition';
import { PageIcon } from 'entities/EntityIcons';
import { findPage, SignInRoute } from 'Pages';
import React, { useEffect } from 'react';
import { Link, Redirect, Route, Switch, useLocation } from 'react-router-dom';
import { capitalize } from 'util/string';

const { Content } = Layout;

interface Props {
    pageKey: string;
    entityId?: string;
}

export default function MainContent({ pageKey, entityId }: Props) {
    const location = useLocation();
    const page = findPage(pageKey);

    useEffect(() => {
        const urlSearchParams = new URLSearchParams(location.search);
        if (urlSearchParams.get('require_auth')) {
            message.error('You need to sign in to perform this action');
        } else if (urlSearchParams.get('require_admin')) {
            message.error('You must be an admin to sign in to the admin app');
        }
    }, [location.search]);

    return (
        <Content style={{ margin: '0 16px' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, margin: 10 }}>
                <MainHeader />
            </div>
            {page && (
                <Breadcrumb style={{ margin: '16px 0' }}>
                    <Breadcrumb.Item>
                        <Link to={getIndexPath(page)}>
                            <PageIcon page={page} /> {capitalize(getPageOrEntityDefinitionLabel(page))}
                        </Link>
                    </Breadcrumb.Item>
                    {entityId && (
                        <Breadcrumb.Item>
                            <Link to={getIdPath(pageKey, entityId)}>#{entityId}</Link>
                        </Breadcrumb.Item>
                    )}
                </Breadcrumb>
            )}
            <div className="inner-content" style={{ background: '#fff', padding: '24px' }}>
                <Switch>
                    <Route exact path="/">
                        <Redirect to={`${getIndexPath(SignInRoute)}${location.search}`} />
                    </Route>
                    <Route key="entityIndex" path="/:pageKey/:tab?" exact component={() => <EntityIndex />} />
                    <Route key="entityDetail" path="/:pageKey/id/:id/:tab?/:tab2?" exact component={() => <EntityDetail />} />
                    <Route key="filestoreEntityIndex" path="/:pageKey/:tab/:path+" exact component={() => <EntityIndex />} />
                    <Route
                        component={() => (
                            <div>
                                I am sorry to inform you that, although I have several highly useful pages, this is not one of
                                them.
                            </div>
                        )}
                    />
                </Switch>
            </div>
        </Content>
    );
}

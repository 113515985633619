import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { ArchivePackageEntity } from 'entities/rw/archive/ArchivePackage';
import { createExternalLink, createLinks } from 'entities/rw/archive/shared';
import type { FileEntity } from 'entities/rw/file/File';
import type { FolderEntity } from 'entities/rw/file/Folder';
import type { ProjectEntity } from 'entities/rw/project/Project';

export interface ArchiveFileEntity extends DeletableEntityBase {
    submitted: string;
    pid: string;
    archivePackage: ArchivePackageEntity;
    project: ProjectEntity;
    folder: FolderEntity;
    file: FileEntity;
}

const shorthand: ShorthandEntityDefinition<ArchiveFileEntity> = {
    key: rw.archive.File,
    label: 'archive file',
    shortLabel: 'file',
    labelFields: 'pid',
    softDeletable: true,
    fields: [
        { key: 'submitted', type: FieldType.date },
        { key: 'pid', label: 'PID' },
        { one: rw.project.Project },
        { one: rw.file.Folder },
        { one: rw.file.File },
        { one: rw.archive.Package, useShortLabel: true, excludeFromTable: true },
    ],
    createLinks,
    createExternalLink,
};

export default shorthand;

import React from 'react';

export interface HighlighterProps {
    searchWord: string;
    textToHighlight: string;
}

interface Chunk {
    start: number;
    end: number;
    match: boolean;
}

function getChunks(search: string, text: string): Chunk[] {
    if (text.length === 0) return [];
    if (search.length === 0 || !text.includes(search)) {
        return [{ start: 0, end: text.length, match: false }];
    }

    let startIndex = 0;
    let index;
    const chunks: Chunk[] = [];
    while ((index = text.indexOf(search, startIndex)) > -1) {
        if (index !== startIndex) {
            // Fill in gap since last match
            chunks.push({ start: startIndex, end: index, match: false });
        }
        chunks.push({ start: index, end: index + search.length, match: true });
        startIndex = index + search.length;
    }
    // Fill in end gap since last match
    if (chunks[chunks.length - 1].end !== text.length) {
        chunks.push({ start: chunks[chunks.length - 1].end, end: text.length, match: false });
    }
    return chunks;
}

export default function Highlighter({ searchWord, textToHighlight }: HighlighterProps) {
    const chunks = getChunks(searchWord.toLowerCase(), textToHighlight.toLowerCase());
    return (
        <span>
            {chunks.map(({ start, end, match }) => {
                const segment = textToHighlight.slice(start, end);
                return match ? <mark key={start}>{segment}</mark> : <span key={start}>{segment}</span>;
            })}
        </span>
    );
}

import { DeleteOutlined, ExpandOutlined, ExportOutlined, ShrinkOutlined } from '@ant-design/icons';
import { Button, Col, Pagination, Row, Switch, Tooltip } from 'antd';
import ErrorComponent from 'components/error/Error';
import GraphQlExplorer from 'components/GraphQlExplorer';
import EntitiesTable from 'components/entity/EntitiesTable';
import FieldTreeSelect from 'components/search/FieldTreeSelect';
import FilterList from 'components/search/FilterList';
import SearchInput from 'components/search/SearchInput';
import { SortOrder } from 'components/search/SortOrder';
import deepmerge from 'deepmerge';
import { getRelationDefinition } from 'entities/Entities';
import type { EntitiesConnection, Entity, ObjectLiteral } from 'entities/EntityDefinition';
import { EntityDefinition, findField, findRelation, getEntityDefinitionLabel, getSearchKey } from 'entities/EntityDefinition';
import { CREATED_FIELD, DELETED_FIELD, FieldBase, FieldType, formatField, ID_FIELD, ManyField } from 'entities/Field';
import {
    createExpandedJsonFieldTree,
    FieldTree,
    findFieldInTree,
    getDefaultCollapsedFieldPathKeys,
    isFieldTree,
    urlDecodeFieldTree,
    urlEncodeCollapsedFieldPathKeys,
    urlEncodeFieldTree,
} from 'entities/FieldTree';
import { saveAs } from 'file-saver';
import Papa from 'papaparse';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { UrlParams } from 'SearchParams';
import { useQuery } from 'urql';
import colors from 'util/colors';
import { urlDecodeFilters, urlEncodeFilters } from 'util/filters';
import { defaultFieldTree, search as graphQlSearch, tableFilter } from 'util/graphql';
import { capitalize } from 'util/string';
import { pathWithQueryParams, useQueryParams } from 'util/url';

const DEFAULT_PAGE = 1;
const DEFAULT_PAGE_SIZE = 20;

const parseParams = (
    { search, pageSize, page, sort, sortOrder, f, c, filters, columns, ...rest }: Record<string, string>,
    entityDefinition: EntityDefinition<any>,
    defaultFieldTree: FieldTree
): UrlParams => ({
    search: search || '',
    pageSize: pageSize ? parseInt(pageSize) : DEFAULT_PAGE_SIZE,
    page: page ? parseInt(page) : DEFAULT_PAGE,
    sortField: sort,
    sortOrder: sortOrder ? (sortOrder as SortOrder) : undefined,
    filters: urlDecodeFilters(f || filters),
    columns: c || columns ? urlDecodeFieldTree(c || columns, entityDefinition) : defaultFieldTree,
});

interface ExportParams<EntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    entities: EntityType[];
    fieldTree: FieldTree;
    page?: number;
}

// https://stackoverflow.com/a/63744993/780425
const exportPageToCsv = <EntityType extends Entity>({
    entityDefinition,
    entities,
    fieldTree,
    page,
}: ExportParams<EntityType>) => {
    const flattenEntity = (
        fieldTree: FieldTree,
        entity?: ObjectLiteral,
        result: Record<string, string> = {},
        labelSegments: string[] = []
    ): Record<string, string> => {
        for (const field of fieldTree.fields) {
            if (isFieldTree(field)) {
                if (field.parentField) {
                    const key = field.parentField.key;
                    flattenEntity(field, entity?.[key], result, [...labelSegments, capitalize(field.parentField.label)]);
                }
            } else {
                const label = [...labelSegments, capitalize(field.label)].join(':');
                result[label] = formatField(entity?.[field.key], field, { stringOnly: true }) as string;
            }
        }
        return result;
    };

    const formattedEntities = entities.map(entity => flattenEntity(fieldTree, entity));
    const dataString = Papa.unparse(formattedEntities, { header: true });
    const blob = new Blob([dataString], { type: 'text/csv;charset=utf-8' });
    const date = new Date().toDateString().replace(/ /g, '_').toLowerCase();
    const filename = `${entityDefinition.key}_search_results_${date}${page ? `_page_${page}` : ''}.csv`;

    saveAs(blob, filename);
};

interface Props<EntityType extends Entity, ScopeEntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    // These three fields are present if this is a scoped search:
    scopeEntityDefinition?: EntityDefinition<ScopeEntityType>;
    scopeEntity?: ScopeEntityType;
    manyField?: ManyField;
}

// Filter out entries with `undefined`, `null`, or empty-object values, recursively.
// Objects in arrays are recursively expanded, but empty array values are kept as is, to preserve array length.
const withoutEmptyValuesRecursive = (object?: ObjectLiteral | any[]): ObjectLiteral | any[] | undefined => {
    if (Array.isArray(object)) {
        return object.length > 0
            ? object.map(v => (typeof v === 'object' && v !== null ? withoutEmptyValuesRecursive(v) : v))
            : undefined;
    }
    return object && Object.keys(object).length > 0
        ? Object.fromEntries(
              Object.entries(object || {})
                  .filter(([_, v]) => v !== undefined && v !== null && (!(typeof v === 'object') || Object.keys(v).length > 0))
                  .map(([k, v]) => [k, typeof v === 'object' ? withoutEmptyValuesRecursive(v) : v])
          )
        : undefined;
};

// Expand and filter out empty values in JSON fields (recursively through relations), so we can derive schemas for any JSON fields below.
const expandAndCleanJsonValues = <EntityType extends Entity>(
    entity: EntityType,
    entityDefinition: EntityDefinition<EntityType>
): EntityType =>
    Object.fromEntries(
        Object.entries(entity)
            .map(([k, v]) => {
                const isJsonField = findField(entityDefinition, k)?.type === FieldType.json;
                // Filter out null/undefined/empty values so `deepmerge` can find the superset of all fields.
                // Otherwise, an element with a non-null object value would get overwritten by a `null` value with the same key in a subsequent entity.
                if (isJsonField && typeof v === 'string') return [k, withoutEmptyValuesRecursive(JSON.parse(v))];

                const relation = findRelation(entityDefinition, k);
                return [k, relation && v ? expandAndCleanJsonValues(v, getRelationDefinition(relation)) : v];
            })
            .filter(([_, v]) => v)
    );

// Keep only JSON fields, recursively through relations.
const getJsonFields = <EntityType extends Entity>(
    entity: EntityType,
    entityDefinition: EntityDefinition<EntityType>
): EntityType =>
    Object.fromEntries(
        Object.entries(entity)
            .map(([k, v]) => {
                const isJsonField = findField(entityDefinition, k)?.type === FieldType.json;
                if (isJsonField) return [k, v];

                const relation = findRelation(entityDefinition, k);
                if (relation) return [k, v ? getJsonFields(v, getRelationDefinition(relation)) : v];

                return [k, undefined];
            })
            .filter(([_, v]) => v !== undefined)
    );

export default function SearchPagination<EntityType extends Entity, ScopeEntityType extends Entity>({
    entityDefinition,
    scopeEntityDefinition,
    scopeEntity,
    manyField,
}: Props<EntityType, ScopeEntityType>) {
    // If the provided relation expands any nested relations, exclude the 'id'/'created' fields of the relation
    // (the idea being that the attention in that case is really on the expanded entity).
    const defaultTableFilter = (field: FieldBase) =>
        tableFilter(field) && (!manyField?.expandTableFieldsFor || !field.key || CREATED_FIELD.key !== field.key);
    const defaultColumns = defaultFieldTree(entityDefinition, defaultTableFilter, {
        scopeEntityKey: scopeEntityDefinition?.key,
        expandFieldsForRelation: manyField?.expandTableFieldsFor,
        includeId: !manyField?.expandTableFieldsFor,
        includeChildIds: false,
    });

    const history = useHistory();
    const [params, setParams] = useState(parseParams(useQueryParams(), entityDefinition, defaultColumns));

    const {
        pageSize = DEFAULT_PAGE_SIZE,
        page = DEFAULT_PAGE,
        search,
        sortField: sortFieldParam,
        sortOrder: sortOrderParam,
        filters = {},
        columns,
    } = params;
    const query = graphQlSearch(entityDefinition, params, scopeEntityDefinition, manyField, scopeEntity);
    const [result] = useQuery({ query });
    const [expanded, setExpanded] = useState(true);

    const { data, fetching, stale, error } = result;
    if (error) return <ErrorComponent message={error.message} />;

    let total = 0;
    let entities: EntityType[] = [];
    if (data && !stale) {
        const { totalCount, nodes }: EntitiesConnection<EntityType> = !scopeEntityDefinition
            ? data[Object.keys(data)[0]]
            : data[scopeEntityDefinition.key]?.[manyField?.key || getSearchKey(entityDefinition)];
        total = totalCount;
        entities = nodes;
    }

    entities = entities.map(entity => expandAndCleanJsonValues(entity, entityDefinition));
    // Merge all entities into one, keeping only JSON fields (recursively through relations), to derive a schema for any unstructured JSON fields.
    const mergedJsonObject: ObjectLiteral = deepmerge.all(entities.map(entity => getJsonFields(entity, entityDefinition)));
    const jsonExpandedFieldTree = createExpandedJsonFieldTree(columns, mergedJsonObject);

    const setColumns = (columns?: FieldTree, newFilterSuggestion = filters) => {
        const newColumns = JSON.stringify(columns) === JSON.stringify(defaultColumns) ? undefined : columns;
        // Ensure only filters & collapsed field paths corresponding to selected columns are present
        // (hence the 'suggestion' in the optional `newFilterSuggestion` arg).
        const newFilters = Object.fromEntries(
            Object.entries(newFilterSuggestion).filter(([k]) => findFieldInTree(newColumns || defaultColumns, k.split(':')))
        );

        const defaultCollapsedFieldPathKeys = getDefaultCollapsedFieldPathKeys(newColumns || defaultColumns);
        const collapsedFieldPathKeys = params.collapsedFieldPathKeys?.filter(collapsedFieldPath =>
            findFieldInTree(newColumns || defaultColumns, collapsedFieldPath.split(':'))
        );
        const newCollapse =
            JSON.stringify(collapsedFieldPathKeys) === JSON.stringify(defaultCollapsedFieldPathKeys)
                ? undefined
                : collapsedFieldPathKeys;

        history.push(
            pathWithQueryParams({
                c: urlEncodeFieldTree(newColumns),
                f: urlEncodeFilters(newFilters),
                collapse: urlEncodeCollapsedFieldPathKeys(newCollapse),
            })
        );
    };
    const removeFilter = (fieldPathKey: string) => {
        const newFilters = Object.fromEntries(Object.entries(filters).filter(([k]) => k !== fieldPathKey));
        const path = pathWithQueryParams({ sort: sortField, sortOrder, f: urlEncodeFilters(newFilters) });
        history.push(path);
    };

    const { searchable, defaultSortField, defaultSortOrder, softDeletable } = entityDefinition;
    const viewingRecycling = filters[DELETED_FIELD.key]?.present;
    const shouldPaginate = total > pageSize;
    const pluralLabel = pluralize(getEntityDefinitionLabel(entityDefinition));
    const sortField = sortFieldParam || defaultSortField || ID_FIELD.key;
    const sortOrder =
        sortOrderParam ||
        (sortField === defaultSortField || sortField === ID_FIELD.key ? defaultSortOrder || SortOrder.asc : SortOrder.asc);

    return (
        <div>
            {searchable !== false && (
                <Row align="middle" gutter={[10, 12]} style={{ marginBottom: 14 }}>
                    <Col xs={{ span: 24 }} xxl={{ span: 16 }}>
                        <SearchInput
                            fetching={fetching || stale}
                            entityDefinition={entityDefinition}
                            onChange={search => {
                                // To preserve the current cursor position, change the URL params in-place, and manually change the `params` state.
                                const newParams = { search, page: shouldPaginate ? DEFAULT_PAGE : undefined };
                                window.history.pushState(null, '', pathWithQueryParams(newParams));
                                setParams({ ...params, ...newParams });
                            }}
                        />
                    </Col>
                </Row>
            )}
            <div style={{ fontSize: 15, marginBottom: 5 }}>Columns</div>
            <Row align="middle" style={{ marginBottom: 14 }}>
                <Col xs={{ span: 24 }} md={{ span: 21 }} xxl={{ span: 16 }}>
                    <FieldTreeSelect
                        entityDefinition={entityDefinition}
                        scopeEntityKey={scopeEntityDefinition?.key}
                        value={columns}
                        onChange={setColumns}
                        onReset={() => setColumns(defaultColumns)}
                        showReset={JSON.stringify(columns) !== JSON.stringify(defaultColumns)}
                    />
                </Col>
                {softDeletable && (
                    <Tooltip
                        title={`Toggle to only show ${viewingRecycling ? 'non-deleted' : 'deleted'} ${pluralLabel}`}
                        style={{ marginLeft: '1em' }}
                    >
                        <Switch
                            checked={viewingRecycling}
                            style={{ marginLeft: '1em', ...(viewingRecycling ? { background: colors.danger } : {}) }}
                            checkedChildren={<DeleteOutlined />}
                            unCheckedChildren={<DeleteOutlined />}
                            onChange={checked => {
                                setColumns(
                                    {
                                        ...columns,
                                        fields: checked
                                            ? !columns.fields.some(f => 'key' in f && f.key === DELETED_FIELD.key)
                                                ? [...columns.fields, DELETED_FIELD]
                                                : columns.fields
                                            : columns.fields.filter(f => !('key' in f) || f.key !== DELETED_FIELD.key),
                                    },
                                    { ...filters, [DELETED_FIELD.key]: { present: checked } }
                                );
                            }}
                        />
                    </Tooltip>
                )}
            </Row>
            {Object.keys(filters).length > 0 && (
                <Row style={{ marginBottom: 14 }}>
                    <FilterList entityDefinition={entityDefinition} filters={filters} removeFilter={removeFilter} />
                </Row>
            )}
            {!fetching && (
                <Row align="middle" justify="space-between" style={{ marginBottom: 8 }}>
                    {shouldPaginate && (
                        <Pagination
                            style={{ flexGrow: 1 }}
                            current={page}
                            defaultPageSize={pageSize}
                            total={total}
                            showTotal={(total, range) => `${range[0]}-${range[1]} of ${total} ${pluralLabel}`}
                            onChange={(page, pageSize) => {
                                history.push(
                                    pathWithQueryParams({
                                        pageSize: pageSize === DEFAULT_PAGE_SIZE ? undefined : `${pageSize}`,
                                        page: page === DEFAULT_PAGE ? undefined : `${page}`,
                                    })
                                );
                            }}
                        />
                    )}
                    <span style={{ display: 'flex', alignItems: 'center' }}>
                        <Tooltip title="Toggle expanded/collapsed-scrollable table mode" placement="topRight">
                            <Switch
                                size="small"
                                style={{ marginRight: 6 }}
                                checkedChildren={<ShrinkOutlined />}
                                unCheckedChildren={<ExpandOutlined />}
                                checked={expanded}
                                onChange={() => setExpanded(!expanded)}
                            />
                        </Tooltip>
                        <Tooltip title="Export the current page to CSV" placement="topRight">
                            <Button
                                icon={<ExportOutlined />}
                                type="link"
                                onClick={() => exportPageToCsv({ entityDefinition, entities, fieldTree: columns, page })}
                            />
                        </Tooltip>
                        <GraphQlExplorer query={query} childLabel="search" />
                    </span>
                </Row>
            )}
            <EntitiesTable
                entityDefinition={entityDefinition}
                entities={entities}
                fieldTree={jsonExpandedFieldTree}
                // TODO #Shorthand `defaultSortField` should be required
                sortField={sortField}
                sortOrder={sortOrder}
                fetching={fetching || stale}
                expanded={expanded}
                search={search}
                filters={filters}
            />
        </div>
    );
}

import EntityLink from 'components/entity/EntityLink';
import type { EntityBase, EntityLabelProps, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type Event from 'entities/rw/event/Event';
import type { Tense } from 'entities/rw/event/Event';
import { formatEventType } from 'entities/rw/event/Event';
import React from 'react';
import { capitalize } from 'util/string';

export enum ModificationEventEffectType {
    update = 'update',
    softdelete = 'softdelete',
    restore = 'restore',
}
export enum CreationEventEffectType {
    create = 'create',
}
export enum DeletionEventEffectType {
    delete = 'delete',
}

// No way to just merge two enums and types at the same time, so need to create the type and value separately.
// See https://stackoverflow.com/a/51498560/780425
export const EventEffectType = { ...CreationEventEffectType, ...DeletionEventEffectType, ...ModificationEventEffectType };
export type EventEffectTypeType = CreationEventEffectType | DeletionEventEffectType | ModificationEventEffectType;

export const formatEventEffectType = (type: EventEffectTypeType, tense: Tense = 'imperative'): string => {
    const isImperative = tense === 'imperative';
    const isPast = tense === 'past';

    switch (type) {
        case EventEffectType.create:
            return isImperative ? 'create' : isPast ? 'created' : 'creating';
        case EventEffectType.update:
            return isImperative ? 'update' : isPast ? 'updated' : 'updating';
        case EventEffectType.softdelete:
            return isImperative ? 'soft-delete' : isPast ? 'soft-deleted' : 'soft-deleting';
        case EventEffectType.restore:
            return isImperative ? 'restore' : isPast ? 'restored' : 'restoring';
        case EventEffectType.delete:
            return isImperative ? 'delete' : isPast ? 'deleted' : 'deleting';
    }
};

// Used in field-value formatting contexts
export const formatEventEffectOption = (option: string) => capitalize(formatEventEffectType(option as EventEffectTypeType));

export interface Difference {
    field: string;
    oldValue?: any;
    newValue?: any;
}

export interface EventEffectEntity extends EntityBase {
    type: EventEffectTypeType;
    diffsJson: string;
    entityKey: string;
    entityId: number;
    event: Event;
    entity?: string;
}

const Label = ({ entityByKey, entity: eventEffect, insideLink }: EntityLabelProps<EventEffectEntity>) => {
    const { entityKey, entityId, type } = eventEffect;
    const affectedEntityDefinition = entityByKey[entityKey];
    const entity = eventEffect.entity ? JSON.parse(eventEffect.entity) : { id: entityId };

    return (
        <span style={{ display: 'inline-flex', alignItems: 'center' }}>
            <EntityLink entityDefinition={affectedEntityDefinition} entity={entity} insideLink={insideLink} />
            <span style={{ margin: '0 6px' }}>:</span>
            <span style={{ fontStyle: 'italic' }}>{formatEventType(type, 'past')}</span>
        </span>
    );
};

const shorthand: ShorthandEntityDefinition<EventEffectEntity> = {
    key: rw.event.Effect,
    immutable: true,
    Label,
    fields: [
        { key: 'type', includeInSummary: true },
        { key: 'entityId', label: 'Entity ID', type: FieldType.number, includeInSummary: true },
        { key: 'entityKey', includeInSummary: true },
        { key: 'diffsJson', type: FieldType.json },
        { key: 'entity', type: FieldType.json, hide: true, excludeFromTable: true, includeInSummary: true },
        { one: rw.event.Event, includeInSummary: true },
        { many: rw.subscription.Notification },
    ],
};

export default shorthand;

import {
    ApartmentOutlined,
    AuditOutlined,
    BankOutlined,
    BellOutlined,
    CarryOutOutlined,
    CheckSquareOutlined,
    ClockCircleOutlined,
    CodeSandboxOutlined,
    DatabaseOutlined,
    FieldTimeOutlined,
    FileDoneOutlined,
    FileImageOutlined,
    FileOutlined,
    FileProtectOutlined,
    FileSyncOutlined,
    FolderOutlined,
    GlobalOutlined,
    HistoryOutlined,
    HomeOutlined,
    IdcardOutlined,
    MailOutlined,
    NotificationOutlined,
    PaperClipOutlined,
    ProjectOutlined,
    SwapOutlined,
    TagOutlined,
    TeamOutlined,
    UnorderedListOutlined,
    UserOutlined,
} from '@ant-design/icons';
import LinkIcon from 'components/icon/LinkIcon';
import { EntityDefinition, ObjectLiteral } from 'entities/EntityDefinition';

import { oikos, rw } from 'entities/EntityKeys';
import { Page } from 'Pages';
import React, { JSXElementConstructor } from 'react';

// These are used to reference other entities when defining relations in `EntityDefinition`s,
// to avoid circular references during declaration.

// eslint-disable-next-line import/no-anonymous-default-export
const iconForEntity: Record<string, JSXElementConstructor<any>> = {
    [rw.user.User]: UserOutlined,
    [rw.user.Address]: HomeOutlined,
    [rw.user.Person]: IdcardOutlined,
    [rw.user.Position]: ApartmentOutlined,
    [rw.user.ContactType]: BankOutlined,

    [rw.organization.Organization]: BankOutlined,
    [rw.organization.VirtualOrganization]: DatabaseOutlined,
    [rw.organization.ProjectEmbargo]: FieldTimeOutlined,

    [rw.campaign.Campaign]: TeamOutlined,
    [rw.campaign.ProjectEmbargo]: FieldTimeOutlined,
    [rw.project.Project]: ProjectOutlined,

    [rw.file.Folder]: FolderOutlined,
    [rw.file.File]: FileOutlined,
    [rw.file.FileHistory]: HistoryOutlined,
    [rw.file.FileRelation]: SwapOutlined,

    [rw.archive.Attachment]: PaperClipOutlined,
    [rw.archive.File]: FileDoneOutlined,
    [rw.archive.Package]: CodeSandboxOutlined,
    [rw.archive.Path]: UnorderedListOutlined,
    [rw.archive.SubmissionHistory]: HistoryOutlined,

    [rw.filestore.File]: FileProtectOutlined,

    [rw.notebook.HtmlCache]: FileSyncOutlined,
    [rw.notebook.Image]: FileImageOutlined,
    [rw.notebook.ImageTag]: TagOutlined,
    [rw.notebook.Metadata]: AuditOutlined,
    [rw.notebook.Session]: ClockCircleOutlined,

    [rw.subscription.Subscription]: BellOutlined,
    [rw.subscription.Notification]: NotificationOutlined,
    [rw.subscription.NotificationRecord]: MailOutlined,

    [rw.event.Event]: CarryOutOutlined,
    [rw.event.Effect]: CheckSquareOutlined,

    [oikos.Portal]: GlobalOutlined,
};

const relationEntityKeys: Record<string, [string, string]> = {
    [rw.user.FileContact]: [rw.file.File, rw.user.User],
    [rw.organization.Role]: [rw.organization.Organization, rw.user.User],
    [rw.organization.CampaignRole]: [rw.organization.Organization, rw.campaign.Campaign],
    [rw.organization.ProjectRole]: [rw.organization.Organization, rw.project.Project],
    [rw.organization.ProjectLabel]: [rw.organization.Organization, rw.project.Project],
    [rw.campaign.Role]: [rw.campaign.Campaign, rw.user.User],
    [rw.campaign.ProjectRole]: [rw.campaign.Campaign, rw.project.Project],
    [rw.campaign.ProjectLabel]: [rw.campaign.Campaign, rw.project.Project],
    [rw.project.Role]: [rw.project.Project, rw.user.User],
    [rw.project.Label]: [rw.project.Project, rw.user.User],
    [rw.portal.Campaign]: [rw.campaign.Campaign, oikos.Portal],
    [rw.portal.Organization]: [rw.organization.Organization, oikos.Portal],
};

export function EntityIcon({ entityKey, style = {} }: { entityKey: string; style?: ObjectLiteral }) {
    const Icon = iconForEntity[entityKey];
    if (Icon) return <Icon className="entity-icon" style={style} />;
    if (entityKey in relationEntityKeys) {
        const [leftEntityKey, rightEntityKey] = relationEntityKeys[entityKey];
        const LeftIcon = iconForEntity[leftEntityKey];
        const RightIcon = iconForEntity[rightEntityKey];
        if (LeftIcon && RightIcon) return <LinkIcon className="entity-icon" LeftIcon={LeftIcon} RightIcon={RightIcon} />;
    }
    return null;
}

export function EntityGroupIcon({ Icon }: { Icon: JSXElementConstructor<any> }) {
    return <Icon className="entity-icon" />;
}

export const PageIcon = ({ page }: { page: Page | EntityDefinition<any> }) =>
    'fields' in page ? <EntityIcon entityKey={page.key} /> : page.icon;

/**
 All files with extensions in this map are considered editable.
 The file view will show a 'File editor' tab if and only if its extension is in this map.
 `undefined` values indicate editable, but no language support (syntax highting, autocomplete, etc).
 String values correspond to the [supported Monaco languages](https://github.com/Microsoft/monaco-editor-webpack-plugin#options).
 I pulled this selection from this `monaco-editor-webpack-plugin` file, excluding esoteric languages that I can't
 imagine we'll ever come across, and commented out the ones that seem plausible but not common.
 We can add support for any commented languages by just uncommenting.
 However, every supported language (again - string language values) increases compile time and build size.

 The extension mapping comes from just Googling the file extensions for each language.
**/

module.exports = {
    bat: undefined, // 'bat',
    clj: undefined, // 'clojure',
    cljs: undefined, // 'clojure',
    cljc: undefined, // 'clojure',
    coffee: undefined, // 'coffee',
    cpp: undefined, // 'cpp',
    hpp: undefined, // 'cpp',
    h: undefined, // 'cpp',
    hh: undefined, // 'cpp',
    cc: undefined, // 'cpp',
    cs: undefined, // 'csharp',
    csp: undefined, // 'csp',
    css: undefined, //'css',
    csv: undefined, // TODO special case
    dart: undefined, // 'dart',
    dockerfile: undefined, // 'dockerfile',
    ex: undefined, // 'elixir',
    exs: undefined, // 'elixir',
    go: undefined, // 'go',
    vb: undefined, // 'vb',
    fs: undefined, // 'fsharp',
    fsi: undefined, // 'fsharp',
    fsx: undefined, // 'fsharp',
    fsscript: undefined, // 'fsharp',
    gql: undefined, // 'graphql',
    hbs: undefined, // 'handlebars',
    html: 'html',
    java: undefined, // 'java',
    json: 'json',
    js: undefined, // 'javascript',
    jsx: undefined, // 'javascript',
    ini: undefined, // 'ini',
    ipynb: 'python', // TODO special case
    jl: undefined, // 'julia',
    kt: undefined, // 'kotlin',
    kts: undefined, // 'kotlin',
    ktm: undefined, // 'kotlin',
    less: undefined, // 'less',
    lua: undefined, // 'lua',
    md: 'markdown',
    m: undefined, // 'objective-c',
    mm: undefined, // 'objective-c',
    pas: undefined, // 'pascal',
    pl: undefined, // 'perl',
    php: undefined, // 'php',
    prj: undefined, // TODO special case
    ps1: undefined, // 'powershell',
    py: 'python',
    r: undefined, // 'r',
    rb: undefined, // 'ruby',
    rq: undefined, // 'sparql',
    rs: undefined, // 'rust',
    sc: undefined, // 'scala',
    scala: undefined, // 'scala',
    scm: undefined, //'scheme',
    scss: undefined, // 'scss',
    sh: undefined, // 'shell',
    shp: undefined, // TODO special case
    sql: undefined, // 'sql',
    swift: undefined, // 'swift',
    ts: undefined, // 'typescript',
    tsx: undefined, // 'typescript',
    txt: 'txt',
    xml: 'xml',
    yml: 'yaml',
    yaml: 'yaml',
};

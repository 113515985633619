import { Tabs } from 'antd';
import React, { ReactNode, useEffect } from 'react';
import { useHistory } from 'react-router';

interface Props {
    activeTab: string;
    createPath: (tab: string) => string;
    children: ReactNode;
}

export default function UrlSegmentTabs({ activeTab, createPath, children }: Props) {
    const history = useHistory();
    useEffect(() => {
        const path = createPath(activeTab);
        if (!history.location.pathname.startsWith(path)) {
            history.replace(`${path}${window.location.search}`);
        }
    }, [activeTab, createPath, history]);

    return (
        <Tabs
            activeKey={activeTab}
            tabBarStyle={{ paddingTop: 0 }}
            animated={{ inkBar: false, tabPane: false }}
            onChange={newTab => history.push(createPath(newTab))}
        >
            {children}
        </Tabs>
    );
}

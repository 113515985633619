import { DeletableEntityBase } from 'entities/EntityDefinition';
import { Field, FieldType, ShorthandField } from 'entities/Field';

export enum LevelType {
    owner = 'Owner',
    writer = 'Writer',
    viewer = 'Viewer',
}

export const LEVEL_FIELD: Field = {
    key: 'levelName',
    label: 'Level',
    editable: true,
    required: true,
    type: FieldType.enum,
    options: Object.values(LevelType),
};

export interface RoleEntityBase extends DeletableEntityBase {
    levelName: LevelType;
}

export const createRoleFields = (resourceEntityKey: string, accessorEntityKey: string): ShorthandField[] => [
    LEVEL_FIELD,
    { one: resourceEntityKey, required: true },
    { one: accessorEntityKey, required: true },
];

import EntityLink from 'components/entity/EntityLink';
import EventView from 'components/entity/rw/event/EventView';
import { SortOrder } from 'components/search/SortOrder';
import type { EntityBase, EntityLabelProps, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { allEntityKeys, rw } from 'entities/EntityKeys';
import { CREATED_FIELD, FieldType } from 'entities/Field';
import type { EventEffectEntity, EventEffectTypeType } from 'entities/rw/event/EventEffect';
import { EventEffectType, formatEventEffectType } from 'entities/rw/event/EventEffect';
import type { UserEntity } from 'entities/rw/user/User';
import React from 'react';
import { capitalize } from 'util/string';

export type Tense = 'past' | 'present' | 'imperative';

enum EventOnlyType {
    undo = 'undo',
}

// No way to just merge two enums and types at the same time, so need to create the type and value separately.
// See https://stackoverflow.com/a/51498560/780425
export const EventType = { ...EventEffectType, ...EventOnlyType };
export type EventTypeType = EventEffectTypeType | EventOnlyType;

export const formatEventType = (type: EventTypeType, tense: Tense = 'imperative'): string => {
    if (type in EventEffectType) return formatEventEffectType(type as EventEffectTypeType, tense);

    const isImperative = tense === 'imperative';
    const isPast = tense === 'past';

    switch (type) {
        case EventType.undo:
            return isImperative ? 'undo' : isPast ? 'undid' : 'undoing';
        default:
            return type;
    }
};

// Used in field-value formatting contexts
const formatEventOption = (option: string) => capitalize(formatEventType(option as EventTypeType));

export interface EventEntity extends EntityBase {
    user: UserEntity;
    type: EventTypeType;
    entityKey: string;
    entityId?: number;
    effects: { nodes: EventEffectEntity[] };
    entity?: string;
}

const shorthand: ShorthandEntityDefinition<EventEntity> = {
    key: rw.event.Event,
    immutable: true,
    mainNav: true,
    Label: ({ entityByKey, entity: event, insideLink }: EntityLabelProps<EventEntity>) => {
        const { entityKey, entityId, type } = event;
        const affectedEntityDefinition = entityByKey[entityKey];
        const entity = event.entity ? JSON.parse(event.entity) : { id: entityId };

        return (
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <EntityLink entityDefinition={affectedEntityDefinition} entity={entity} insideLink={insideLink} />
                <span style={{ margin: '0 6px' }}>:</span>
                <span style={{ fontStyle: 'italic' }}>{formatEventType(type)}</span>
            </span>
        );
    },
    defaultSortField: CREATED_FIELD.key,
    defaultSortOrder: SortOrder.desc,
    fields: [
        {
            key: 'type',
            type: FieldType.enum,
            options: Object.values(EventType),
            formatOption: formatEventOption,
            required: true,
            includeInSummary: true,
        },
        {
            key: 'entityKey',
            type: FieldType.enum,
            options: allEntityKeys,
            formatOptions: true,
            required: true,
            includeInSummary: true,
        },
        { key: 'entityId', label: 'Entity ID', type: FieldType.entityId, includeInSummary: true },
        { key: 'entity', type: FieldType.json, hide: true, excludeFromTable: true, includeInSummary: true },
        { one: rw.user.User, required: true },
        { many: rw.event.Effect, key: 'effects', includeInDetail: true, hideTab: true },
    ],
    Edit: EventView,
};

export default shorthand;

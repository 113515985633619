import React from 'react';

// TODO use antd spinner and rm `react-spinkit` dep
import Spinner from 'react-spinkit';
import useWindowDimensions from 'util/useWindowDimensions';

export default function FullPageLoader() {
    const { height } = useWindowDimensions();

    return (
        <div
            style={{
                position: 'absolute',
                left: '50%',
                transform: 'translateY(-50%)',
                top: `${height / 2}px`,
            }}
        >
            <Spinner name="line-scale" color="#4e6275" />
        </div>
    );
}

import { WarningOutlined } from '@ant-design/icons';
import { Alert, Space } from 'antd';
import { useAuth } from 'AuthContext';
import EntityLink from 'components/entity/EntityLink';
import type { DeletableEntityBase, EntityDefinitionMap, EntityLabelProps } from 'entities/EntityDefinition';
import { getEntityDefinitionLabel, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { EntityIcon } from 'entities/EntityIcons';
import { allEntityKeys, rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { EventTypeType } from 'entities/rw/event/Event';
import type { EventEffectTypeType } from 'entities/rw/event/EventEffect';
import {
    EventEffectType,
    formatEventEffectOption,
    formatEventEffectType,
    ModificationEventEffectType,
} from 'entities/rw/event/EventEffect';
import type { UserEntity } from 'entities/rw/user/User';
import React from 'react';
import { useQuery } from 'urql';
import colors from 'util/colors';
import { find, summaryFieldTree } from 'util/graphql';

// There's no technical limitation preventing subscription to these entity types. This blacklist here to prevent
// subscription UX from showing up in places where there's no sensible use-case, or where it would be confusing.
// **Note that all entity definitions marked `immutable` can already not be subscribed to,** although some are repeated here
// for clarity.**
export const unsubscribableEntityKeys = [
    rw.subscription.Subscription,
    rw.subscription.Notification,
    rw.subscription.NotificationRecord,
    rw.event.Event,
    rw.event.Effect,
];

const subscribableEntityKeys = allEntityKeys.filter(entityKey => !unsubscribableEntityKeys.includes(entityKey));

export enum NotificationType {
    email = 'email',
    message = 'message',
}

export interface EntitySubscriptionEntity extends DeletableEntityBase {
    subscriber: UserEntity;
    entityKey: string;
    entityId: number;
    emailEnabled: boolean;
    eventEffectTypes: EventEffectTypeType[];
    relationEntityKey: string;
    relationEventEffectTypes: EventEffectTypeType[];
    entity?: string;
}

const SubscriptionDescription = ({
    entityByKey,
    entitySubscription,
}: {
    entityByKey: EntityDefinitionMap;
    entitySubscription: EntitySubscriptionEntity;
}) => {
    const { subscriber, entityKey, entityId, emailEnabled = true, eventEffectTypes } = entitySubscription;
    const subscribedEntityDefinition = entityByKey[entityKey];
    const { authenticatedUser } = useAuth();
    const [findResult] = useQuery({
        pause: !!entitySubscription.entity, // If the subscription already has an entity populated, no need to query for it.
        query: find(subscribedEntityDefinition, entityId, summaryFieldTree(subscribedEntityDefinition)),
    });

    const { data, error } = findResult;
    if (error) return <Alert message={error?.message} type="error" />;
    const entity = data?.[entityKey] || (entitySubscription.entity && JSON.parse(entitySubscription.entity)) || { id: entityId };

    const isAuthedUser = subscriber.id === authenticatedUser?.id;
    const numEffectTypes = entitySubscription.eventEffectTypes.length;

    return (
        <>
            Notify{' '}
            {isAuthedUser ? 'me' : <EntityLink entityDefinition={entityByKey[rw.user.User]} entity={subscriber} external />}{' '}
            {`by ${emailEnabled ? 'email and ' : ''}in-app notifications`} when{' '}
            {getEntityDefinitionLabel(subscribedEntityDefinition)}{' '}
            <EntityLink entityDefinition={subscribedEntityDefinition} entity={entity} external /> is{' '}
            {eventEffectTypes
                .map(
                    (effectType, i) =>
                        `${formatEventEffectType(effectType, 'past')}${
                            numEffectTypes === 1 || i === numEffectTypes - 1 ? '' : i === numEffectTypes - 2 ? ' or ' : ', '
                        }`
                )
                .join('')}
            .
        </>
    );
};

const shorthand: ShorthandEntityDefinition<EntitySubscriptionEntity> = {
    key: rw.subscription.Subscription,
    creatable: true,
    softDeletable: true,
    mainNav: true,
    label: 'Subscription',
    path: 'subscription',
    Label: ({ entityByKey, entity: entitySubscription, insideLink }: EntityLabelProps<EntitySubscriptionEntity>) => {
        const { subscriber, entityKey, entityId, id } = entitySubscription;
        const subscribedEntityDefinition = entityByKey[entityKey];
        const entity = entitySubscription.entity ? JSON.parse(entitySubscription.entity) : { id: entityId };
        const userEntityDefinition = entityByKey[rw.user.User];

        // A subscriber is required, but in some cases we could be constructing a label from a JSON-parsed subscription,
        // which won't the relation/entity fields.
        if (!subscriber) return <>{`Subscription #${id}`}</>;

        return (
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <EntityLink entityDefinition={subscribedEntityDefinition} entity={entity} insideLink={insideLink} />
                <EntityIcon entityKey={rw.subscription.Notification} style={{ fontSize: 12, margin: '0px 8px' }} />
                <EntityLink entityDefinition={userEntityDefinition} entity={subscriber} insideLink={insideLink} />
            </span>
        );
    },
    createUpdateConfirmationModal: (entityByKey, subscription: EntitySubscriptionEntity, eventType?: EventTypeType) => {
        const isCreate = eventType === EventEffectType.create;

        return {
            title: (
                <Space>
                    <WarningOutlined style={{ color: colors.warning, fontSize: 20 }} />
                    <>{isCreate ? 'Create' : 'Update'} subscription confirmation</>
                </Space>
            ),
            okText: 'Confirm',
            contents: (
                <>
                    <p>Please confirm this {isCreate ? 'new' : 'updated'} subscription is configured correctly:</p>
                    <i>
                        <SubscriptionDescription entityByKey={entityByKey} entitySubscription={subscription} />
                    </i>
                </>
            ),
        };
    },
    fields: [
        { key: 'subscriber', one: rw.user.User, required: true, editable: false, includeInSummary: true },
        {
            key: 'entityKey',
            label: 'entity type',
            type: FieldType.enum,
            options: subscribableEntityKeys,
            formatOptions: true,
            required: true,
            editable: true,
            includeInSummary: true,
        },
        {
            key: 'entityId',
            label: 'entity',
            type: FieldType.entityId,
            required: true,
            editable: true,
            dependsOnField: { key: 'entityKey' },
            includeInSummary: true,
        },
        { key: 'emailEnabled', type: FieldType.boolean, default: true, editable: true },
        {
            key: 'eventEffectTypes',
            label: 'event types',
            type: FieldType.enumList,
            options: Object.values(ModificationEventEffectType),
            required: true, // TODO this should only be required if there is no `relationEntityKey` populated. Otherwise `relationEventEffectTypes` should be required
            editable: true,
            graphQlTypeName: '[ModificationEventEffectType]',
            formatOption: formatEventEffectOption,
        },
        {
            key: 'relationEntityKey',
            label: 'relation entity type',
            type: FieldType.enum,
            options: subscribableEntityKeys,
            formatOptions: true,
            editable: true,
            hide: true,
            excludeFromTable: true,
        },
        {
            key: 'relationEventEffectTypes',
            label: 'relation event types',
            type: FieldType.enumList,
            options: Object.values(EventEffectType),
            editable: true,
            graphQlTypeName: '[EventEffectType]',
            formatOption: formatEventEffectOption,
            hide: true,
            excludeFromTable: true,
        },
        { key: 'entity', type: FieldType.json, hide: true, excludeFromTable: true, includeInSummary: true },
        { many: rw.subscription.Notification },
    ],
};

export default shorthand;

import { Alert, Button, notification, Space, Tooltip } from 'antd';
import { useAuth } from 'AuthContext';
import EntityLink from 'components/entity/EntityLink';
import { entityByKey } from 'entities/Entities';
import { getEntityDefinitionLabel, getIdPath, getIndexPath, getPageOrEntityDefinitionLabel } from 'entities/EntityDefinition';
import { EntityIcon } from 'entities/EntityIcons';
import { rw } from 'entities/EntityKeys';
import { formatEventEffectType } from 'entities/rw/event/EventEffect';
import { UserEntity } from 'entities/rw/user/User';
import { print } from 'graphql';
import { SignInRoute, SignOutRoute } from 'Pages';
import pluralize from 'pluralize';
import React, { useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { useSubscription } from 'urql';
import { defaultFieldTree, detailFilter, graphQlQuery } from 'util/graphql';
import { capitalize } from 'util/string';

const onNotificationSubscription = `subscription OnNotification { ${rw.subscription.Notification} ${print(
    graphQlQuery(defaultFieldTree(entityByKey[rw.subscription.Notification], detailFilter))
)} }`;

interface NotificationsButtonProps {
    user: UserEntity;
}

function Notifications({ user }: NotificationsButtonProps) {
    const { authenticatedUser } = useAuth();
    const history = useHistory();
    const [notificationResult] = useSubscription({ query: onNotificationSubscription });
    const { data, error } = notificationResult;

    const notificationEntity = data?.[rw.subscription.Notification];
    useEffect(() => {
        if (notificationEntity) {
            const { eventEffect, entitySubscription } = notificationEntity;
            const { subscriber } = entitySubscription;
            const { entityKey, entity: entityJson, type: effectType } = eventEffect;
            const entityDefinition = entityByKey[entityKey];
            const isAuthedUser = subscriber.id === authenticatedUser?.id;

            // Important note: All notifications/messages are not rendered in the context of the React router,
            // so we can't use the usual `<Link>` component. Instead, we manually push to the history.
            notification.info({
                key: notificationEntity.id,
                message: 'Notification',
                description: (
                    <>
                        <div>
                            {isAuthedUser ? (
                                'You'
                            ) : (
                                <EntityLink
                                    entityDefinition={entityByKey[rw.user.User]}
                                    entity={subscriber}
                                    external
                                    history={history}
                                />
                            )}{' '}
                            {formatEventEffectType(effectType, 'past')} {getEntityDefinitionLabel(entityDefinition)}{' '}
                            <EntityLink entityDefinition={entityDefinition} entity={JSON.parse(entityJson)} external />.
                        </div>
                        <Space style={{ marginTop: '1em' }}>
                            <Button
                                onClick={() => {
                                    history?.push(getIdPath(entityByKey[rw.subscription.Notification], notificationEntity.id));
                                }}
                            >
                                View notification
                            </Button>
                            <Button
                                onClick={() => {
                                    history?.push(getIdPath(entityByKey[rw.event.Event], eventEffect.event.id));
                                }}
                            >
                                View event
                            </Button>
                        </Space>
                    </>
                ),
                duration: 0, // don't automatically close
            });
        }
    }, [notificationEntity, authenticatedUser, history]);

    if (error) return <Alert message={error?.message} type="error" />;

    return (
        <Tooltip title="Notifications" placement="bottomLeft">
            <Link to={`${getIdPath(entityByKey[rw.user.User], user.id)}/${pluralize(rw.subscription.Notification)}`}>
                <Button shape="round" icon={<EntityIcon entityKey={rw.subscription.Notification} />} />
            </Link>
        </Tooltip>
    );
}

export default function MainHeader() {
    const { authenticatedUser, logout } = useAuth();

    if (authenticatedUser && logout) {
        const { id, email } = authenticatedUser;
        return (
            <Space>
                <Notifications user={authenticatedUser} />
                <Tooltip title={`Signed in as ${email}`} placement="bottomLeft">
                    <Link to={getIdPath(entityByKey[rw.user.User], id)}>
                        <Button shape="round" icon={<EntityIcon entityKey={rw.user.User} />} />
                    </Link>
                </Tooltip>
                <Tooltip title={capitalize(getPageOrEntityDefinitionLabel(SignOutRoute))} placement="bottomLeft">
                    <Button shape="circle" icon={SignOutRoute.icon} onClick={logout} />
                </Tooltip>
            </Space>
        );
    }

    return (
        <Tooltip title={capitalize(getPageOrEntityDefinitionLabel(SignInRoute))} placement="bottomRight">
            <Link to={getIndexPath(SignInRoute)}>
                <Button shape="circle" icon={SignInRoute.icon} />
            </Link>
        </Tooltip>
    );
}

import React, { CSSProperties } from 'react';

interface Props {
    href: string;
    children: React.ReactNode;
    style?: CSSProperties;
}

export default function ExternalLink({ href, children, style }: Props) {
    return (
        <a href={href} target="_blank" rel="noreferrer" style={style}>
            {children}
        </a>
    );
}

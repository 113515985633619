interface ArchivableEntity {
    id: number;
    pid: string;
    doi?: string;
    submitted?: string;
}

export const createExternalLink = ({ pid, doi }: ArchivableEntity) =>
    doi ? `https://search.dataone.org/view/${doi}` : pid && `https://search.dataone.org/view/${pid}`;

export const createLinks = ({ id, pid, doi, submitted }: ArchivableEntity) => {
    const submittedMillis = submitted && new Date(submitted).getTime();

    return (
        !!pid && [
            {
                label: 'DataONE catalog',
                href: createExternalLink({ id, pid, doi }),
            },
            {
                label: 'RW member node',
                href: `https://dataone.researchworkspace.com/mn/v2/meta/${pid}`,
            },
            {
                label: 'DataONE coordinating node',
                href: `https://cn.dataone.org/cn/v2/meta/${pid}`,
            },
            {
                label: 'DataONE SOLR',
                href: `https://search.dataone.org/cn/v2/query/solr/?q=id:%22${pid}%22&rows=1&start=0&fl=*&wt=json`,
            },
            {
                label: 'd1-publisher logs in Grafana',
                href: submittedMillis
                    ? `http://grafana.axiomptk/explore?orgId=1&left=["${submittedMillis - 2 * 60 * 1000}","${
                          submittedMillis + 60 * 60 * 1000
                      }","Loki",{"expr":"{container_name=\\"research-workspace-prod_d1-publisher_1\\"}"}]`
                    : `http://grafana.axiomptk/explore?orgId=1&left=["now-7d","now","Loki",{"expr":"{container_name=\\"research-workspace-prod_d1-publisher_1\\"} |= \\"${pid}\\""}]`,
            },
        ]
    );
};

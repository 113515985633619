import { Tree } from 'antd';
import { Key } from 'antd/es/table/interface';
import { EventDataNode } from 'antd/lib/tree';
import type { EntitiesConnection, Entity } from 'entities/EntityDefinition';
import { EntityDefinition, getIdPath, getIndexPath } from 'entities/EntityDefinition';
import { FolderEntity } from 'entities/rw/file/Folder';
import { FilestoreFileEntity } from 'entities/rw/filestore/FilestoreFile';
import { DataNode } from 'rc-tree/lib/interface';
import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import RouteParams from 'types/RouteParams';
import { useClient } from 'urql';
import { defaultFieldTree, search, tableFilter } from 'util/graphql';
import { getPathSegments, removeLeadingSlash } from 'util/url';

const { DirectoryTree } = Tree;

const updateTreeData = (list: DataNode[], key: string | number, children: DataNode[]): DataNode[] =>
    list.map(node => {
        if (node.key === key) return { ...node, children };
        if (node.children) return { ...node, children: updateTreeData(node.children, key, children) };
        return node;
    });

const constructTreeDataFromPath = (path: string, segmentIndex = -1): DataNode[] => {
    const segments = getPathSegments(path);
    return [
        {
            key: segmentIndex === -1 ? '/' : segments.slice(0, segmentIndex + 1).join('/'),
            title: segments[segmentIndex] || '/',
            children: segmentIndex >= segments.length - 1 ? [] : constructTreeDataFromPath(path, segmentIndex + 1),
        },
    ];
};

// E.g. `pathSegmentsToKey(['hi', 'bye', 'try']) => ['/', '/hi', '/hi/bye', '/hi/bye/try']`
const pathSegmentsToKeys = (pathSegments: string[], segmentIndex = -1): string[] =>
    segmentIndex >= pathSegments.length
        ? []
        : [`/${pathSegments.slice(0, segmentIndex + 1).join('/')}`, ...pathSegmentsToKeys(pathSegments, segmentIndex + 1)];

export default function FolderTree({
    entityDefinition,
}: {
    entityDefinition: EntityDefinition<FolderEntity | FilestoreFileEntity>;
}) {
    const { path = '/' } = useParams<RouteParams>();
    const history = useHistory();
    const client = useClient();
    const [treeData, setTreeData] = useState<DataNode[]>(constructTreeDataFromPath(path));

    const fetchPath = async (path: string) => {
        const query = search(entityDefinition, {
            path: `"${path}"`,
            columns: defaultFieldTree(entityDefinition, tableFilter),
        });
        const result = await client.query(query).toPromise();

        const { data, error } = result;
        if (error) return;

        let entities: Entity[] = [];
        if (data) {
            const { nodes }: EntitiesConnection<FolderEntity | FilestoreFileEntity> = data[Object.keys(data)[0]];
            entities = nodes;
        }
        setTreeData(original =>
            updateTreeData(
                original,
                path,
                entities.map(({ path, name, isDirectory }) => ({
                    key: path,
                    title: name,
                    isLeaf: !isDirectory,
                }))
            )
        );
    };

    useEffect(() => {
        fetchPath(path);
    }, []);

    const onSelectOrExpand = (keys: Key[], info: { node: EventDataNode; expanded?: boolean }) => {
        const { node, expanded } = info;
        const { key, isLeaf } = node;
        const filePath = removeLeadingSlash(key as string);
        if (!filePath) return;

        if (isLeaf) {
            const pathSegments = getPathSegments(filePath);
            const id = pathSegments[pathSegments.length - 1];
            if (id) history.push(getIdPath(entityDefinition, id));
        } else if (expanded) {
            window.history.pushState(null, '', `${getIndexPath(entityDefinition)}/tree/${filePath}${window.location.search}`);
            fetchPath(filePath);
        }
    };

    const expandedKeys = pathSegmentsToKeys(getPathSegments(path));

    return (
        <DirectoryTree
            onSelect={onSelectOrExpand}
            onExpand={onSelectOrExpand}
            defaultExpandAll={true}
            treeData={treeData}
            defaultExpandedKeys={expandedKeys}
            loadData={async ({ key, children }) => {
                if (!children) await fetchPath(key as string);
            }}
        />
    );
}

import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { FileEntity } from 'entities/rw/file/File';
import type { AddressEntity } from 'entities/rw/user/Address';
import type { PositionEntity } from 'entities/rw/user/Position';
import type { UserEntity } from 'entities/rw/user/User';

export interface FileContactEntity extends DeletableEntityBase {
    user: UserEntity;
    file: FileEntity;
    address: AddressEntity;
    position: PositionEntity;
}

const shorthand: ShorthandEntityDefinition<FileContactEntity> = {
    key: rw.user.FileContact,
    creatable: true,
    softDeletable: true,
    labelFields: 'title',
    fields: [
        { one: rw.file.File, editable: true, required: true },
        { one: rw.user.User, editable: true, required: true },
        { one: rw.user.Address, editable: true },
        { one: rw.user.Position, editable: true },
    ],
};

export default shorthand;

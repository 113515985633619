import { CrownOutlined, EditOutlined, EyeOutlined } from '@ant-design/icons';
import EntityLink from 'components/entity/EntityLink';
import { EntityLabelProps, getRelationFields } from 'entities/EntityDefinition';
import type { RoleEntityBase } from 'entities/rw/Role';
import { LevelType } from 'entities/rw/Role';
import React from 'react';

export const RoleLevelIcon = ({ level }: { level: LevelType }) => {
    switch (level) {
        case LevelType.owner:
            return <CrownOutlined />;
        case LevelType.writer:
            return <EditOutlined />;
        case LevelType.viewer:
            return <EyeOutlined />;
    }
};

export const RoleLabel = <EntityType extends RoleEntityBase>({
    entityByKey,
    entityDefinition,
    entity: role,
    insideLink,
}: EntityLabelProps<EntityType>) => {
    const { levelName } = role;
    const relationFields = getRelationFields(entityDefinition);
    const resourceEntityKey = relationFields[0].key as string;
    const accessorEntityKey = relationFields[1].key as string;

    return (
        <span style={{ display: 'inline-flex', alignItems: 'center', gap: 10 }}>
            <EntityLink
                entityDefinition={entityByKey[accessorEntityKey]}
                entity={role[accessorEntityKey]}
                insideLink={insideLink}
            />
            <RoleLevelIcon level={levelName} />
            <EntityLink
                entityDefinition={entityByKey[resourceEntityKey]}
                entity={role[resourceEntityKey]}
                insideLink={insideLink}
                style={{ alignItems: 'center' }}
            />
        </span>
    );
};

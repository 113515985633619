import { Button } from 'antd';
import ExternalLink from 'components/text/ExternalLink';
import type { Entity } from 'entities/EntityDefinition';
import { EntityDefinition, getIdPath } from 'entities/EntityDefinition';
import { getEntityLabel } from 'EntityLabel';
import { History } from 'history';
import React, { CSSProperties } from 'react';
import { Link } from 'react-router-dom';

interface Props<EntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    entity: EntityType;
    external?: boolean; // open in another tab
    insideLink?: boolean; // It's convenient to be able to use nested `EntityLink`s inside e.g. entity labels, instead of conditionally rendering just the label
    history?: History; // Only provide if using in a context outside the React router if `useRouterLink = false`
    style?: CSSProperties;
}

export default function EntityLink<EntityType extends Entity>({
    entityDefinition,
    entity,
    external = false,
    insideLink = false,
    history,
    style,
}: Props<EntityType>) {
    const idPath = getIdPath(entityDefinition.key, entity.id);
    const label = getEntityLabel(entityDefinition, entity, { insideLink: true });
    if (insideLink) return <>{label}</>;
    if (external) {
        return (
            <ExternalLink href={idPath} style={style}>
                {label}
            </ExternalLink>
        );
    }
    if (history) {
        return (
            <Button
                type="link"
                onClick={() => {
                    history.push(idPath);
                }}
                style={style}
            >
                {label}
            </Button>
        );
    }
    return (
        <span style={style}>
            <Link to={idPath}>{label}</Link>
        </span>
    );
}

import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { PersonEntity } from 'entities/rw/user/Person';
import type { UserEntity } from 'entities/rw/user/User';

export interface AddressEntity extends DeletableEntityBase {
    email: string;
    address1: string;
    address2: string;
    city: string;
    state: string;
    zip: string;
    fax: string;
    phone: string;
    website: string;
    user: UserEntity;
    person: PersonEntity;
}

const shorthand: ShorthandEntityDefinition<AddressEntity> = {
    key: rw.user.Address,
    creatable: true,
    softDeletable: true,
    labelFields: 'address1',
    defaultSortField: 'address1',
    fields: [
        { key: 'email', editable: true, type: FieldType.email, includeInSummary: true },
        { key: 'address1', editable: true, includeInSummary: true },
        { key: 'address2', editable: true, includeInSummary: true, excludeFromTable: true },
        { key: 'city', editable: true, includeInSummary: true },
        // TODO state field type
        { key: 'state', editable: true, includeInSummary: true },
        // TODO zip field type
        { key: 'zip', editable: true, includeInSummary: true },
        { key: 'fax', editable: true, excludeFromTable: true, includeInSummary: true },
        // TODO phone field type
        { key: 'phone', editable: true },
        // TODO URL field type
        { key: 'website', editable: true, excludeFromTable: true },
        { one: rw.user.User, editable: true },
        { one: rw.user.Person, editable: true },
    ],
};

export default shorthand;

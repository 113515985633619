import type { EntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { FileEntity } from 'entities/rw/file/File';
import type { UserEntity } from 'entities/rw/user/User';

export interface FileHistoryEntity extends EntityBase {
    uuid: string;
    name: string;
    bytes: number;
    mimetype: string;
    userId: number;
    user: UserEntity;
    file: FileEntity;
}

const shorthand: ShorthandEntityDefinition<FileHistoryEntity> = {
    key: rw.file.FileHistory,
    immutable: true,
    fields: [
        { key: 'uuid' },
        { key: 'name' },
        { key: 'mimetype', excludeFromTable: true },
        { key: 'bytes', label: 'Size', type: FieldType.bytes },
        { one: rw.user.User, label: 'Uploader', excludeFromTable: true },
        { one: rw.filestore.File, excludeFromTable: true },
        { one: rw.file.File },
    ],
};

export default shorthand;

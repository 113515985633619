import { FilterValue } from 'antd/es/table/interface';
import { FieldType } from 'entities/Field';
import moment from 'moment';
import { Key } from 'react';

// See `comparatorTooltip` below for explanations
export enum FilterComparator {
    like = 'like',
    nlike = 'nlike',
    eq = 'eq',
    ne = 'ne',
    gt = 'gt',
    lt = 'lt',
    gte = 'gte',
    lte = 'lte',
    range = 'range',
    all = 'all',
    any = 'any',
    in = 'in',
}

export type FilterDateMode = 'time' | 'date' | 'week' | 'month' | 'year';

export interface Filter {
    search?: string;
    // When the search value is an array type (currently used for enums), `searches` will be present and `search` will be `undefined`.
    // Ideally, `search` would be a union `string | string[]` type, but GraphQL/TypeGraphQL does not support this yet, and I want
    // to make the client interface match the server interface for clarity.
    searches?: string[];
    range?: [string, string];
    comparator?: FilterComparator; // TODO make this required
    dateMode?: FilterDateMode;
    exclude?: boolean;
    present?: boolean;
}

export type Filters = Record<string, Filter>;

// @ts-ignore
const comparatorsForFieldType: Record<FieldType, FilterComparator[]> = {
    [FieldType.string]: [FilterComparator.like, FilterComparator.nlike, FilterComparator.eq, FilterComparator.ne],
    [FieldType.date]: [FilterComparator.eq, FilterComparator.range, FilterComparator.gt, FilterComparator.lt],
    [FieldType.number]: [
        FilterComparator.eq,
        FilterComparator.ne,
        FilterComparator.gt,
        FilterComparator.lt,
        FilterComparator.gte,
        FilterComparator.lte,
        FilterComparator.range,
    ],
    [FieldType.entityId]: [
        FilterComparator.eq,
        FilterComparator.ne,
        FilterComparator.gt,
        FilterComparator.lt,
        FilterComparator.gte,
        FilterComparator.lte,
        FilterComparator.range,
    ],
    [FieldType.boolean]: [FilterComparator.eq],
    [FieldType.enum]: [FilterComparator.eq],
    [FieldType.enumList]: [FilterComparator.all, FilterComparator.any, FilterComparator.in],
    [FieldType.jsonPrimitiveList]: [FilterComparator.all, FilterComparator.any, FilterComparator.in],
    [FieldType.json]: [],
};

export const comparatorsForField = (fieldType: FieldType): FilterComparator[] =>
    comparatorsForFieldType[fieldType] || comparatorsForFieldType[FieldType.string];

export const isComparatorValidForField = (comparator: FilterComparator, fieldType: FieldType) =>
    comparatorsForField(fieldType).includes(comparator);

export const defaultComparatorForField = (fieldType: FieldType): FilterComparator => comparatorsForField(fieldType)[0];

export const comparatorLabel = (comparator: FilterComparator): string => {
    switch (comparator) {
        case FilterComparator.like:
            return '≈';
        case FilterComparator.nlike:
            return '≉';
        case FilterComparator.eq:
            return '=';
        case FilterComparator.ne:
            return '≠';
        case FilterComparator.gt:
            return '>';
        case FilterComparator.lt:
            return '<';
        case FilterComparator.gte:
            return '≥';
        case FilterComparator.lte:
            return '≤';
        case FilterComparator.range:
            return '[]';
        case FilterComparator.all:
            return 'All';
        case FilterComparator.any:
            return 'Any';
        case FilterComparator.in:
            return 'In';
    }
};

export const comparatorTooltip = (comparator: FilterComparator): string => {
    switch (comparator) {
        case FilterComparator.like:
            return 'Contains';
        case FilterComparator.nlike:
            return "Doesn't contain";
        case FilterComparator.eq:
            return 'Exactly equal';
        case FilterComparator.ne:
            return 'Not equal';
        case FilterComparator.gt:
            return 'Greater than';
        case FilterComparator.lt:
            return 'Less than';
        case FilterComparator.gte:
            return 'Greater than or equal to';
        case FilterComparator.lte:
            return 'Less than or equal to';
        case FilterComparator.range:
            return 'Within range';
        case FilterComparator.all:
            return 'Contains all selected items';
        case FilterComparator.any:
            return 'Contains at least one of the selected items';
        case FilterComparator.in:
            return 'All items are in the selected list (subset of selection)';
    }
};

// ,: separates filters
// !: field exclusion
// []: demarcates search key & value, surrounds comparator
// -: separates range values & comparator sections (e.g. for date comparators, 'eq-year', 'range-week')
// ;: separates array search values (`searches`)
const filterSpecialChars = [',', '!', '[', ']', '-', ';'];

// These are allowed URL chars: -._~:/?#[]@!$&'()*+,;=
export const urlEncodeFilters = (filters: Filters): string => {
    // Replace special characters used to encode/decode filters.
    const encodeValue = (value: string | number): string => {
        let str = `${value}`;
        filterSpecialChars.forEach(char => {
            const encodedChar = btoa(char);
            if (str.includes(encodedChar)) throw Error(`${encodedChar} is currently not allowed within a search value`);

            str = str.replaceAll(char, encodedChar);
        });

        return str;
    };
    const urlEncodeFilter = (fieldKey: string, filter: Filter): string => {
        const { exclude, present, range, searches, search, comparator, dateMode } = filter;
        if (exclude) return `!${fieldKey}`;
        if (present !== undefined) return `${fieldKey}[${present ? 'present' : 'npresent'}]`;
        if (range) return `${fieldKey}[${FilterComparator.range}]${range.map(encodeValue).join('-')}`;
        if (searches) return `${fieldKey}[${comparator}]${searches.map(encodeValue).join(';')}`;
        if (search) return `${fieldKey}[${comparator}${dateMode ? `-${dateMode}` : ''}]${encodeValue(search)}`;
        return '';
    };

    return `${Object.entries(filters)
        .map(([fieldKey, filter]) => urlEncodeFilter(fieldKey, filter))
        .join(',')}`;
};

export const urlDecodeFilters = (urlFilters: string): Filters | undefined => {
    if (!urlFilters) return undefined;

    const decodeValue = (str: string): string => {
        filterSpecialChars.forEach(char => {
            str = str.replaceAll(btoa(char), char);
        });
        return str;
    };

    return Object.fromEntries(
        urlFilters
            .split(',')
            .map(urlFilter => {
                if (urlFilter.startsWith('!')) return [urlFilter.slice(1), { exclude: true }];
                // eslint-disable-next-line @typescript-eslint/no-unused-vars
                const [_, fieldKey, comparatorAndDateMode, value] = urlFilter.match(/(.*)\[(.*)](.*)/) || [];
                if (comparatorAndDateMode === 'present') return [fieldKey, { present: true }];
                if (comparatorAndDateMode === 'npresent') return [fieldKey, { present: false }];
                const searchOrRange = value.includes('-')
                    ? { range: value.split('-').map(decodeValue) }
                    : value.includes(';')
                    ? { searches: value.split(';').map(decodeValue) }
                    : { search: decodeValue(value) };
                const [comparator, dateMode] = comparatorAndDateMode.split('-');
                return [fieldKey, { ...searchOrRange, comparator, dateMode } as Filter];
            })
            .filter(([k, v]) => k && v)
    );
};

// `FilterValue`s are created by antd tables, and correspond to the `selectedKeys` array.
// Since `selectedKeys` must be an array of `React.Key`s, but we have several types of filtering,
// the convention used here is `selectedKeys = [filterType, comparatorAndDateMode?, ...filterValues?]`, where all items are of type (string|0|1).
// Filter types are: 'exclude', and 'search'.
// For the `exclude` types, the only present value is `filterType`.
export const filterKeysToFilter = (filterKeys: Key[] | null): Filter | undefined => {
    if (filterKeys === null) return undefined;

    const [filterType, comparatorAndDateMode, ...values] = filterKeys;
    if (filterType === 'exclude') return { exclude: true };
    if (filterType === 'present') return { present: true };
    if (filterType === 'npresent') return { present: false };
    if (filterType === 'search') {
        // eslint-disable-next-line no-case-declarations
        const [comparator, dateMode] = (comparatorAndDateMode as string).split('-');
        return {
            ...(values.length > 1
                ? comparator === FilterComparator.range
                    ? { range: [values[0] as string, values[1] as string] }
                    : { searches: values.map(v => v as string) }
                : { search: values[0] as string }),
            comparator: comparator as FilterComparator,
            dateMode: dateMode as FilterDateMode | undefined,
        };
    }

    return undefined;
};

export const filterKeysToFilters = (filterKeys: Record<string, FilterValue | null>): Filters =>
    Object.fromEntries(
        Object.entries(filterKeys)
            .filter(([_, filterValue]) => filterValue)
            .map(([fieldKey, filterValue]) => [fieldKey, filterKeysToFilter(filterValue as Key[] | null)])
            .filter(([_, filter]) => filter)
    );

export const filterToFilterKeys = (filter?: Filter): Key[] | null => {
    if (!filter) return null;

    const { search, searches, range, comparator = FilterComparator.eq, dateMode, exclude, present } = filter;

    if (exclude) return ['exclude'];
    if (present !== undefined) return [present ? 'present' : 'npresent'];

    const comparatorAndDateMode = dateMode ? `${comparator}-${dateMode}` : comparator;
    if (range) return ['search', comparatorAndDateMode, range[0], range[1]];
    if (searches) return ['search', comparatorAndDateMode, ...searches];
    return ['search', comparatorAndDateMode, search || ''];
};

export const filtersToFilterKeys = (filters?: Filters): Record<string, FilterValue | null> =>
    Object.fromEntries(Object.entries(filters || {}).map(([fieldKey, filter]) => [fieldKey, filterToFilterKeys(filter)]));

export const filtersToGraphQl = (filters: Filters): string => {
    const getValue = (filter: Filter) => {
        const convertValue = (
            value: string | string[],
            comparator?: FilterComparator,
            dateMode?: FilterDateMode
        ): string | string[] => {
            if (!dateMode) return value;
            if (dateMode === 'time') return value; // use exact chosen timestamps

            const unit = dateMode === 'date' ? 'day' : dateMode;
            const numberValue = Array.isArray(value) ? value.map(v => Number(v)) : Number(value);

            const epochSecondsToBoundary = (value: number, toEnd?: boolean) => {
                const date = moment.unix(value);
                return (toEnd ? date.endOf(unit) : date.startOf(unit)).unix();
            };

            const boundaryTimestamp = Array.isArray(numberValue)
                ? [epochSecondsToBoundary(numberValue[0]), epochSecondsToBoundary(numberValue[1], true)]
                : // "greater than single date with non-time mode " means, "after the end of the mode's range"
                  epochSecondsToBoundary(numberValue, comparator === FilterComparator.gt);

            return Array.isArray(boundaryTimestamp) ? boundaryTimestamp.map(bt => `${bt}`) : `${boundaryTimestamp}`;
        };

        if (filter.exclude) return 'exclude: true';
        if (filter.present !== undefined) return `present: ${filter.present}`;

        let { search, searches, range, comparator = range ? FilterComparator.range : FilterComparator.eq, dateMode } = filter;

        // Dates are a special case in that "equality" should be treated as a range based on the mode.
        if (dateMode && comparator === FilterComparator.eq && search) {
            comparator = FilterComparator.range;
            range = [search, search]; // These will get modified based on mode below.
            search = undefined;
        }
        if (searches) return `searches: ${JSON.stringify(searches)}, comparator: ${comparator}`;
        if (search) return `search: "${convertValue(search, comparator, dateMode)}", comparator: ${comparator}`;
        if (range) return `range: ${JSON.stringify(convertValue(range, comparator, dateMode))}, comparator: ${comparator}`;

        throw Error(`Incomplete filter: ${JSON.stringify(filter)}`);
    };

    const graphQlFilters = Object.entries(filters).map(([fieldKey, filter]) => `{ field: "${fieldKey}", ${getValue(filter)} }`);
    return `[${graphQlFilters.join(',')}]`;
};

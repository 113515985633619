import FolderTree from 'components/tree/FolderTree';
import type { Entity, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';

export interface FilestoreFileEntity extends Entity {
    name: string;
    path: string;
    isDirectory: boolean;
}

const shorthand: ShorthandEntityDefinition<FilestoreFileEntity> = {
    key: rw.filestore.File,
    idFieldType: FieldType.string,
    hasCreatedField: false,
    label: 'filestore',
    path: 'filestore',
    mainNav: true,
    fields: [
        { key: 'name' },
        { key: 'path' },
        { key: 'isDirectory', type: FieldType.boolean },
        { key: 'bytes', label: 'Size', type: FieldType.bytes },
        { one: rw.file.FileHistory, excludeFromTable: true },
        { one: rw.file.File, excludeFromTable: true },
    ],
    searchable: false,
    hideSearchTab: true,
    indexTabs: [{ key: 'tree', Component: FolderTree }],
};

export default shorthand;

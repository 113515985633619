import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { ArchivePackageEntity } from 'entities/rw/archive/ArchivePackage';
import { createExternalLink, createLinks } from 'entities/rw/archive/shared';
import type { FolderEntity } from 'entities/rw/file/Folder';

export interface ArchiveAttachmentEntity extends DeletableEntityBase {
    submitted: string;
    uuid: string;
    pid: string;
    md5: string;
    archivePackage: ArchivePackageEntity;
    folder: FolderEntity;
}

const shorthand: ShorthandEntityDefinition<ArchiveAttachmentEntity> = {
    key: rw.archive.Attachment,
    shortLabel: 'attachment',
    labelFields: 'pid',
    softDeletable: true,
    fields: [
        { key: 'submitted', type: FieldType.date },
        { key: 'uuid', label: 'UUID' },
        { key: 'pid', label: 'PID' },
        { one: rw.file.Folder },
        { one: rw.archive.Package, excludeFromTable: true, useShortLabel: true },
    ],
    createLinks,
    createExternalLink,
};

export default shorthand;

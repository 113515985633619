import { RWV1_HOST } from 'config';
import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';

export interface ProjectEntity extends DeletableEntityBase {
    updated?: string;
    name: string;
    isArchived?: boolean;
    isPublic?: boolean;
    isAtn?: boolean;
    description?: string;
    fileCount?: number;
    metadataId?: string;
}

const shorthand: ShorthandEntityDefinition<ProjectEntity> = {
    key: rw.project.Project,
    creatable: true,
    softDeletable: true,
    mainNav: true,
    fields: [
        { key: 'label', one: rw.project.Label, userScoped: true, editable: false, searchable: false },
        { key: 'updated', type: FieldType.date },
        { key: 'name', editable: true, required: true },
        { key: 'isArchived', editable: true, type: FieldType.boolean },
        { key: 'isPublic', editable: true, type: FieldType.boolean },
        { key: 'isAtn', label: 'Is ATN', editable: false, type: FieldType.boolean, excludeFromTable: true },
        { key: 'hasReports', editable: true, type: FieldType.boolean, excludeFromTable: true },
        { key: 'fileCount', excludeFromTable: true },
        { key: 'metadataId', label: 'Metadata ID', excludeFromTable: true },
        { key: 'roles', many: rw.project.Role, label: 'Members', expandTableFieldsFor: rw.user.User },
        { key: 'labels', many: rw.project.Label, label: 'Labels', expandTableFieldsFor: rw.user.User },
        { many: rw.organization.ProjectRole, label: 'Organizations', expandTableFieldsFor: rw.organization.Organization },
        { many: rw.organization.ProjectLabel, label: 'Organization labels', expandTableFieldsFor: rw.organization.Organization },
        { many: rw.campaign.ProjectRole, label: 'Campaigns', expandTableFieldsFor: rw.campaign.Campaign },
        { many: rw.campaign.ProjectLabel, label: 'Campaign labels', expandTableFieldsFor: rw.campaign.Campaign },
        { many: rw.file.Folder },
        { many: rw.file.File },
        { many: rw.archive.Package },
        { many: rw.archive.File },
    ],
    createExternalLink: ({ id }) => `${RWV1_HOST}/project/${id}/files`,
    createExternalFieldLink: ({ id }, fieldKey) =>
        fieldKey === 'metadataId' ? `${RWV1_HOST}/project/${id}/metadata` : undefined,
};

export default shorthand;

import { RoleLabel } from 'components/entity/Role';
import type { ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { CampaignEntity } from 'entities/rw/campaign/Campaign';
import type { RoleEntityBase } from 'entities/rw/Role';
import { createRoleFields } from 'entities/rw/Role';
import type { UserEntity } from 'entities/rw/user/User';

export interface CampaignRoleEntity extends RoleEntityBase {
    campaign: CampaignEntity;
    user: UserEntity;
}

const shorthand: ShorthandEntityDefinition<CampaignRoleEntity> = {
    key: rw.campaign.Role,
    shortLabel: 'Membership',
    creatable: true,
    softDeletable: true,
    Label: RoleLabel,
    fields: createRoleFields(rw.campaign.Campaign, rw.user.User),
};

export default shorthand;

import type { EntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { oikos, rw } from 'entities/EntityKeys';
import type { PortalEntity } from 'entities/oikos/Portal';
import type { OrganizationEntity } from 'entities/rw/organization/Organization';

export interface OrganizationPortalEntity extends EntityBase {
    portal: PortalEntity;
    organization: OrganizationEntity;
    tag: string;
}

const shorthand: ShorthandEntityDefinition<OrganizationPortalEntity> = {
    key: rw.portal.Organization,
    creatable: true,
    labelFields: 'tag',
    fields: [
        { key: 'tag', editable: true },
        { one: oikos.Portal, required: true, crossDb: true },
        { one: rw.organization.Organization, required: true },
    ],
};

export default shorthand;

import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { NotebookImageEntity } from 'entities/rw/notebook/NotebookImage';

export interface NotebookImageTagEntity extends DeletableEntityBase {
    name: string;
    sha256: string;
    size: number;
    isLatest: boolean;
    notebookImage: NotebookImageEntity;
}

const shorthand: ShorthandEntityDefinition<NotebookImageTagEntity> = {
    key: rw.notebook.ImageTag,
    shortLabel: 'image tag',
    softDeletable: true,
    fields: [
        { key: 'name', editable: true, required: true },
        { key: 'sha256' },
        { key: 'size', type: FieldType.bytes },
        { key: 'isLatest', type: FieldType.boolean },
        { one: rw.notebook.Image, useShortLabel: true },
    ],
};

export default shorthand;

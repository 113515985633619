import { Result } from 'antd';
import React from 'react';

interface Props {
    message?: string;
}

export default function Error({ message }: Props) {
    return <Result status="warning" title="Something went wrong" extra={message} />;
}

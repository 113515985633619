import EntityLink from 'components/entity/EntityLink';
import { SortOrder } from 'components/search/SortOrder';
import type { EditProps, EntityBase, EntityLabelProps, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { EntityIcon } from 'entities/EntityIcons';
import { rw } from 'entities/EntityKeys';
import { CREATED_FIELD } from 'entities/Field';
import type { EventEffectEntity } from 'entities/rw/event/EventEffect';
import type { EntitySubscriptionEntity } from 'entities/rw/subscription/EntitySubscription';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import React from 'react';

export interface NotificationEntity extends EntityBase {
    entitySubscription: EntitySubscriptionEntity;
    eventEffect: EventEffectEntity;
}

export interface NotificationMutations {
    notifyAuthenticatedUser: DocumentNode; // For admins. See what this in-app notification looks like.
    emailAuthenticatedUser: DocumentNode; // For admins. See what this email notification looks like.
}

const shorthand: ShorthandEntityDefinition<NotificationEntity> = {
    key: rw.subscription.Notification,
    mainNav: true,
    immutable: true,
    defaultSortField: CREATED_FIELD.key,
    defaultSortOrder: SortOrder.desc,
    fields: [
        { one: rw.subscription.Subscription, includeInSummary: true },
        { one: rw.event.Effect, includeInSummary: true },
        { many: rw.subscription.NotificationRecord },
    ],
    Label: ({ entityByKey, entity: notification, insideLink }: EntityLabelProps<NotificationEntity>) => {
        const { entitySubscription, eventEffect } = notification;
        const { subscriber } = entitySubscription;

        return (
            <span style={{ display: 'inline-flex', alignItems: 'center' }}>
                <EntityLink entityDefinition={entityByKey[rw.event.Effect]} entity={eventEffect} insideLink={insideLink} />
                <EntityIcon entityKey={rw.subscription.Notification} style={{ fontSize: 12, margin: '0px 8px' }} />
                <EntityLink entityDefinition={entityByKey[rw.user.User]} entity={subscriber} insideLink={insideLink} />
            </span>
        );
    },
    Edit: ({ EntityForm, entityDefinition, entity }: EditProps<NotificationEntity>) => {
        const { id } = entity;
        const { notifyAuthenticatedUser, emailAuthenticatedUser } =
            entityDefinition.mutations as unknown as NotificationMutations;

        return (
            <EntityForm
                entityDefinition={entityDefinition}
                initialEntity={entity}
                submits={[
                    {
                        messageKey: 'notifyMe',
                        children: 'Notify me',
                        help: 'Trigger this notification to send you an in-app notification, to view it exactly as it would appear to subscribers',
                        loadingMessage: 'Sending notification...',
                        successMessage: 'Notification sent',
                        submitMutation: notifyAuthenticatedUser,
                        transformMutationValues: () => ({ id }),
                        previewable: false,
                    },
                    {
                        messageKey: 'emailMe',
                        children: 'Email me',
                        help: 'Trigger this notification to send you an email, to view it exactly as it would appear to subscribers',
                        loadingMessage: 'Sending email...',
                        successMessage: 'Email sent',
                        submitMutation: emailAuthenticatedUser,
                        transformMutationValues: () => ({ id }),
                        previewable: false,
                    },
                ]}
            />
        );
    },
    mutations: {
        notifyAuthenticatedUser: gql`
            mutation ($id: Float!) {
                notificationNotifyAuthenticatedUser(id: $id) {
                    id
                }
            }
        `,
        emailAuthenticatedUser: gql`
            mutation ($id: Float!) {
                notificationEmailAuthenticatedUser(id: $id) {
                    id
                }
            }
        `,
    },
};

export default shorthand;

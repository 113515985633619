import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { UserEntity } from 'entities/rw/user/User';

export interface NotebookImageEntity extends DeletableEntityBase {
    name: string;
    description: string;
    kernel: string;
    owner: UserEntity;
}

const shorthand: ShorthandEntityDefinition<NotebookImageEntity> = {
    key: rw.notebook.Image,
    creatable: true,
    softDeletable: true,
    shortLabel: 'image',
    defaultSortField: 'name',
    fields: [
        { key: 'name', editable: true, required: true },
        { key: 'description', editable: true, type: FieldType.longString },
        {
            key: 'kernel',
            editable: true,
            required: true,
            type: FieldType.enum,
            options: ['ir', 'julia - 1.4', 'octave', 'python2', 'python3', 'ruby'],
        },
        { key: 'owner', one: rw.user.User },
        { many: rw.notebook.ImageTag, label: 'Tags' },
    ],
};

export default shorthand;

import { CloseOutlined, LoadingOutlined, SearchOutlined } from '@ant-design/icons';
import { useDebounceCallback } from '@react-hook/debounce';
import { Input } from 'antd';
import { EntityDefinition, getEntityDefinitionLabel } from 'entities/EntityDefinition';
import pluralize from 'pluralize';
import React, { useState } from 'react';
import { useQueryParams } from 'util/url';

interface Props {
    onChange: (search: string) => void;
    entityDefinition?: EntityDefinition<any>;
    fetching?: boolean;
}

export default function SearchInput({ onChange, entityDefinition, fetching = false }: Props) {
    const doSearch = useDebounceCallback(onChange, 200, false);
    const { search } = useQueryParams();
    const [searchText, setSearchText] = useState(search);

    return (
        <Input
            autoFocus={true}
            style={{ width: '100%' }}
            placeholder={`Search${entityDefinition ? ` ${pluralize(getEntityDefinitionLabel(entityDefinition))}` : ''}...`}
            onChange={event => {
                const newSearchText = event.target.value || '';
                setSearchText(newSearchText);
                doSearch(newSearchText);
            }}
            value={searchText}
            suffix={
                fetching ? (
                    <LoadingOutlined />
                ) : searchText ? (
                    <CloseOutlined
                        onClick={() => {
                            setSearchText('');
                            doSearch('');
                        }}
                        style={{ fontSize: 10 }}
                    />
                ) : (
                    <SearchOutlined />
                )
            }
        />
    );
}

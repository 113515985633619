import { RoleLabel } from 'components/entity/Role';
import type { ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { CampaignEntity } from 'entities/rw/campaign/Campaign';
import type { OrganizationEntity } from 'entities/rw/organization/Organization';
import type { RoleEntityBase } from 'entities/rw/Role';
import { createRoleFields } from 'entities/rw/Role';

export interface CampaignOrganizationRoleEntity extends RoleEntityBase {
    organization: OrganizationEntity;
    campaign: CampaignEntity;
}

const shorthand: ShorthandEntityDefinition<CampaignOrganizationRoleEntity> = {
    key: rw.organization.CampaignRole,
    shortLabel: 'Campaign membership',
    creatable: true,
    softDeletable: true,
    Label: RoleLabel,
    fields: createRoleFields(rw.organization.Organization, rw.campaign.Campaign),
};

export default shorthand;

import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { OrganizationEntity } from 'entities/rw/organization/Organization';
import type { VirtualOrganizationEntity } from 'entities/rw/organization/VirtualOrganization';
import type { PersonEntity } from 'entities/rw/user/Person';
import type { UserEntity } from 'entities/rw/user/User';

export interface PositionEntity extends DeletableEntityBase {
    title: string;
    current: boolean;
    startYear: number;
    endYear: number;
    startMonth: number;
    endMonth: number;
    user?: UserEntity;
    creator?: UserEntity;
    contact?: PersonEntity;
    organization?: OrganizationEntity;
    virtualOrganization?: VirtualOrganizationEntity;
}

const shorthand: ShorthandEntityDefinition<PositionEntity> = {
    key: rw.user.Position,
    creatable: true,
    softDeletable: true,
    labelFields: 'title',
    defaultSortField: 'title',
    fields: [
        { key: 'title', editable: true, required: true, includeInSummary: true },
        { key: 'current', type: FieldType.boolean, editable: true, excludeFromTable: true },
        // TODO year/month field types
        { key: 'startYear', type: FieldType.number, editable: true, excludeFromTable: true },
        { key: 'startMonth', type: FieldType.number, editable: true, excludeFromTable: true },
        { key: 'endMonth', type: FieldType.number, editable: true, excludeFromTable: true },
        { one: rw.user.User, editable: true },
        { key: 'creator', one: rw.user.User },
        { key: 'contact', one: rw.user.Person, editable: true },
        { one: rw.organization.Organization, editable: true, includeInSummary: true },
        { one: rw.organization.VirtualOrganization, editable: true, includeInSummary: true },
    ],
};

export default shorthand;

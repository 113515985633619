import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';

export interface PersonEntity extends DeletableEntityBase {
    name: string;
    lastName: string;
    firstName: string;
}

const shorthand: ShorthandEntityDefinition<PersonEntity> = {
    key: rw.user.Person,
    creatable: true,
    softDeletable: true,
    defaultSortField: 'lastName',
    labelFields: ['firstName', 'lastName'],
    fields: [
        { key: 'name', excludeFromTable: true, excludeFromDetail: true, hide: true },
        { key: 'firstName', editable: true, required: true },
        { key: 'lastName', editable: true, required: true },
    ],
};

export default shorthand;

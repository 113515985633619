import { CloseOutlined, DownloadOutlined, EditOutlined, WarningOutlined } from '@ant-design/icons';
import { Alert, Space, Spin } from 'antd';
import SimpleEntitiesTable from 'components/entity/SimpleEntitiesTable';
import { PreviewContentsProps } from 'components/form/SubmitButton';
import { RWV1_HOST } from 'config';
import type {
    DeletableEntityBase,
    DetailTabProps,
    EntitiesConnection,
    ShorthandEntityDefinition,
} from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { FolderEntity } from 'entities/rw/file/Folder';
import type { ProjectEntity } from 'entities/rw/project/Project';
import type { UserEntity } from 'entities/rw/user/User';
import gql from 'graphql-tag';
import React from 'react';
import MonacoEditor from 'react-monaco-editor';
import { useQuery } from 'urql';
import colors from 'util/colors';
import { isFileEditable, languageForFilename } from 'util/file';

export interface FileEntity extends DeletableEntityBase {
    uuid: string;
    name: string;
    bytes: number;
    mimetype: string;
    userId: number;
    user: UserEntity;
    project: ProjectEntity;
    folder: FolderEntity;
    contents?: string;
}

function FileEditor({ entity, entityDefinition }: DetailTabProps<FileEntity>) {
    const { id, name } = entity;

    // The file entity detail query does not include contents for efficiency, so we fetch that here.
    const query = gql`{${entityDefinition.key}(id: ${id}) { id, contents }}`;
    const [result] = useQuery({ query });
    const { fetching, data, error } = result;
    if (error) return <Alert message={error.message} type="error" />;
    if (fetching && !data) return <Spin />;

    const { contents } = data[entityDefinition.key];

    return (
        <>
            <p>
                <i>Note: Editor does not provide save functionality just yet! (Coming sooooon.)</i>
            </p>
            <MonacoEditor
                width="100%"
                height="600"
                language={languageForFilename(name)}
                theme="vs-light"
                value={contents}
                options={{}}
                onChange={() => console.log('changed')}
                editorDidMount={editor => editor.focus()}
            />
        </>
    );
}

const shorthand: ShorthandEntityDefinition<FileEntity> = {
    key: rw.file.File,
    mainNav: true,
    softDeletable: true,
    deletable: true,
    fields: [
        { key: 'uuid', label: 'UUID' },
        { key: 'name', editable: true, required: true },
        { key: 'bytes', label: 'Size', type: FieldType.bytes },
        { key: 'mimetype', excludeFromTable: true },
        { one: rw.project.Project, excludeFromTable: true, includeInSummary: true },
        { one: rw.file.Folder, editable: true, includeInSummary: true },
        { one: rw.user.User, label: 'Uploader', excludeFromTable: true },
        { one: rw.filestore.File, excludeFromTable: true },
        { many: rw.archive.File },
        { many: rw.file.FileHistory, label: 'History' },
        { key: 'objectFileRelations', many: rw.file.FileRelation, label: 'Object files' },
        { key: 'subjectFileRelations', many: rw.file.FileRelation, label: 'Subject files' },
        { many: rw.notebook.HtmlCache },
        { many: rw.notebook.Metadata },
        { many: rw.notebook.Session },
        { many: rw.user.FileContact },
    ],
    createDetailTabs: ({ name }) =>
        isFileEditable(name) ? [{ key: 'editor', label: 'File editor', Component: FileEditor }] : [],
    createExternalLink: ({ id, name }) =>
        `${RWV1_HOST}/${isFileEditable(name, true) ? 'file' : 'files'}/${id}/${encodeURIComponent(name)}`,
    createExternalLinkIcon: ({ name }: FileEntity) => (isFileEditable(name, true) ? <EditOutlined /> : <DownloadOutlined />),
    indexActions: [
        {
            messageKey: 'purge',
            children: (
                <>
                    <CloseOutlined /> Purge expired files
                </>
            ),
            help: 'Purge files that have been deleted for at least six months',
            loadingMessage: 'Purging expired deleted files...',
            successMessage: 'Files purged',
            danger: true,
            modal: {
                title: (
                    <Space>
                        <WarningOutlined style={{ color: colors.warning, fontSize: 20 }} />
                        <>Purge expired files</>
                    </Space>
                ),
                contents: (
                    <p>Are you sure you want to completely delete all files that have been deleted for at least six months?</p>
                ),
                okText: 'Purge',
                okType: 'danger',
            },
            PreviewContents: function ({ entityDefinition, response }: PreviewContentsProps<FileEntity>) {
                if (!response) return <>No preview available</>;

                const expiredFilesConnection: EntitiesConnection<FileEntity> = response.purgeExpiredFiles;
                const expiredFiles = expiredFilesConnection.nodes;
                if (!expiredFiles?.length) return <>No expired files to purge.</>;

                return (
                    <>
                        <p style={{ fontWeight: 'bold' }}>Number of deleted files: {expiredFiles.length}</p>
                        <SimpleEntitiesTable entityDefinition={entityDefinition} entities={expiredFiles} />
                    </>
                );
            },
            submitMutation: gql`
                mutation ($preview: Boolean, $ageSeconds: Float) {
                    purgeExpiredFiles(preview: $preview, ageSeconds: $ageSeconds) {
                        totalCount
                        nodes {
                            id
                            name
                            uuid
                            deleted
                        }
                    }
                }
            `,
        },
    ],
};

export default shorthand;

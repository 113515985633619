import io from 'socket.io-client';

// TODO would be nice to update the server to socket.io 4.x, as this
//  would enable clients to use > 2.x. Challenge there is coordinating
//  with the Java client.

// **Now that workspace-graphql supports websocket subscriptions and provides a notification pub/sub system,
// we can just ditch this legacy one, which doesn't actually provide much value.**

export const Events = {
    newMessage: 'new-message',
    reload: 'reload',
};

let socket: typeof io.Socket;
export async function getSocket(): Promise<typeof io.Socket> {
    if (socket) return socket;

    return new Promise((resolve, reject) => {
        const tempSocket = io('https://io.researchworkspace.com/workspace');
        tempSocket.on('connect_error', (err: any) => {
            console.log(`connect_error due to ${err.message}`);
            reject(err);
        });
        tempSocket.on('connect', () => {
            socket = tempSocket;
            resolve(socket);
        });
    });
}

export async function emitUserEvent(event: string, userId: number, message?: string) {
    const socket = await getSocket();
    socket.emit('global', {
        event,
        user: userId,
        channel: `user:${userId}`,
        message,
    });
}

import { useLocation } from 'react-router-dom';

export function pathWithQueryParams(params: Record<string, string | boolean | number | undefined>, href?: string): string {
    const url = new URL(href || window.location.href);
    Object.entries(params).forEach(([key, value]) => {
        if (value !== undefined && value !== '') {
            url.searchParams.set(key, `${value}`);
        } else {
            url.searchParams.delete(key);
        }
    });
    // Commas, colons, semicolons, and bracket are fine in urls, and we use them directly in filters, but `searchParams.set` url-encodes them.
    return `${url.pathname}${url.search
        .replaceAll(encodeURIComponent(','), ',')
        .replaceAll(encodeURIComponent('['), '[')
        .replaceAll(encodeURIComponent(']'), ']')
        .replaceAll(encodeURIComponent(':'), ':')
        .replaceAll(encodeURIComponent(';'), ';')}`;
}

export const useQueryParams = (): Record<string, string> => Object.fromEntries(new URLSearchParams(useLocation().search));

export const removeLeadingSlash = (path: string) => path.replace(/^\//, '');

export const removeLeadingAndTrailingSlashes = (path: string) => path.replace(/^\/|\/$/g, '');

export const getPathSegments = (path: string) =>
    removeLeadingAndTrailingSlashes(path)
        .split('/')
        .filter(segment => segment);

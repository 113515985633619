import React, { useState } from 'react';
import { Input } from 'antd';
import { RWV1_HOST } from 'config';
import type { DeletableEntityBase, EditProps, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import { emitUserEvent, Events } from 'services/SocketService';

export interface UserEntity extends DeletableEntityBase {
    username: string;
    email: string;
    roles?: string[];
    firstName?: string;
    lastName?: string;
    googleId?: string;
    hasProfilePicture?: boolean;
    hasPassword?: boolean;
    color?: string;
}

const shorthand: ShorthandEntityDefinition<UserEntity> = {
    key: rw.user.User,
    mainNav: true,
    creatable: true,
    softDeletable: true,
    defaultSortField: 'username',
    labelFields: ['firstName', 'lastName'],
    fields: [
        { key: 'username', required: true, includeInSummary: true },
        { key: 'email', editable: true, required: true, type: FieldType.email, includeInSummary: true },
        { key: 'firstName', editable: true, required: true, includeInSummary: true },
        { key: 'lastName', editable: true, required: true, includeInSummary: true },
        { key: 'googleId', label: 'Google ID', hideNull: true, excludeFromTable: true },
        { key: 'password', editable: true, type: FieldType.password, writeOnly: true, searchable: false },
        { key: 'hasPassword', hide: true, excludeFromTable: true },
        { key: 'roles', editable: true, type: FieldType.enumList, options: ['admin'] },
        { many: rw.organization.Role, label: 'Organizations', expandTableFieldsFor: rw.organization.Organization },
        { many: rw.campaign.Role, label: 'Campaigns', expandTableFieldsFor: rw.campaign.Campaign },
        { many: rw.project.Role, label: 'Projects', expandTableFieldsFor: rw.project.Project },
        { many: rw.file.File },
        { many: rw.file.FileHistory },
        { many: rw.archive.Package, label: 'Submitted archives' },
        { many: rw.archive.SubmissionHistory, label: 'Archive submission history' },
        { many: rw.user.Address },
        { many: rw.user.Position },
        { many: rw.user.FileContact },
        { many: rw.notebook.Session },
        { many: rw.notebook.Image },
        { many: rw.subscription.Subscription },
        { many: rw.subscription.Notification },
        { key: 'actions', many: rw.event.Event, label: 'Actions' },
    ],
    createExternalLink: ({ username }) => `${RWV1_HOST}/u/${username}`,
    createFieldExtra: ({ googleId }, fieldKey) =>
        fieldKey === 'email' &&
        googleId &&
        "This user signs in with Google, so if updating email, make sure it matches the user's new Google account.",
    Edit: ({ EntityForm, entityDefinition, entity }: EditProps<UserEntity>) => {
        const { id } = entity;
        const [userMessage, setUserMessage] = useState('');

        return (
            <EntityForm
                entityDefinition={entityDefinition}
                initialEntity={entity}
                submits={[
                    {
                        messageKey: 'send',
                        children: 'Send message',
                        loadingMessage: 'Sending message...',
                        successMessage: 'Message sent',
                        doAction: async () => emitUserEvent(Events.newMessage, id, userMessage || undefined),
                        modal: {
                            title: 'Send message',
                            contents: (
                                <Input
                                    addonBefore=">"
                                    placeholder="Message text"
                                    onChange={event => setUserMessage(event.target.value)}
                                />
                            ),
                        },
                    },
                    {
                        messageKey: 'reload',
                        children: 'Reload browser',
                        loadingMessage: "Reloading user's browser...",
                        successMessage: 'User browser reloaded',
                        doAction: async () => emitUserEvent(Events.reload, id),
                    },
                ]}
            />
        );
    },
};

export default shorthand;

import type { EntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';

export interface VirtualOrganizationEntity extends EntityBase {
    name: string;
}

const shorthand: ShorthandEntityDefinition<VirtualOrganizationEntity> = {
    key: rw.organization.VirtualOrganization,
    creatable: true,
    mainNav: true,
    defaultSortField: 'name',
    fields: [{ key: 'name', editable: true, required: true }],
};

export default shorthand;

import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { CampaignEntity } from 'entities/rw/campaign/Campaign';
import type { OrganizationEntity } from 'entities/rw/organization/Organization';

export interface ArchivePathEntity extends DeletableEntityBase {
    disabled: boolean; // TODO copy all `disabled` values to `deleted` and rm `disabled` field
    template: string;
    destination: string;
    organization: OrganizationEntity;
    campaign: CampaignEntity;
}

const shorthand: ShorthandEntityDefinition<ArchivePathEntity> = {
    key: rw.archive.Path,
    label: 'archive path',
    shortLabel: 'path',
    softDeletable: true,
    fields: [
        {
            key: 'disabled',
            editable: true,
            type: FieldType.boolean,
            help: 'If disabled, data coordinators can not submit archives for this path.',
        },
        { key: 'destination', editable: true, type: FieldType.enum, options: ['DATAONE', 'NCEI'] },
        { key: 'template', excludeFromTable: true, editable: true, type: FieldType.longString },
        { one: rw.organization.Organization },
        { one: rw.campaign.Campaign },
    ],
};

export default shorthand;

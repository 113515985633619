import { EyeOutlined, UploadOutlined } from '@ant-design/icons';
import { Button, Dropdown, Menu, message, Modal, Tooltip, Upload, UploadProps } from 'antd';
import { DropdownButtonType } from 'antd/es/dropdown';
import EventView from 'components/entity/rw/event/EventView';
import EntityForm from 'components/form/EntityForm';
import GraphQlExplorer from 'components/GraphQlExplorer';
import { entityByKey } from 'entities/Entities';
import { Entity, EntityDefinition, getIdPath, ObjectLiteral, SubmitModalProps } from 'entities/EntityDefinition';
import { EntityIcon } from 'entities/EntityIcons';
import { rw } from 'entities/EntityKeys';
import Event, { EventEntity, EventType, EventTypeType } from 'entities/rw/event/Event';
import { DocumentNode } from 'graphql';
import { History } from 'history';
import { MenuInfo } from 'rc-menu/lib/interface';
import React, { CSSProperties, ReactElement, ReactNode, useState } from 'react';
import ReactJson from 'react-json-view';
import { useHistory } from 'react-router';
import { CombinedError, useMutation } from 'urql';
import colors from 'util/colors';
import { capitalize } from 'util/string';

const PREVIEW_MESSAGE_KEY = 'preview';

interface PreviewModalProps {
    messageKey: string;
    visible?: boolean;
    onOk: () => void;
    onCancel: () => void;
    children?: ReactNode;
}

const PreviewModal = ({ messageKey, visible, onOk, onCancel, children }: PreviewModalProps) => (
    <Modal
        title={
            <>
                {capitalize(messageKey)} preview
                <p style={{ fontSize: 12, marginTop: 5, marginBottom: 0 }}>
                    Committing this {messageKey} would result in the following effects:
                </p>
            </>
        }
        visible={visible}
        onOk={onOk}
        onCancel={onCancel}
        okText={`Commit ${messageKey}`}
        okType="primary"
    >
        {children}
    </Modal>
);

const getEntityFromResponse = (response: ObjectLiteral): Entity => response[Object.keys(response)[0]];
const getEventFromResponseEntity = (responseEntity: ObjectLiteral, eventType?: EventTypeType): EventEntity =>
    eventType === EventType.undo ? responseEntity : responseEntity?.event;
const getEventFromResponse = (response: ObjectLiteral, eventType?: EventTypeType): EventEntity =>
    getEventFromResponseEntity(getEntityFromResponse(response), eventType);

export interface PreviewContentsProps<EntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    response: ObjectLiteral;
}

function DefaultPreviewContents<EntityType extends Entity>({ response }: PreviewContentsProps<EntityType>) {
    if (!response) return <>No preview available</>;

    const responseEntity = getEntityFromResponse(response);
    if (!responseEntity) return <>No entity present in response</>;

    const event = getEventFromResponseEntity(responseEntity);
    if (!event) {
        return (
            <>
                <>No event present in response entity. Response:</>
                <ReactJson src={responseEntity} />
            </>
        );
    }

    return (
        <EventView
            EntityForm={EntityForm}
            entityByKey={entityByKey}
            entityDefinition={entityByKey[rw.event.Event]}
            entity={event}
            affectedEntity={responseEntity}
            isPreview
        />
    );
}

// Everything needed to specify the behavior of a submit action (usually a GraphQL mutation/query).
export interface SubmitOptions<EntityType extends Entity> {
    messageKey: string;
    type?: EventTypeType;
    mutationValues?: ObjectLiteral;
    transformMutationValues?: (values: ObjectLiteral) => ObjectLiteral;
    submitMutation?: DocumentNode;
    onSubmitComplete?: (response: ObjectLiteral, history: History) => void;
    doAction?: () => void;
    loadingMessage?: ReactNode;
    successMessage?: ReactNode;
    danger?: boolean;
    success?: boolean;
    dashed?: boolean;
    modal?: SubmitModalProps;
    help?: string;
    uploadProps?: UploadProps;
    disabled?: boolean;
    previewable?: boolean; // defaults to `true`
    // The default preview expects a response with, as the value for its first key, an entity containing an event (or just a top-level event).
    // You can override that behavior here.
    PreviewContents?: (props: PreviewContentsProps<EntityType>) => ReactElement;
    children?: ReactNode;
    onError?: (error: CombinedError) => void;
}

export interface SubmitProps<EntityType extends Entity> extends SubmitOptions<EntityType> {
    entityDefinition: EntityDefinition<EntityType>;
}

export default function SubmitButton<EntityType extends Entity>({
    entityDefinition,
    messageKey,
    type,
    previewable = true,
    PreviewContents = DefaultPreviewContents,
    danger,
    success,
    dashed,
    modal,
    submitMutation,
    help,
    uploadProps,
    disabled,
    doAction,
    onSubmitComplete,
    loadingMessage,
    successMessage,
    mutationValues,
    transformMutationValues,
    onError,
    children,
}: SubmitProps<EntityType>) {
    const history = useHistory();
    const isCreateOrUpdate = type && (type === EventType.create || type === EventType.update);
    const primary = isCreateOrUpdate || type === EventType.undo;
    const [isConfirmModalVisible, setConfirmModalVisible] = useState(false);
    const [previewResponse, setPreviewResponse] = useState<ObjectLiteral | undefined>(undefined);

    const [, mutate] = useMutation(submitMutation || '');
    const transformedValues = mutationValues && (transformMutationValues?.(mutationValues) || mutationValues);

    const onSubmit = async (preview = false) => {
        if (!preview && loadingMessage) message.loading({ key: messageKey, content: loadingMessage, duration: 0 });
        if (preview) message.loading({ key: PREVIEW_MESSAGE_KEY, content: 'Loading preview...', duration: 0 });

        if (doAction) {
            // Manual action, rather than the usual previewable mutation.
            doAction();
            if (successMessage) message.success({ key: messageKey, content: successMessage, duration: 6 });
            return;
        }

        if (submitMutation && transformedValues) {
            if (previewable) transformedValues.preview = preview;
            const result = await mutate(transformedValues as any);
            if (!result) return;

            const { data, error } = result;
            if (error) {
                onError?.(error);
                message.destroy(messageKey);
                return;
            }

            if (!data) return;

            const event = getEventFromResponse(data, type);
            if (preview) {
                setPreviewResponse(data);
                message.destroy(PREVIEW_MESSAGE_KEY);
                return;
            }

            if (event) {
                successMessage = (
                    <span>
                        {successMessage}
                        <br />
                        <Button
                            type="link"
                            icon={<EntityIcon entityKey={Event.key} style={{ fontSize: 12, top: 0, marginRight: 0 }} />}
                            style={{ color: colors.success, fontSize: 12, padding: 0, marginBottom: -10 }}
                            onClick={() => {
                                history.push(getIdPath(entityByKey[Event.key], event.id));
                                message.destroy(messageKey);
                            }}
                        >
                            View event
                        </Button>
                    </span>
                );
            }

            if (successMessage) message.success({ key: messageKey, content: successMessage, duration: 6 });
            onSubmitComplete?.(data, history);
        }
    };

    // This is about handling both naked buttons and buttons wrapped in a Dropdown.Button.
    const decorateSubmit = (Button: ReactElement, isMenuTrigger = false) => {
        let isSuccess = type === EventType.create || success;
        const style: CSSProperties = {};
        if (isSuccess && !disabled) {
            if (isMenuTrigger) style.borderLeftColor = colors.successLight;
            else style.borderRightColor = colors.successLight;
        }

        let Submit = React.cloneElement(Button, { danger, className: isSuccess ? 'ant-btn-success' : undefined, style });
        if (isMenuTrigger) return Submit;

        if (uploadProps) Submit = <Upload {...uploadProps}>{Submit}</Upload>;
        if (help) Submit = <Tooltip title={help}>{Submit}</Tooltip>;
        return Submit;
    };

    // These props are the only ones that belong at the top level for both <Button> and <Dropdown.Button>.
    const submitSharedProps = {
        type: (dashed ? 'dashed' : primary ? 'primary' : 'default') as DropdownButtonType,
        htmlType: (primary ? 'submit' : 'button') as 'submit' | 'button',
        disabled,
        onClick: () => {
            if (modal) setConfirmModalVisible(true);
            else onSubmit();
        },
    };
    const buttonChildren = (
        <span className="form-button-content">
            {uploadProps && (
                <>
                    <UploadOutlined />{' '}
                </>
            )}
            {children}
        </span>
    );

    const Submit = submitMutation ? (
        <Dropdown.Button
            {...submitSharedProps}
            placement="topRight"
            overlay={
                <Menu
                    onClick={({ key }: MenuInfo) => {
                        if (key === 'preview') {
                            onSubmit(true);
                        }
                    }}
                >
                    {previewable && (
                        <Menu.Item
                            key="preview"
                            icon={
                                <Tooltip title={`Preview the results of this ${messageKey} without actually executing it`}>
                                    <EyeOutlined />
                                </Tooltip>
                            }
                        >
                            Preview
                        </Menu.Item>
                    )}
                    <Menu.Item key="explore">
                        <GraphQlExplorer
                            position="before"
                            query={submitMutation}
                            variables={{ ...transformedValues, preview: previewable ? true : undefined }}
                            iconOnly
                            iconClassName="ant-dropdown-menu-item-icon"
                            iconStyle={{ width: 14, height: 12 }}
                            childLabel={messageKey}
                            tooltipPlacement="top"
                        >
                            Explore
                        </GraphQlExplorer>
                    </Menu.Item>
                </Menu>
            }
            buttonsRender={([leftButton, rightButton]) => [
                decorateSubmit(leftButton as ReactElement),
                decorateSubmit(rightButton as ReactElement, true),
            ]}
        >
            {buttonChildren}
        </Dropdown.Button>
    ) : (
        decorateSubmit(<Button {...submitSharedProps}>{buttonChildren}</Button>)
    );

    return (
        <>
            {Submit}
            {modal && (
                <Modal
                    title={modal.title}
                    visible={isConfirmModalVisible}
                    onOk={async () => {
                        setConfirmModalVisible(false);
                        await onSubmit();
                    }}
                    onCancel={() => setConfirmModalVisible(false)}
                    okText={modal.okText || 'OK'}
                    okType={modal.okType || 'primary'}
                >
                    {modal.contents}
                </Modal>
            )}
            <PreviewModal
                messageKey={messageKey}
                visible={!!previewResponse}
                onOk={() => {
                    setPreviewResponse(undefined);
                    onSubmit();
                }}
                onCancel={() => {
                    setPreviewResponse(undefined);
                }}
            >
                {previewResponse && <PreviewContents response={previewResponse} entityDefinition={entityDefinition} />}
            </PreviewModal>
        </>
    );
}

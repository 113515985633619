import { useDebounceCallback } from '@react-hook/debounce';
import { Alert, Divider, Select, Spin } from 'antd';
import EntityLink from 'components/entity/EntityLink';
import GraphQlExplorer from 'components/GraphQlExplorer';
import { findEntityDefinition } from 'entities/Entities';
import type { Entity } from 'entities/EntityDefinition';
import { EntityDefinition, getEntityDefinitionLabel, isLabelFieldKey } from 'entities/EntityDefinition';
import { EntityIcon } from 'entities/EntityIcons';
import { formatField, isOne } from 'entities/Field';
import { getEntityLabel } from 'EntityLabel';
import pluralize from 'pluralize';
import React, { ReactNode, useState } from 'react';
import { useQuery } from 'urql';
import { find, search, summaryFieldTree } from 'util/graphql';

interface InlineSearchLabelProps<EntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    entity: EntityType;
}

export function InlineSearchLabel<EntityType extends Entity>({ entityDefinition, entity }: InlineSearchLabelProps<EntityType>) {
    const { fields } = entityDefinition;

    const elements = fields
        .filter(({ key, includeInSummary }) => includeInSummary || isLabelFieldKey(entityDefinition, key))
        .map(field => {
            const value = entity[field.key];
            if (!value) return undefined;

            const isOneField = isOne(field);
            const relationEntityDefinition = isOneField && findEntityDefinition(field.one);
            if (isOneField && !relationEntityDefinition) {
                throw Error(`Could not find entity definition for key ${field.one}`);
            }

            return (
                <>
                    {isOneField && relationEntityDefinition ? (
                        <>
                            <EntityIcon entityKey={field.one} />{' '}
                            {getEntityLabel(relationEntityDefinition, value, { maxLength: 36 })}
                        </>
                    ) : (
                        value
                    )}
                </>
            );
        })
        .filter(element => element);

    // Show just the ID as a last resort
    if (elements.length === 0) return <span>#{entity.id}</span>;

    return (
        <span>
            {elements.map((element, i) => (
                <span key={`${i}`}>
                    {element}
                    {i < elements.length - 1 && <Divider type="vertical" />}
                </span>
            ))}
        </span>
    );
}

// See `labelInValue` under https://ant.design/components/select/#Select-props
interface Value {
    value: number;
    label: ReactNode;
}

interface Props<EntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    value?: EntityType;
    onChange: (value?: EntityType) => void;
    editing?: boolean;
    style?: Record<string, unknown>;
}

const entityToValue = <EntityType extends Entity>(entity: EntityType, entityDefinition: EntityDefinition<EntityType>): Value => ({
    value: entity.id,
    label: <InlineSearchLabel entity={entity} entityDefinition={entityDefinition} />,
});

const valueToEntity = ({ value }: Value): Entity => ({ id: value });

// This can be used within antd <Form.Item /> since forms only require `value` and `onChange` params to work properly.
// This class translates between an input `number` value type, which is an entity ID, and the `Value` type that antd wants.
export default function EntitySearchSelect<EntityType extends Entity>({
    entityDefinition,
    value,
    onChange,
    editing = false,
    style,
}: Props<EntityType>) {
    const { key } = entityDefinition;
    const summary = summaryFieldTree(entityDefinition);
    const [searchValue, setSearchValue] = useState('');
    const doSearch = useDebounceCallback((searchValue: string) => setSearchValue(searchValue), 200, false);

    const searchQuery = search(entityDefinition, { pageSize: 20, search: searchValue, columns: summary });
    const [findResult] = useQuery({ pause: !value, query: find(entityDefinition, value?.id || 0, summary) });
    const [searchResult] = useQuery({ query: searchQuery });

    const { fetching: finding, data: findData, error: findError } = findResult;
    const { fetching: searching, data: searchData, error: searchError } = searchResult;
    if (findError || searchError) return <Alert message={findError?.message || searchError?.message} type="error" />;

    const entity: EntityType = value && findData?.[key];
    const entities: EntityType[] = searchData?.[Object.keys(searchData)[0]]?.nodes || [];
    if (!editing) return entity ? <EntityLink entityDefinition={entityDefinition} entity={entity} /> : <>{formatField(null)}</>;

    const pluralLabel = pluralize(getEntityDefinitionLabel(entityDefinition));

    return (
        <GraphQlExplorer
            query={searchQuery}
            position="before"
            childLabel="graphql"
            buttonStyle={{ marginLeft: 0 }}
            style={{ display: 'flex', alignItems: 'center', width: '100%' }}
        >
            <Select
                style={style}
                value={entity && entityToValue(entity, entityDefinition)}
                options={entities.map(entity => entityToValue(entity, entityDefinition))}
                labelInValue
                allowClear
                showSearch
                placeholder={`Search ${pluralLabel}...`}
                filterOption={false}
                onSearch={searchValue => doSearch(searchValue)}
                onChange={newValue => onChange(newValue && (valueToEntity(newValue) as EntityType))}
                notFoundContent={finding || searching ? <Spin size="small" /> : `No matching ${pluralLabel} found`}
            />
        </GraphQlExplorer>
    );
}

import type { EntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { ArchivePackageEntity } from 'entities/rw/archive/ArchivePackage';
import { createExternalLink, createLinks } from 'entities/rw/archive/shared';
import type { UserEntity } from 'entities/rw/user/User';

export interface ArchiveSubmissionHistoryEntity extends EntityBase {
    submitted: string;
    indexed: string;
    received: string;
    type: string;
    pid: string;
    metadataPid: string;
    seriesId: string;
    archivePackage: ArchivePackageEntity;
    submitter: UserEntity;
}

const shorthand: ShorthandEntityDefinition<ArchiveSubmissionHistoryEntity> = {
    key: rw.archive.SubmissionHistory,
    label: 'archive submission history',
    shortLabel: 'submission history',
    labelFields: 'pid',
    fields: [
        { key: 'submitted', type: FieldType.date },
        { key: 'indexed', type: FieldType.date },
        { key: 'received', type: FieldType.date },
        { key: 'type' },
        { key: 'pid', label: 'PID' },
        { key: 'metadataPid', label: 'Metadata PID', hideNull: true, excludeFromTable: true },
        { key: 'seriesId', label: 'Series ID', hideNull: true, excludeFromTable: true },
        { key: 'submitter', one: rw.user.User },
        { one: rw.archive.Package, label: 'Package', excludeFromTable: true },
    ],
    createLinks,
    createExternalLink,
};

export default shorthand;

import { RWV1_HOST } from 'config';
import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { oikos, rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { UserEntity } from 'entities/rw/user/User';

export interface OrganizationEntity extends DeletableEntityBase {
    name: string;
    abbr?: string;
    description?: string;
    creator: UserEntity;
}

const shorthand: ShorthandEntityDefinition<OrganizationEntity> = {
    key: rw.organization.Organization,
    creatable: true,
    softDeletable: true,
    mainNav: true,
    defaultSortField: 'name',
    fields: [
        { key: 'name', editable: true, required: true },
        { key: 'abbr', label: 'Abbreviation', editable: true },
        { key: 'description', editable: true, type: FieldType.longString },
        { key: 'creator', one: rw.user.User, excludeFromTable: true, excludeFromCreate: true },
        { key: 'roles', many: rw.organization.Role, label: 'Members', expandTableFieldsFor: rw.user.User },
        { many: rw.organization.CampaignRole, label: 'Campaigns', expandTableFieldsFor: rw.campaign.Campaign },
        { many: rw.organization.ProjectRole, label: 'Projects', expandTableFieldsFor: rw.project.Project },
        { many: rw.organization.ProjectLabel, label: 'Project labels', expandTableFieldsFor: rw.project.Project },
        { many: rw.archive.Package },
        { many: rw.archive.Path },
        { many: rw.user.Position },
        { many: rw.portal.Organization, label: 'Portals', expandTableFieldsFor: oikos.Portal },
        {
            key: 'embargos',
            many: rw.organization.ProjectEmbargo,
            label: 'Project embargos',
            expandTableFieldsFor: rw.project.Project,
        },
    ],
    createExternalLink: ({ id, name }) => `${RWV1_HOST}/org/${id}/${name.replace(/ /g, '-')}`,
};

export default shorthand;

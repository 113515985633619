import { CheckCircleTwoTone, StopTwoTone } from '@ant-design/icons';
import React from 'react';
import colors from 'util/colors';

const style = { verticalAlign: 'middle' };

export default function BooleanIcon({ value }: { value: boolean }) {
    return value ? (
        <CheckCircleTwoTone twoToneColor={colors.success} style={{ ...style, fontSize: 18 }} />
    ) : (
        <StopTwoTone twoToneColor="#f5222d" style={{ ...style, fontSize: 16 }} />
    );
}

import { Button, Col, Row, Typography } from 'antd';
import { useAuth } from 'AuthContext';
import { getIndexPath } from 'entities/EntityDefinition';
import { SignInRoute } from 'Pages';
import React from 'react';
import { Redirect } from 'react-router-dom';

const { Title } = Typography;

export default function Logout() {
    const { authenticatedUser, logout } = useAuth();
    if (!authenticatedUser) return <Redirect to={getIndexPath(SignInRoute)} />;

    return (
        <Row justify="center">
            <Col style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                <Title level={2}>Signed in as {authenticatedUser.email}</Title>
                <Button style={{ width: '100%', maxWidth: '300px' }} onClick={logout} type="primary">
                    Sign out
                </Button>
            </Col>
        </Row>
    );
}

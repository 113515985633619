import { ArrowRightOutlined, CloseCircleOutlined, DownOutlined, FilterOutlined, RightOutlined } from '@ant-design/icons';
import { Divider, Tag } from 'antd';
import FieldTreeLeafLabel from 'components/search/FieldTreeLeafLabel';
import { EntityDefinition } from 'entities/EntityDefinition';
import { fieldPathsToFieldTree, getLeaves } from 'entities/FieldTree';
import React, { useState } from 'react';
import { Filters } from 'util/filters';
import { capitalize } from 'util/string';

interface Props {
    entityDefinition: EntityDefinition<any>;
    filters: Filters;
    removeFilter: (fieldPathKey: string) => void;
}

export default function FilterList({ entityDefinition, filters = {}, removeFilter }: Props) {
    const [expanded, setExpanded] = useState(false);

    return (
        <div style={{ border: '1px solid #d9d9d9', borderRadius: 2, padding: '4px 8px', background: '#fcfcfc' }}>
            <div>
                <FilterOutlined style={{ alignSelf: 'center', fontSize: 12, marginRight: 4 }} />
                <span style={{ marginRight: 5 }}>Active filters</span>
                {expanded && <DownOutlined style={{ fontSize: 12 }} onClick={() => setExpanded(false)} />}
                {!expanded && <RightOutlined style={{ fontSize: 12 }} onClick={() => setExpanded(true)} />}
            </div>
            {expanded && (
                <ul style={{ margin: 0, paddingLeft: 12 }}>
                    {Object.entries(filters).map(([fieldPathKey, filter]) => {
                        const path = fieldPathKey.split(':');
                        const fieldTree = fieldPathsToFieldTree([path], entityDefinition);
                        const leaf = getLeaves(fieldTree)[0];
                        const filterEntries = Object.entries(filter).filter(([_, v]) => v !== undefined);

                        return (
                            <li key={fieldPathKey} style={{ display: 'flex', alignItems: 'center', margin: '6px 0' }}>
                                <Tag className="filter label">
                                    <FieldTreeLeafLabel
                                        field={leaf.field}
                                        parentField={leaf.parentField}
                                        pathLabels={path.slice(0, path.length - 1)}
                                    />
                                </Tag>
                                <ArrowRightOutlined style={{ fontSize: 10, margin: '0 4px' }} />
                                <Tag className="filter value">
                                    {filterEntries.map(([k, v], i) => (
                                        <span key={k}>
                                            <span style={{ fontSize: 13 }}>{capitalize(k)}:</span>{' '}
                                            <span style={{ fontStyle: 'italic' }}>{`${v}`}</span>
                                            {i !== filterEntries.length - 1 && <Divider type="vertical" />}
                                        </span>
                                    ))}
                                    <CloseCircleOutlined
                                        style={{ fontSize: 10 }}
                                        onClick={() => {
                                            removeFilter(fieldPathKey);
                                        }}
                                    />
                                </Tag>
                            </li>
                        );
                    })}
                </ul>
            )}
        </div>
    );
}

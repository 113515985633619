import type { EmbargoEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import { CampaignEntity } from 'entities/rw/campaign/Campaign';
import type { ProjectEntity } from 'entities/rw/project/Project';

export interface CampaignProjectEmbargoEntity extends EmbargoEntityBase {
    campaign: CampaignEntity;
    project: ProjectEntity;
}

const shorthand: ShorthandEntityDefinition<CampaignProjectEmbargoEntity> = {
    key: rw.campaign.ProjectEmbargo,
    shortLabel: 'Project embargo',
    creatable: true,
    fields: [
        { key: 'embargoDate', type: FieldType.date, required: true },
        { one: rw.campaign.Campaign, required: true },
        { one: rw.project.Project, required: true },
    ],
};

export default shorthand;

import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { FileEntity } from 'entities/rw/file/File';

export interface NotebookMetadataEntity extends DeletableEntityBase {
    kernel: string;
    file: FileEntity;
}

const shorthand: ShorthandEntityDefinition<NotebookMetadataEntity> = {
    key: rw.notebook.Metadata,
    searchKey: 'notebookMetadatas', // needed since `pluralize('notebookMetadata') => 'notebookMetadata'`
    shortLabel: 'metadata',
    softDeletable: true,
    fields: [
        {
            key: 'kernel',
            type: FieldType.enum,
            // These come from the distinct values currently in the DB (8/20/2021)
            // TODO: Note the difference from the options in `NotebookImageEntity`.
            //  These should be aligned. Or really we should just remove this `kernel` metadata in favor of image tags?
            options: [
                'cfec - scrape',
                'conda',
                'conda - env - anaconda3 - r',
                'ir',
                'julia - 1.4',
                'octave',
                'python2',
                'python3',
                'ruby',
            ],
        },
        { one: rw.file.File },
    ],
};

export default shorthand;

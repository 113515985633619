import { RoleLabel } from 'components/entity/Role';
import type { ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { OrganizationEntity } from 'entities/rw/organization/Organization';
import type { ProjectEntity } from 'entities/rw/project/Project';
import type { RoleEntityBase } from 'entities/rw/Role';
import { createRoleFields } from 'entities/rw/Role';

export interface ProjectOrganizationRoleEntity extends RoleEntityBase {
    organization: OrganizationEntity;
    project: ProjectEntity;
}

const shorthand: ShorthandEntityDefinition<ProjectOrganizationRoleEntity> = {
    key: rw.organization.ProjectRole,
    shortLabel: 'Project membership',
    creatable: true,
    softDeletable: true,
    Label: RoleLabel,
    fields: createRoleFields(rw.organization.Organization, rw.project.Project),
};

export default shorthand;

import { RoleLabel } from 'components/entity/Role';
import type { ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { CampaignEntity } from 'entities/rw/campaign/Campaign';
import type { ProjectEntity } from 'entities/rw/project/Project';
import type { RoleEntityBase } from 'entities/rw/Role';
import { createRoleFields } from 'entities/rw/Role';

export interface ProjectCampaignRoleEntity extends RoleEntityBase {
    campaign: CampaignEntity;
    project: ProjectEntity;
}

const shorthand: ShorthandEntityDefinition<ProjectCampaignRoleEntity> = {
    key: rw.campaign.ProjectRole,
    shortLabel: 'Project membership',
    creatable: true,
    Label: RoleLabel,
    fields: createRoleFields(rw.campaign.Campaign, rw.project.Project),
};

export default shorthand;

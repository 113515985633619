import { ArrowLeftOutlined, ArrowRightOutlined } from '@ant-design/icons';
import React, { JSXElementConstructor } from 'react';

interface Props {
    LeftIcon: JSXElementConstructor<any>;
    RightIcon: JSXElementConstructor<any>;
    className?: string;
}

const sharedStyle = { margin: 0, lineHeight: 1, verticalAlign: 'middle' };

export default function LinkIcon({ LeftIcon, RightIcon, className }: Props) {
    return (
        <span className={className} style={{ whiteSpace: 'nowrap' }}>
            <LeftIcon style={{ ...sharedStyle, fontSize: 11, marginRight: 3 }} />
            <ArrowLeftOutlined style={{ ...sharedStyle, fontSize: 6 }} />
            <ArrowRightOutlined style={{ ...sharedStyle, fontSize: 6 }} />
            <RightIcon style={{ ...sharedStyle, fontSize: 11, marginLeft: 3 }} />
        </span>
    );
}

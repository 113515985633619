import type { EmbargoEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { OrganizationEntity } from 'entities/rw/organization/Organization';
import type { ProjectEntity } from 'entities/rw/project/Project';

export interface OrganizationProjectEmbargoEntity extends EmbargoEntityBase {
    organization: OrganizationEntity;
    project: ProjectEntity;
}

const shorthand: ShorthandEntityDefinition<OrganizationProjectEmbargoEntity> = {
    key: rw.organization.ProjectEmbargo,
    shortLabel: 'Project embargo',
    creatable: true,
    fields: [
        { key: 'embargoDate', type: FieldType.date, required: true },
        { one: rw.organization.Organization, required: true },
        { one: rw.project.Project, required: true },
    ],
};

export default shorthand;

import * as Sentry from '@sentry/react';
import { multipartFetchExchange } from '@urql/exchange-multipart-fetch';
import { Layout } from 'antd';
import { AuthProvider } from 'AuthContext';
import MainContent from 'components/MainContent';
import Sidebar from 'components/Sidebar';
import { GRAPHQL_HOST } from 'config';
import { entityByKey } from 'entities/Entities';
import Event from 'entities/rw/event/Event';
import FilestoreFile from 'entities/rw/filestore/FilestoreFile';
import User from 'entities/rw/user/User';
import { print } from 'graphql';
import gql from 'graphql-tag';
import React from 'react';
import { useLocation } from 'react-router-dom';
import wsClient from 'services/WebSocketService';
import { createClient, dedupExchange, errorExchange, Provider, subscriptionExchange } from 'urql';
import { defaultFieldTree, detailFilter, graphQlQuery } from 'util/graphql';
import log from 'util/log';
import { getPathSegments, pathWithQueryParams } from 'util/url';

log.info('urql createClient: %s', GRAPHQL_HOST);

// Add any additional graphql queries and mutations once on load.
// This is here instead of in the entities' definitions, because entity query generation
// relies on relation entities already being defined.
const userQuery = print(graphQlQuery(defaultFieldTree(entityByKey[User.key], detailFilter)));
const eventQuery = print(graphQlQuery(defaultFieldTree(entityByKey[Event.key], detailFilter)));

User.queries = {
    getAuthenticatedUser: gql`
        {
            authenticatedUser ${userQuery}
        }
    `,
};
User.mutations = {
    login: gql`
        mutation ($email: String!, $password: String!) {
            login(data: { email: $email, password: $password }) ${userQuery}
        }
    `,
    logout: gql`
        mutation {
            logout {
                id
                email
            }
        }
    `,
};

Event.mutations = {
    undoEvent: gql`
        mutation ($id: Float!, $preview: Boolean) {
            undoEvent(id: $id, preview: $preview) ${eventQuery}
        }
    `,
};

const graphQlClient = createClient({
    url: `${GRAPHQL_HOST}/graphql`,
    fetchOptions: () => ({ credentials: 'include' }), // https://github.com/FormidableLabs/urql/issues/170
    exchanges: [
        dedupExchange,
        // Not using cache since it was causing weird complex bugs, incorrectly mixing up bits from different requests/responses.
        // cacheExchange({ schema } as any),
        errorExchange({
            onError(error) {
                const redirectToRootWithQueryParams = (queryParams: Record<string, string>) => {
                    window.location.href = pathWithQueryParams(
                        {
                            ...queryParams,
                            // track where to come back to after successful sign in
                            redirectPath: `${window.location.pathname.replace('/', '')}${window.location.search}`,
                        },
                        window.location.origin
                    );
                };

                if (error?.graphQLErrors?.some(error => error.extensions?.code === 'UNAUTHENTICATED')) {
                    redirectToRootWithQueryParams({ require_auth: 'true' });
                } else if (error?.graphQLErrors?.some(error => error.message?.includes('not supported for user with role'))) {
                    redirectToRootWithQueryParams({ requires_admin: 'true' });
                }
            },
        }),
        multipartFetchExchange,
        subscriptionExchange({
            forwardSubscription: operation => ({
                subscribe: sink => ({
                    unsubscribe: wsClient.subscribe(operation, sink),
                }),
            }),
        }),
    ],
});

export default Sentry.withProfiler(() => {
    const location = useLocation();
    const pathSegments = getPathSegments(location.pathname);
    const pageKey = pathSegments[0];

    // Filestore ID is the full (unencoded) path, and can either be an /id/{path}, or /tree/{path} link.
    // No other entities can contain slashes in their IDs.
    const entityIdGroups = location.pathname.match(pageKey === FilestoreFile.path ? /\/id\/(.*)/i : /\/id\/([^/]*)/i);
    const entityId = entityIdGroups?.[entityIdGroups?.length - 1];

    return (
        <Provider value={graphQlClient}>
            <AuthProvider>
                <Layout style={{ minHeight: '100vh' }}>
                    <Sidebar pageKey={pageKey} />
                    <Layout className="site-layout">
                        <MainContent pageKey={pageKey} entityId={entityId} />
                    </Layout>
                </Layout>
            </AuthProvider>
        </Provider>
    );
});

import type { Entity, EntityDefinition, EntityDefinitionMap, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import type {
    Field,
    ManyFieldShorthand,
    OneFieldShorthand,
    RelationField,
    RelationFieldShorthand,
    ShorthandField,
} from 'entities/Field';
import { FieldType, CREATED_FIELD, DELETED_FIELD, ID_FIELD } from 'entities/Field';
import Portal from 'entities/oikos/Portal';
import ArchiveAttachment from 'entities/rw/archive/ArchiveAttachment';
import ArchiveFile from 'entities/rw/archive/ArchiveFile';
import ArchivePackage from 'entities/rw/archive/ArchivePackage';
import ArchivePath from 'entities/rw/archive/ArchivePath';
import ArchiveSubmissionHistory from 'entities/rw/archive/ArchiveSubmissionHistory';
import Campaign from 'entities/rw/campaign/Campaign';
import CampaignRole from 'entities/rw/campaign/CampaignRole';
import ProjectCampaignLabel from 'entities/rw/campaign/ProjectCampaignLabel';
import ProjectCampaignRole from 'entities/rw/campaign/ProjectCampaignRole';
import CampaignProjectEmbargo from 'entities/rw/embargo/CampaignProjectEmbargo';
import OrganizationProjectEmbargo from 'entities/rw/embargo/OrganizationProjectEmbargo';
import Event from 'entities/rw/event/Event';
import EventEffect from 'entities/rw/event/EventEffect';
import File from 'entities/rw/file/File';
import FileHistory from 'entities/rw/file/FileHistory';
import FileRelation from 'entities/rw/file/FileRelation';
import Folder from 'entities/rw/file/Folder';
import FilestoreFile from 'entities/rw/filestore/FilestoreFile';
import NotebookHtmlCache from 'entities/rw/notebook/NotebookHtmlCache';
import NotebookImage from 'entities/rw/notebook/NotebookImage';
import NotebookImageTag from 'entities/rw/notebook/NotebookImageTag';
import NotebookMetadata from 'entities/rw/notebook/NotebookMetadata';
import NotebookSession from 'entities/rw/notebook/NotebookSession';
import CampaignOrganizationRole from 'entities/rw/organization/CampaignOrganizationRole';
import Organization from 'entities/rw/organization/Organization';
import OrganizationRole from 'entities/rw/organization/OrganizationRole';
import ProjectOrganizationLabel from 'entities/rw/organization/ProjectOrganizationLabel';
import ProjectOrganizationRole from 'entities/rw/organization/ProjectOrganizationRole';
import VirtualOrganization from 'entities/rw/organization/VirtualOrganization';
import CampaignPortal from 'entities/rw/portal/CampaignPortal';
import OrganizationPortal from 'entities/rw/portal/OrganizationPortal';
import Project from 'entities/rw/project/Project';
import ProjectLabel from 'entities/rw/project/ProjectLabel';
import ProjectRole from 'entities/rw/project/ProjectRole';
import EntitySubscription from 'entities/rw/subscription/EntitySubscription';
import Notification from 'entities/rw/subscription/Notification';
import NotificationRecord from 'entities/rw/subscription/NotificationRecord';
import Address from 'entities/rw/user/Address';
import ContactType from 'entities/rw/user/ContactType';
import FileContact from 'entities/rw/user/FileContact';
import Person from 'entities/rw/user/Person';
import Position from 'entities/rw/user/Position';
import User from 'entities/rw/user/User';
import pluralize from 'pluralize';
import { camelOrSnakeCaseToSentence } from 'util/string';

const entityShorthandDefinitions: ShorthandEntityDefinition<any>[] = [
    Event,
    EventEffect,

    User,
    Person,
    FileContact,
    ContactType,
    Address,
    Position,

    Organization,
    OrganizationRole,
    ProjectOrganizationRole,
    ProjectOrganizationLabel,
    CampaignOrganizationRole,
    VirtualOrganization,
    OrganizationProjectEmbargo,

    Campaign,
    CampaignRole,
    ProjectCampaignRole,
    ProjectCampaignLabel,
    CampaignProjectEmbargo,

    Project,
    ProjectRole,
    ProjectLabel,

    Folder,
    File,
    FileRelation,
    FileHistory,

    ArchivePackage,
    ArchiveFile,
    ArchiveAttachment,
    ArchiveSubmissionHistory,
    ArchivePath,

    NotebookSession,
    NotebookImage,
    NotebookImageTag,
    NotebookMetadata,
    NotebookHtmlCache,
    FilestoreFile,

    Portal,
    CampaignPortal,
    OrganizationPortal,

    EntitySubscription,
    Notification,
    NotificationRecord,
];

const isOne = (field?: ShorthandField): field is OneFieldShorthand => !!field && 'one' in field;
const isMany = (field?: ShorthandField): field is ManyFieldShorthand => !!field && 'many' in field;
const isRelation = (field?: ShorthandField): field is RelationFieldShorthand => isOne(field) || isMany(field);
const getShorthandRelationEntityKey = (field: RelationFieldShorthand): string => (isOne(field) ? field.one : field.many);
const shorthandDefinitionByKey: Record<string, ShorthandEntityDefinition<any>> = Object.fromEntries(
    entityShorthandDefinitions.map(shorthand => [shorthand.key, shorthand])
);
const getShorthandRelationDefinition = (relation: RelationFieldShorthand) =>
    shorthandDefinitionByKey[getShorthandRelationEntityKey(relation)];

export const getSearchKey = (entityDefinition: ShorthandEntityDefinition<any>) =>
    entityDefinition.searchKey || pluralize(entityDefinition.key);
export const getLabel = ({ key, label, shortLabel }: ShorthandEntityDefinition<any>, shorten = false): string =>
    (shorten && shortLabel) || label || camelOrSnakeCaseToSentence(key);

export const getFieldLabel = (field: ShorthandField): string => {
    const { key, label } = field;
    return label
        ? label
        : isRelation(field) && !key
        ? getLabel(getShorthandRelationDefinition(field), isOne(field) ? field.useShortLabel : undefined)
        : camelOrSnakeCaseToSentence(key as string);
};

export const hydrateField = (field: ShorthandField): Field | RelationField => {
    const label = getFieldLabel(field);
    if ('one' in field) return { key: field.key || field.one, ...field, label };
    if ('many' in field) return { key: field.key || getSearchKey(shorthandDefinitionByKey[field.many]), ...field, label };
    return { type: FieldType.string, ...field, label }; // Must be a non-relation shorthand field. Default to string type.
};

const hydrateEntityDefinition = <EntityType extends Entity>(
    shorthand: ShorthandEntityDefinition<EntityType>
): EntityDefinition<EntityType> => ({
    ...shorthand,
    fields: [
        { ...ID_FIELD, type: shorthand.idFieldType || ID_FIELD.type },
        ...(shorthand.hasCreatedField !== false ? [CREATED_FIELD] : []),
        ...(shorthand.softDeletable && !shorthand.immutable ? [DELETED_FIELD] : []),
        ...shorthand.fields.map(hydrateField),
    ],
});

export const allEntities = entityShorthandDefinitions.map(hydrateEntityDefinition);
export const entityByKey: EntityDefinitionMap = Object.fromEntries(allEntities.map(entity => [entity.key, entity]));
export const entityByPath: EntityDefinitionMap = Object.fromEntries(
    allEntities.map(entityDefinition => [entityDefinition.path || entityDefinition.key, entityDefinition])
);

export const findEntityDefinition = (keyOrPath?: string): EntityDefinition<any> | undefined => {
    if (!keyOrPath) return undefined;
    if (keyOrPath === 'filestore') keyOrPath = FilestoreFile.key;

    const entityDefinition = entityByKey[keyOrPath] || entityByPath[keyOrPath];
    return entityDefinition && 'fields' in entityDefinition ? entityDefinition : undefined;
};

export const getRelationDefinition = (relation: RelationField) => entityByKey[getShorthandRelationEntityKey(relation)];

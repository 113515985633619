export const isNumber = (str: string) => str && !Number.isNaN(Number(str));
export const isBoolean = (str: string) => str === 'false' || str === 'true';
export const capitalize = (value: string) => `${value.charAt(0).toUpperCase()}${value.substring(1)}`;
export const uncapitalize = (value: string) => `${value.charAt(0).toLowerCase()}${value.substring(1)}`;
export const truncate = (value: string, maxLength: number) =>
    value.length > maxLength ? value.substr(0, maxLength - 1) + '...' : value;

// Split on camel-case/snake-case and convert each word to lower case (caller can `capitalize` the result if wanted).
// E.g. thisIsALongKey_here => this is a long key here
// (All GraphQL fields are in camelCase, but we handle underscores for unstructured JSON keys.)
// TODO Capitalize common acronyms, e.g. URL & JSON, and remove the custom field `label`s that are only there to handle this.
export const camelOrSnakeCaseToSentence = (snakeCase: string): string =>
    snakeCase
        .split(/(?=[A-Z])/) // split by camelCase
        .map(part => part.split('_')) // split by snake_case
        .flat()
        .map(part => part.toLowerCase())
        .join(' ');

// E.g. This is a long key here => thisIsALongKeyHere
// Used to create keys from labels.
// TODO this is only used in one place and I think it's not needed (already have unique keys on relations?)
export const sentenceToSnakeCase = (sentence: string): string =>
    uncapitalize(
        sentence
            .split(' ')
            .map(part => capitalize(part))
            .join('')
    );

import type { LabelEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { ProjectEntity } from 'entities/rw/project/Project';
import type { UserEntity } from 'entities/rw/user/User';

export interface ProjectLabelEntity extends LabelEntityBase {
    user: UserEntity;
    project: ProjectEntity;
}

const shorthand: ShorthandEntityDefinition<ProjectLabelEntity> = {
    key: rw.project.Label,
    creatable: true,
    softDeletable: true,
    fields: [
        { key: 'name', editable: true, required: true },
        { one: rw.user.User, required: true },
        { one: rw.project.Project, required: true },
    ],
};

export default shorthand;

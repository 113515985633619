import { BookOutlined, CloudServerOutlined, LoginOutlined, LogoutOutlined } from '@ant-design/icons';
import Login from 'components/auth/Login';
import Logout from 'components/auth/Logout';
import { EntityDefinition } from 'entities/EntityDefinition';
import { EntityGroupIcon, EntityIcon } from 'entities/EntityIcons';
import Portal from 'entities/oikos/Portal';
import ArchiveAttachment from 'entities/rw/archive/ArchiveAttachment';
import ArchiveFile from 'entities/rw/archive/ArchiveFile';
import ArchivePackage from 'entities/rw/archive/ArchivePackage';
import ArchivePath from 'entities/rw/archive/ArchivePath';
import ArchiveSubmissionHistory from 'entities/rw/archive/ArchiveSubmissionHistory';
import Campaign from 'entities/rw/campaign/Campaign';
import CampaignRole from 'entities/rw/campaign/CampaignRole';
import ProjectCampaignLabel from 'entities/rw/campaign/ProjectCampaignLabel';
import ProjectCampaignRole from 'entities/rw/campaign/ProjectCampaignRole';
import CampaignProjectEmbargo from 'entities/rw/embargo/CampaignProjectEmbargo';
import OrganizationProjectEmbargo from 'entities/rw/embargo/OrganizationProjectEmbargo';
import Event from 'entities/rw/event/Event';
import File from 'entities/rw/file/File';
import FileHistory from 'entities/rw/file/FileHistory';
import FileRelation from 'entities/rw/file/FileRelation';
import Folder from 'entities/rw/file/Folder';
import FilestoreFile from 'entities/rw/filestore/FilestoreFile';
import NotebookHtmlCache from 'entities/rw/notebook/NotebookHtmlCache';
import NotebookImage from 'entities/rw/notebook/NotebookImage';
import NotebookImageTag from 'entities/rw/notebook/NotebookImageTag';
import NotebookMetadata from 'entities/rw/notebook/NotebookMetadata';
import NotebookSession from 'entities/rw/notebook/NotebookSession';
import CampaignOrganizationRole from 'entities/rw/organization/CampaignOrganizationRole';
import Organization from 'entities/rw/organization/Organization';
import OrganizationRole from 'entities/rw/organization/OrganizationRole';
import ProjectOrganizationLabel from 'entities/rw/organization/ProjectOrganizationLabel';
import ProjectOrganizationRole from 'entities/rw/organization/ProjectOrganizationRole';
import VirtualOrganization from 'entities/rw/organization/VirtualOrganization';
import CampaignPortal from 'entities/rw/portal/CampaignPortal';
import OrganizationPortal from 'entities/rw/portal/OrganizationPortal';
import Project from 'entities/rw/project/Project';
import ProjectLabel from 'entities/rw/project/ProjectLabel';
import ProjectRole from 'entities/rw/project/ProjectRole';
import EntitySubscription from 'entities/rw/subscription/EntitySubscription';
import Notification from 'entities/rw/subscription/Notification';
import NotificationRecord from 'entities/rw/subscription/NotificationRecord';
import Address from 'entities/rw/user/Address';
import FileContact from 'entities/rw/user/FileContact';
import Person from 'entities/rw/user/Person';
import Position from 'entities/rw/user/Position';
import User from 'entities/rw/user/User';
import React, { ReactElement } from 'react';

export interface Labeled {
    key: string;
    label?: string; // If `label` is not present, the field label will be the key, split on casing
    shortLabel?: string;
}

// Plain `RouteDefinition`s are for routes that don't show up in the side nav (e.g. logout).
export interface RouteDefinition extends Labeled {
    path?: string;
    mainNav?: boolean;
}

// Only to pull component out of the RouteDefinition so that EntityDefinition can use a different render strategy.
export interface DisplayableRouteDefinition extends RouteDefinition {
    icon: ReactElement;
    Component: () => ReactElement;
}

export interface EntityGroupDefinition extends DisplayableRouteDefinition {
    entityDefinitions: EntityDefinition<any>[];
}

export type Page = DisplayableRouteDefinition | EntityGroupDefinition | EntityDefinition<any>;

export const SignInRoute: DisplayableRouteDefinition = {
    key: 'signIn',
    icon: <LoginOutlined />,
    Component: Login,
};

export const SignOutRoute: DisplayableRouteDefinition = {
    key: 'signOut',
    icon: <LogoutOutlined />,
    Component: Logout,
};

export const allPages = [
    SignInRoute,
    SignOutRoute,
    {
        key: 'users',
        label: 'User',
        icon: <EntityIcon entityKey={User.key} />,
        entityDefinitions: [User, Person, FileContact, Position, Address],
    },
    {
        key: 'organizations',
        label: 'Organization',
        icon: <EntityIcon entityKey={Organization.key} />,
        entityDefinitions: [
            Organization,
            OrganizationRole,
            CampaignOrganizationRole,
            ProjectOrganizationRole,
            ProjectOrganizationLabel,
            OrganizationProjectEmbargo,
            VirtualOrganization,
        ],
    },
    {
        key: 'campaigns',
        label: 'Campaign',
        icon: <EntityIcon entityKey={Campaign.key} />,
        entityDefinitions: [Campaign, CampaignRole, ProjectCampaignRole, ProjectCampaignLabel, CampaignProjectEmbargo],
    },
    {
        key: 'projects',
        label: 'Project',
        icon: <EntityIcon entityKey={Project.key} />,
        entityDefinitions: [Project, ProjectRole, ProjectLabel],
    },
    {
        key: 'files',
        label: 'File',
        icon: <EntityIcon entityKey={File.key} />,
        entityDefinitions: [Folder, File, FileHistory, FileRelation],
    },
    {
        key: 'notebook',
        icon: <EntityGroupIcon Icon={BookOutlined} />,
        entityDefinitions: [NotebookSession, NotebookImage, NotebookImageTag, NotebookMetadata, NotebookHtmlCache],
    },
    {
        key: 'archive',
        icon: <EntityGroupIcon Icon={CloudServerOutlined} />,
        entityDefinitions: [ArchivePackage, ArchiveFile, ArchiveAttachment, ArchivePath, ArchiveSubmissionHistory],
    },
    FilestoreFile,
    {
        key: 'oikosPortal',
        icon: <EntityIcon entityKey={Portal.key} />,
        entityDefinitions: [Portal, OrganizationPortal, CampaignPortal],
    },
    Event,
    {
        key: 'subscriptions',
        label: 'Subscription',
        icon: <EntityIcon entityKey={EntitySubscription.key} />,
        entityDefinitions: [EntitySubscription, Notification, NotificationRecord],
    },
] as Page[];

export const findPage = (keyOrPath?: string): DisplayableRouteDefinition | EntityDefinition<any> | undefined => {
    if (!keyOrPath) return undefined;
    return allPages
        .flatMap(page => ('entityDefinitions' in page ? page.entityDefinitions : page))
        .find(page => page.key.toLowerCase() === keyOrPath.toLowerCase() || page.path?.toLowerCase() === keyOrPath.toLowerCase());
};

export const findParentPage = (keyOrPath?: string): Page | undefined => {
    if (!keyOrPath) return undefined;
    return allPages.find(page => {
        if ('entityDefinitions' in page) {
            const { entityDefinitions } = page;
            if (
                entityDefinitions.find(
                    ({ key: childKey, path: childPath }) =>
                        childKey.toLowerCase() === keyOrPath.toLowerCase() || childPath?.toLowerCase() === keyOrPath.toLowerCase()
                )
            ) {
                return true;
            }
        }
        return false;
    });
};

export const findDisplayablePage = (key?: string): DisplayableRouteDefinition | undefined => {
    const page = findPage(key);
    return page && !('fields' in page) ? page : undefined;
};

import languageForExtension from '../languageForExtension';

const LANGUAGE_FOR_EXTENSION = languageForExtension as Record<string, string | undefined>;

const EXTENSIONS_WITH_SYNTAX_SUPPORT = Object.keys(LANGUAGE_FOR_EXTENSION);
const EDITABLE_EXTENSIONS_RWV1 = ['txt', 'csv', 'json', 'js', 'xml', 'sql', 'md', 'py', 'ipynb', 'shp', 'prj'];

export const extensionForFileName = (fileName: string): string => fileName.substr(fileName.lastIndexOf('.') + 1).toLowerCase();

// `v1 = false` means editable here in the admin (V2).
// `v1 = true` means editable in the RW V1 UI.
export const isFileEditable = (fileName: string, v1 = false): boolean =>
    (v1 ? EDITABLE_EXTENSIONS_RWV1 : EXTENSIONS_WITH_SYNTAX_SUPPORT).includes(extensionForFileName(fileName));

export const languageForFilename = (filename: string): string => LANGUAGE_FOR_EXTENSION[extensionForFileName(filename)] || 'txt';

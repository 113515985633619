import { entityByKey } from 'entities/Entities';
import { Entity, EntityDefinition, getEntityDefinitionLabel } from 'entities/EntityDefinition';
import React, { ReactNode } from 'react';
import { asArray } from 'util/array';
import { capitalize, truncate } from 'util/string';

interface EntityLabelOptions {
    maxLength?: number;
    insideLink?: boolean;
}
export const getEntityLabel = <EntityType extends Entity>(
    entityDefinition: EntityDefinition<EntityType>,
    entity: EntityType,
    options: EntityLabelOptions = {}
): ReactNode => {
    const { maxLength, insideLink } = options;
    const { labelFields = 'name', Label } = entityDefinition;
    if (Label) {
        return <Label entityByKey={entityByKey} entityDefinition={entityDefinition} entity={entity} insideLink={insideLink} />;
    }

    const label =
        asArray(labelFields)
            .map(labelField => entity[labelField])
            .filter(segment => segment)
            .join(' ') || `${capitalize(getEntityDefinitionLabel(entityDefinition))} #${entity.id}`;
    return maxLength ? truncate(label, maxLength) : label;
};

import {
    CloseOutlined,
    FilterFilled,
    LeftOutlined,
    MenuUnfoldOutlined,
    RightOutlined,
    TableOutlined,
    UndoOutlined,
} from '@ant-design/icons';
import { Button, Empty, Row, Select, Skeleton, Space, Table, Tooltip } from 'antd';
import { FilterDropdownProps, FilterValue } from 'antd/es/table/interface';
import { ColumnGroupType, ColumnType } from 'antd/lib/table/interface';
import Highlighter from 'components/highlight/Highlighter';
import FieldTreeLeafLabel from 'components/search/FieldTreeLeafLabel';
import FilterInput from 'components/search/FilterInput';
import { SortOrder, sortOrderToTableSortOrder, tableSortOrderToSortOrder } from 'components/search/SortOrder';
import { getRelationDefinition } from 'entities/Entities';
import type { Entity, EntityDefinition, ObjectLiteral } from 'entities/EntityDefinition';
import { getEntityDefinitionLabel, getIdPath, getLabel } from 'entities/EntityDefinition';
import { Field, FieldType, formatField, ID_FIELD, isOne, RelationField } from 'entities/Field';
import type { FieldTree, JsonExpandedFieldTree, JsonFieldTree } from 'entities/FieldTree';
import {
    getDefaultCollapsedFieldPathKeys,
    getFieldOrFieldTreeKey,
    urlDecodeCollapsedFieldPathKeys,
    urlEncodeCollapsedFieldPathKeys,
    valueForFieldPath,
} from 'entities/FieldTree';
import pluralize from 'pluralize';
import React, { Key, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { CollapsedFieldPathKeys } from 'SearchParams';
import { asArray, isPrimitiveArray } from 'util/array';
import {
    comparatorLabel,
    comparatorTooltip,
    defaultComparatorForField,
    Filter,
    FilterComparator,
    filterKeysToFilter,
    filterKeysToFilters,
    Filters,
    filtersToFilterKeys,
    filterToFilterKeys,
    isComparatorValidForField,
    urlEncodeFilters,
} from 'util/filters';
import { capitalize } from 'util/string';
import { pathWithQueryParams, useQueryParams } from 'util/url';

const getFullPath = (
    path: string[],
    rowIndex: number,
    jsonArrayPathKeys: string[],
    jsonArrayIndexForPath: Record<string, Record<number, number>>
): string[] => {
    let fullPath: string[] = [];
    for (let i = 0; i < path.length; i++) {
        const pathPart = path[i];
        fullPath.push(pathPart);
        const pathSegmentKey = path.slice(0, i + 1).join(':');
        const jsonArrayIndex =
            jsonArrayPathKeys.includes(pathSegmentKey) && (jsonArrayIndexForPath[pathSegmentKey]?.[rowIndex] || '0');
        if (jsonArrayIndex) fullPath.push(`${jsonArrayIndex}`);
    }
    return fullPath;
};

interface ColumnOptions {
    filterValues: Record<string, FilterValue | null>;
    sortField: string;
    sortOrder: SortOrder;

    setFilterDropdownVisible: (v: boolean) => void;
    focusInput: (input: HTMLInputElement) => void;
    setHoveredPath: (path?: string) => void;
    jsonArrayPathKeys?: string[];
    jsonArrayIndexForPath: Record<string, Record<number, number>>;
    setJsonArrayIndexForPath: (path: string, rowIndex: number, arrayIndex: number) => void;
    hoveredPathKey?: string;
    onClick: (entityDefinition: EntityDefinition<any>, entityId: number) => void;
    setPathKeyCollapsed: (fieldPathKey: string, collapsed: boolean) => void;
    filters?: Filters;
    collapsedFieldPathKeys?: CollapsedFieldPathKeys;
    search?: string;
    parentRelationField?: RelationField;
    parentPath?: string[];
    userScoped?: boolean; // Current column belongs to a user-scoped relation or a descendant of one
    isJsonChild?: boolean; // Current column is a JSON field or descendant
    isArrayChild?: boolean; // If `true`, this column belongs to a JSON object whose parent is a JSON array.
}

// The position of a column relative to its parent (allows conditioning cell rendering on e.g. first/last child)
interface ColumnPosition {
    index: number;
    length: number;
}

interface CollapseButtonProps {
    isCollapsed: boolean;
    setPathKeyCollapsed: (fieldPathKey: string, collapsed: boolean) => void;
    pathKey: string;
}

const CollapseButton = ({ isCollapsed, setPathKeyCollapsed, pathKey }: CollapseButtonProps) => (
    <Button
        // Top-level JSON fields collapsed by default. Nested fields expanded by default.
        // In default state, show icon as inactive (gray), but active (highlighted) if hovered.
        // Otherwise, the `type="link"` below will make it show as highlighted with no class name.
        className={isCollapsed === pathKey.includes(':') ? '' : 'hover-active'}
        icon={
            isCollapsed ? (
                <Tooltip title="Expand JSON field into separate columns">
                    <TableOutlined />
                </Tooltip>
            ) : (
                <Tooltip title="Collapse JSON columns into a single column">
                    <MenuUnfoldOutlined />
                </Tooltip>
            )
        }
        type="link"
        style={{ position: 'fixed', top: -4, right: 0, width: 16, height: 16 }}
        onClick={() => {
            setPathKeyCollapsed(pathKey, !isCollapsed);
        }}
    />
);

function column<EntityType extends Entity, ChildEntityType extends Entity>(
    rootEntityDefinition: EntityDefinition<EntityType>,
    childEntityDefinition: EntityDefinition<ChildEntityType>,
    field: Field | FieldTree | JsonFieldTree,
    position: ColumnPosition,
    options: ColumnOptions
): ColumnGroupType<ObjectLiteral> | ColumnType<ObjectLiteral> {
    const { parentPath = [], hoveredPathKey, jsonArrayPathKeys = [], collapsedFieldPathKeys = [], userScoped = false } = options;
    const parentPathKey = parentPath.join(':');
    const isTree = 'fields' in field;
    const isJsonTree = isTree && 'key' in field;
    const relationField = isTree && !isJsonTree && field.parentField;
    if (relationField && !isOne(relationField)) throw Error('FieldTree with child fields but no one-relation');

    const path = [...parentPath, isJsonTree ? field.key : getFieldOrFieldTreeKey(relationField || field)].filter(
        p => p
    ) as string[];
    const pathKey = path.join(':');
    const isCollapsed = collapsedFieldPathKeys.includes(pathKey);
    const headerClassName =
        hoveredPathKey && (pathKey.includes(hoveredPathKey) || rootEntityDefinition.key === hoveredPathKey) ? 'highlight' : '';

    if (isJsonTree && !isCollapsed) {
        const { setPathKeyCollapsed } = options;

        return {
            title: (
                <>
                    <CollapseButton isCollapsed={false} setPathKeyCollapsed={setPathKeyCollapsed} pathKey={pathKey} />
                    {capitalize(getLabel(field))}
                </>
            ),
            onHeaderCell: () => ({ className: headerClassName }),
            children: field.fields.map((childField, index) =>
                column(
                    rootEntityDefinition,
                    childEntityDefinition,
                    childField,
                    { index, length: field.fields.length },
                    {
                        ...options,
                        parentPath: path,
                        isJsonChild: true,
                        isArrayChild: field.isArray,
                        jsonArrayPathKeys: [...jsonArrayPathKeys, ...(field.isArray ? [pathKey] : [])],
                    }
                )
            ),
        };
    }

    if (isTree && !isJsonTree) {
        if (!relationField) throw Error('FieldTree with child fields but no relation');

        return {
            title: <FieldTreeLeafLabel parentField={relationField} />,
            onHeaderCell: () => ({ className: `relation ${headerClassName}` }),
            children: field.fields.map((childField, index) =>
                column(
                    rootEntityDefinition,
                    getRelationDefinition(relationField),
                    childField,
                    { index, length: field.fields.length },
                    {
                        ...options,
                        parentRelationField: relationField,
                        parentPath: path,
                        userScoped: userScoped || relationField?.userScoped,
                    }
                )
            ),
        };
    }

    if (isJsonTree) {
        return column(
            rootEntityDefinition,
            childEntityDefinition,
            { key: field.key, label: getLabel(field), type: FieldType.json },
            position,
            options
        );
    }

    const {
        onClick,
        filters,
        parentRelationField,
        filterValues,
        sortField,
        sortOrder,
        search,
        setHoveredPath,
        focusInput,
        setFilterDropdownVisible,
        setJsonArrayIndexForPath,
        isJsonChild,
        isArrayChild,
        setPathKeyCollapsed,
    } = options;
    const fieldKey = field.key;
    const parentRelationKey = parentPath?.[parentPath?.length - 1];
    const filter = filters?.[pathKey];
    const isJson = field.type === FieldType.json;
    if (parentRelationField && !isOne(parentRelationField)) throw Error('Parent relation field with no relation');
    const isSearchable = field && field.searchable !== false && !userScoped && !parentRelationField?.crossDb && !isArrayChild;
    const filteredValue = filterValues[pathKey] || [];
    const nestLevel = parentPath.length;

    return {
        key: pathKey,
        dataIndex: path,
        title: (
            <>
                <FieldTreeLeafLabel field={field} />
                {isJson && (
                    <CollapseButton isCollapsed={isCollapsed} setPathKeyCollapsed={setPathKeyCollapsed} pathKey={pathKey} />
                )}
            </>
        ),
        sorter: !parentRelationField?.crossDb && !userScoped && !isJsonChild && !isJson, // TypeORM doesn't support ordering by JSON fields :(
        sortOrder: sortOrderToTableSortOrder(sortField === pathKey ? sortOrder : undefined),
        onHeaderCell: () => ({ className: headerClassName }),
        onCell: entity => {
            const leafRecordId = !isJsonChild && valueForFieldPath(entity, parentPath)?.id;

            return {
                className: `${nestLevel % 2 === 0 ? 'even' : 'odd'}-level${
                    parentRelationKey ? ' relation' : ''
                } ${headerClassName}`,
                onMouseOver: () => {
                    if (leafRecordId) {
                        if (parentPathKey !== hoveredPathKey) setHoveredPath(parentPathKey);
                    } else if (rootEntityDefinition.key !== hoveredPathKey) {
                        setHoveredPath(rootEntityDefinition.key);
                    }
                },
                onMouseOut: () => {
                    setHoveredPath(undefined);
                },
                onClick: () => {
                    const childHovered = leafRecordId && parentPathKey && parentPathKey === hoveredPathKey;
                    onClick(childHovered ? childEntityDefinition : rootEntityDefinition, childHovered ? leafRecordId : entity.id);
                },
            };
        },
        render: (_: any, entity, rowIndex) => {
            // The cell value provided in the first argument is ignored, since it will erroneously be `undefined` when any ancestor is an array.
            // The `dataIndex`, which reflects the visual hierarchy, does not include array indices.
            const { jsonArrayPathKeys = [], jsonArrayIndexForPath } = options;
            const fullPath = getFullPath(path, rowIndex, jsonArrayPathKeys, jsonArrayIndexForPath);
            const value = valueForFieldPath(entity, fullPath);
            const arrayFieldPath = isArrayChild && fullPath.slice(0, fullPath.length - 2);
            const arrayLength = arrayFieldPath && valueForFieldPath(entity, arrayFieldPath)?.length;
            const arrayIndex = arrayLength && Number(fullPath[fullPath.length - 2]);
            const arrayPresent = arrayFieldPath && typeof arrayIndex === 'number';

            // Cases to highlight:
            //   * exact match on ID, or
            //   * partial match on non-filtered stringy column, or
            //   * partial match on filtered 'like' comparator column
            const highlight =
                field &&
                isSearchable &&
                ((fieldKey === ID_FIELD.key && `${value}` === search && search) ||
                    (fieldKey !== ID_FIELD.key && !filter && typeof value === 'string' && search) ||
                    (filter?.comparator === FilterComparator.like && filter.search));
            const type = field.type === FieldType.json && isPrimitiveArray(value) ? FieldType.jsonPrimitiveList : field.type;

            return (
                <span
                    style={
                        // Make room for array navigation buttons
                        arrayPresent && position.index === 0
                            ? { paddingLeft: 12, boxDecorationBreak: 'clone', WebkitBoxDecorationBreak: 'clone' }
                            : arrayPresent && position.index === position.length - 1
                            ? { paddingRight: 12, boxDecorationBreak: 'clone', WebkitBoxDecorationBreak: 'clone' }
                            : {}
                    }
                >
                    {highlight ? (
                        <Highlighter
                            searchWord={highlight}
                            textToHighlight={`${formatField(value, { ...field, type }, { stringOnly: true })}`}
                        />
                    ) : (
                        formatField(value, { ...field, type }, { entityDefinition: childEntityDefinition })
                    )}
                    {arrayPresent && position.index === 0 && (
                        <Button
                            style={{ position: 'absolute', left: 0, top: 0, height: '100%', width: 16 }}
                            icon={<LeftOutlined />}
                            disabled={arrayIndex === 0}
                            onClick={e => {
                                e.stopPropagation();
                                setJsonArrayIndexForPath(arrayFieldPath.join(':'), rowIndex, arrayIndex - 1);
                            }}
                        />
                    )}
                    {arrayPresent && position.index === position.length - 1 && (
                        <Button
                            style={{ position: 'absolute', right: 0, top: 0, height: '100%', width: 16 }}
                            icon={<RightOutlined />}
                            disabled={arrayIndex >= arrayLength - 1}
                            onClick={e => {
                                e.stopPropagation();
                                setJsonArrayIndexForPath(arrayFieldPath.join(':'), rowIndex, arrayIndex + 1);
                            }}
                        />
                    )}
                </span>
            );
        },
        filteredValue,
        filterDropdown: isSearchable
            ? ({ setSelectedKeys, selectedKeys, confirm, clearFilters }: FilterDropdownProps) => {
                  const isDate = field.type === FieldType.date;

                  const selectedFilter = filterKeysToFilter(selectedKeys) || {};
                  // Set filter defaults
                  if (selectedFilter.present === undefined) {
                      if (isDate && !selectedFilter.dateMode) selectedFilter.dateMode = 'date';
                      if (!selectedFilter.comparator) {
                          const defaultComparator = defaultComparatorForField(field.type);
                          if (defaultComparator) selectedFilter.comparator = defaultComparator;
                      }
                      if (selectedFilter.range && selectedFilter.range[1] === undefined) {
                          selectedFilter.range[1] = selectedFilter.range[0];
                      }
                  }
                  if (Object.keys(selectedFilter).length === 0) {
                      selectedFilter.present = true;
                  }

                  const { comparator, search, searches, range, dateMode, present } = selectedFilter;
                  const isRange = comparator === FilterComparator.range;
                  const hasRange = range?.[0] !== undefined;
                  const searchPresent = !!(isRange
                      ? hasRange
                      : field.type === FieldType.enumList || field.type === FieldType.jsonPrimitiveList
                      ? searches || search
                      : search);
                  const filterChanged = filter
                      ? JSON.stringify(selectedFilter) !== JSON.stringify(filter)
                      : present !== undefined || searchPresent;
                  const filterSubmitEnabled = filterChanged && (!isRange || hasRange);

                  const setSelectedFilter = (filter: Filter): void => {
                      if (!filter.exclude) {
                          // For convenience, allow setting just the search value.
                          if (!filter.comparator) filter.comparator = comparator;
                          if (!filter.dateMode && isDate) filter.dateMode = dateMode;

                          if (filter.comparator === FilterComparator.range && filter.search !== undefined && !filter.range) {
                              // Switching from a search to a range query type.
                              filter.range = [filter.search, filter.search];
                              filter.search = undefined;
                          } else if (
                              filter.comparator !== FilterComparator.range &&
                              filter.range !== undefined &&
                              !filter.search
                          ) {
                              // Switching from a range to a search query type.
                              filter.search = filter.range[0];
                              filter.range = undefined;
                          }
                      }

                      const selectedKeys = filterToFilterKeys(filter);
                      if (selectedKeys) setSelectedKeys(selectedKeys);
                  };

                  return (
                      // See `filterKeysToFilters` note above for more info on `selectedKeys` conventions.
                      <div style={{ padding: 8 }}>
                          <Row>
                              <Space style={{ marginBottom: 8 }}>
                                  <Select
                                      value={present === true ? 'present' : present === false ? 'npresent' : comparator}
                                      onChange={comparator => {
                                          setSelectedFilter(
                                              comparator === 'present'
                                                  ? { present: true }
                                                  : comparator === 'npresent'
                                                  ? { present: false }
                                                  : { search, searches, range, comparator: comparator as FilterComparator }
                                          );
                                      }}
                                  >
                                      <Select.Option key="present" value="present">
                                          <Tooltip title="Present">?</Tooltip>
                                      </Select.Option>
                                      <Select.Option key="npresent" value="npresent">
                                          <Tooltip title="Not present">—</Tooltip>
                                      </Select.Option>
                                      {Object.values(FilterComparator)
                                          .filter(comparator => isComparatorValidForField(comparator, field.type))
                                          .map(comparator => (
                                              <Select.Option key={comparator} value={comparator}>
                                                  <Tooltip title={comparatorTooltip(comparator)}>
                                                      {comparatorLabel(comparator)}
                                                  </Tooltip>
                                              </Select.Option>
                                          ))}
                                  </Select>
                                  <FilterInput
                                      filter={selectedFilter}
                                      field={field}
                                      confirm={confirm}
                                      onChange={setSelectedFilter}
                                      focusInput={focusInput}
                                  />
                              </Space>
                          </Row>
                          <Space>
                              <Button
                                  type="primary"
                                  onClick={() => {
                                      // `setSelectedFilter` is usually a no-op, except when there was originally no filter for the field,
                                      // then filter dropdown opened and submit is clicked on a 'present' filter without changing the filter-type selection.
                                      setSelectedFilter(selectedFilter);
                                      confirm();
                                  }}
                                  icon={<FilterFilled />}
                                  size="small"
                                  disabled={!filterSubmitEnabled}
                              >
                                  Filter
                              </Button>
                              {filterChanged && (
                                  <Button
                                      onClick={() => {
                                          setSelectedKeys((filteredValue as Key[]) || []);
                                      }}
                                      icon={<UndoOutlined />}
                                      size="small"
                                  >
                                      Reset
                                  </Button>
                              )}
                              {filteredValue.length > 0 && (
                                  <Button
                                      onClick={() => {
                                          clearFilters?.();
                                          confirm();
                                      }}
                                      icon={<CloseOutlined />}
                                      danger
                                      size="small"
                                  >
                                      Clear
                                  </Button>
                              )}
                          </Space>
                      </div>
                  );
              }
            : null,
        filterIcon: (filtered: boolean) => (
            // Make room to the right if there's a json collapse icon (which is absolutely positioned in the top-right of the header cell)
            <FilterFilled style={{ color: filtered ? '#1890ff' : undefined, marginRight: isJson ? 14 : 0 }} />
        ),
        onFilterDropdownVisibleChange: (visible: boolean) => {
            setFilterDropdownVisible(visible);
        },
    };
}

// TODO need some way overriding a relation entity label (e.g. oneToMany relations with custom `label`)
//  (fix bug where 'actions' labels are shown as 'events' in the table)
interface Props<EntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    entities: EntityType[];
    fieldTree: JsonExpandedFieldTree;
    sortField: string;
    sortOrder: SortOrder;
    fetching: boolean;
    search?: string;
    filters?: Filters;
    expanded?: boolean;
}

let searchInput: HTMLInputElement | null = null; // Global var to allow focusing on the (single visible) search input without re-rendering

export default function EntitiesTable<EntityType extends Entity>({
    entityDefinition,
    entities,
    fieldTree,
    fetching,
    sortField,
    sortOrder,
    search,
    filters,
    expanded = false,
}: Props<EntityType>) {
    const { defaultSortField = ID_FIELD.key, defaultSortOrder = SortOrder.asc } = entityDefinition;
    const history = useHistory();
    const [filterDropdownVisible, setFilterDropdownVisible] = useState(false);
    const [hoveredPath, setHoveredPath] = useState<string | undefined>();
    const [jsonArrayIndexForPath, setJsonArrayIndexForPath] = useState<Record<string, Record<number, number>>>({});
    const defaultCollapsedFieldPaths = getDefaultCollapsedFieldPathKeys(fieldTree);
    const [collapsedFieldPathKeys, setCollapsedFieldPathKeys] = useState<CollapsedFieldPathKeys>(
        urlDecodeCollapsedFieldPathKeys(useQueryParams()['collapse']) || defaultCollapsedFieldPaths
    );
    // Avoid setting global state if the filter dropdown is visible,
    // to avoid discarding the temporary column filter state when re-rendering all columns.
    const setHoveredPathChecked = (hoveredPath?: string) => !filterDropdownVisible && setHoveredPath(hoveredPath);
    const filterValues = filtersToFilterKeys(filters);

    const columns =
        entities.length === 0
            ? []
            : fieldTree.fields.map((field, index) =>
                  column(
                      entityDefinition,
                      entityDefinition,
                      field,
                      { index, length: fieldTree.fields.length },
                      {
                          filters,
                          collapsedFieldPathKeys,
                          search,
                          filterValues,
                          sortField,
                          sortOrder,
                          onClick: (entityDefinition: EntityDefinition<any>, entityId: number) => {
                              history.push(getIdPath(entityDefinition, entityId));
                          },
                          setPathKeyCollapsed: (fieldPathKey: string, collapsed: boolean) => {
                              const newCollapsedFieldPaths = collapsed
                                  ? [...collapsedFieldPathKeys, fieldPathKey]
                                  : collapsedFieldPathKeys.filter(fp => fp !== fieldPathKey);
                              const final =
                                  JSON.stringify(newCollapsedFieldPaths) === JSON.stringify(defaultCollapsedFieldPaths)
                                      ? undefined
                                      : newCollapsedFieldPaths;
                              // Using `window.history` to avoid a full page refresh & re-query.
                              window.history.pushState(
                                  null,
                                  '',
                                  pathWithQueryParams({ collapse: urlEncodeCollapsedFieldPathKeys(final) })
                              );
                              setCollapsedFieldPathKeys(final || defaultCollapsedFieldPaths);
                          },
                          setFilterDropdownVisible: (visible: boolean) => {
                              if (visible) setTimeout(() => searchInput?.select(), 100);
                              setFilterDropdownVisible(visible);
                          },
                          focusInput: (input: HTMLInputElement) => {
                              searchInput = input;
                          },
                          hoveredPathKey: hoveredPath,
                          setHoveredPath: setHoveredPathChecked,
                          jsonArrayIndexForPath,
                          setJsonArrayIndexForPath: (path: string, rowIndex: number, arrayIndex: number) => {
                              setJsonArrayIndexForPath({
                                  ...jsonArrayIndexForPath,
                                  [path]: { ...jsonArrayIndexForPath[path], [rowIndex]: arrayIndex },
                              });
                          },
                      }
                  )
              );

    return (
        <Table
            className="entities"
            size="small"
            locale={{
                emptyText: fetching ? (
                    <Skeleton active />
                ) : (
                    <Empty
                        image={Empty.PRESENTED_IMAGE_SIMPLE}
                        description={`No matching ${pluralize(getEntityDefinitionLabel(entityDefinition))} found`}
                    />
                ),
            }}
            bordered
            showHeader={!fetching}
            dataSource={fetching ? [] : entities}
            columns={columns}
            rowKey="id"
            pagination={false}
            rowClassName="clickable"
            // @ts-ignore same as ts-ignore below. something up with antd type inference because this is right.
            multipleFilter
            scroll={expanded ? undefined : { y: 'calc(100vh - 360px)' }}
            onChange={(pagination, filterValues, sorter) => {
                // Clicking sort cycles through 'asc', 'desc', `undefined` (in that order).
                // If there is a default sort field, the results will be sorted this way in the response, so we want the UI to reflect this.
                // Thus, we need to prevent sorting by the default field with _no order_.
                // Instead, for default fields, only allow descending/ascending.
                // Also, if the sort order/field is the default, don't clutter the URL with it.
                // @ts-ignore not sure why these fields aren't being found on the type...
                const { field: fieldPath, order: tableOrder } = sorter;
                const fieldPathKey = asArray(fieldPath).join(':');
                const order = tableSortOrderToSortOrder(
                    tableOrder || (fieldPathKey === defaultSortField ? sortOrderToTableSortOrder(SortOrder.asc) : null)
                );
                const sortField = order && fieldPathKey !== defaultSortField ? fieldPathKey : undefined;
                const filters = filterKeysToFilters(filterValues);
                const sortOrder = sortField ? order : order !== defaultSortOrder ? order : undefined;
                const path = pathWithQueryParams({ sort: sortField, sortOrder, f: urlEncodeFilters(filters) });
                history.push(path);
            }}
        />
    );
}

import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';

export interface ContactTypeEntity extends DeletableEntityBase {
    name: string;
    description: string;
    label: string;
}

const shorthand: ShorthandEntityDefinition<ContactTypeEntity> = {
    key: rw.user.ContactType,
    creatable: true,
    softDeletable: true,
    mainNav: true,
    defaultSortField: 'name',
    fields: [{ key: 'name', editable: true, required: true }],
};

export default shorthand;

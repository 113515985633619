import { Table } from 'antd';
import { ColumnsType } from 'antd/es/table';
import deepmerge from 'deepmerge';
import { findEntityDefinition } from 'entities/Entities';
import { Entity, EntityDefinition, findField, findOneRelation } from 'entities/EntityDefinition';
import { Field, isOne, OneField } from 'entities/Field';
import React from 'react';
import { capitalize } from 'util/string';

/**
 * Simple AF, non-interactive table.
 * This component isn't used heavily in the app, but it's probably the single best place to start in understanding how everything fits together in this app.
 * This is a minimal version of the more fully-featured and interactive `EntitiesTable` component in this directory.
 */

const getColumns = <EntityType extends Entity>(
    entityDefinition: EntityDefinition<EntityType>,
    entity: EntityType
): ColumnsType<EntityType> =>
    Object.keys(entity).map(key => {
        const field = (findField(entityDefinition, key) || findOneRelation(entityDefinition, key)) as Field | OneField;

        return {
            key,
            dataIndex: key,
            title: capitalize(field.label),
            children:
                isOne(field) && entity[key]
                    ? // Note: In `EntitiesTable`, which is more stringent about types, this lax `any` corresponds to the `ChildEntityType` column template type.
                      getColumns(findEntityDefinition(field.one) as EntityDefinition<any>, entity[key])
                    : undefined,
        };
    });

interface SimpleEntityTableProps<EntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    entities: EntityType[];
}
export default function SimpleEntitiesTable<EntityType extends Entity>({
    entityDefinition,
    entities,
}: SimpleEntityTableProps<EntityType>) {
    const mergedExpiredFiles = deepmerge.all(entities) as EntityType; // Merge into one mega-entity.
    const rows = entities.map(entity => ({ key: entity.id, ...entity })); // Not using `formatField` here like in `EntitiesTable`, so no fancy components in cells, just rendering the raw values.
    const columns = getColumns(entityDefinition, mergedExpiredFiles);

    return <Table dataSource={rows} columns={columns} />;
}

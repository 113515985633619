import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { FileEntity } from 'entities/rw/file/File';

export interface NotebookHtmlCacheEntity extends DeletableEntityBase {
    uuid: string;
    lastFileUuid: string;
    file: FileEntity;
}

const shorthand: ShorthandEntityDefinition<NotebookHtmlCacheEntity> = {
    key: rw.notebook.HtmlCache,
    label: 'Notebook HTML Cache',
    shortLabel: 'HTML Cache',
    softDeletable: true,
    fields: [{ key: 'uuid', label: 'UUID' }, { key: 'lastFileUuid', label: 'Last file UUID' }, { one: rw.file.File }],
};

export default shorthand;

import type { LegacyButtonType } from 'antd/es/button/button';
import type { EntityFormProps } from 'components/form/EntityFormProps';
import { SubmitOptions } from 'components/form/SubmitButton';
import { SortOrder } from 'components/search/SortOrder';
import type { EntityFields, Field, FieldType, OneField, RelationField, ShorthandField } from 'entities/Field';
import { isMany, isOne, isRelation, ManyField } from 'entities/Field';
import type { EventTypeType } from 'entities/rw/event/Event';
import type { DocumentNode } from 'graphql';
import type { DisplayableRouteDefinition, Labeled, Page, RouteDefinition } from 'Pages';
import pluralize from 'pluralize';
import type { ReactElement, ReactNode } from 'react';
import { asArray } from 'util/array';
import { camelOrSnakeCaseToSentence } from 'util/string';

export interface ObjectLiteral {
    [key: string]: any;
}

export interface Entity extends ObjectLiteral {
    id: number;
}

export interface EntityBase extends Entity {
    created?: string;
}

export interface DeletableEntityBase extends EntityBase {
    deleted?: string;
}

export interface LabelEntityBase extends DeletableEntityBase {
    name: string;
}

export interface EmbargoEntityBase extends EntityBase {
    embargoDate: string;
}

export interface EntitiesConnection<EntityType extends Entity> {
    nodes: EntityType[];
    totalCount: number;
}

export interface Link {
    label: string;
    href: string;
}

export interface IndexTabProps {
    entityDefinition: EntityDefinition<any>;
}

export interface IndexTab extends Labeled {
    Component: ({ entityDefinition }: IndexTabProps) => ReactElement;
}

export interface DetailTabProps<EntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    entity: EntityType;
}

export interface DetailTab<EntityType extends Entity> extends Labeled {
    Component: ({ entityDefinition, entity }: DetailTabProps<EntityType>) => ReactElement;
}

export interface SubmitModalProps {
    title: ReactNode;
    icon?: ReactNode;
    contents?: ReactNode;
    okText?: string;
    okType?: LegacyButtonType;
}

export type EntityDefinitionMap = Record<string, EntityDefinition<any>>;

export interface EntityLabelProps<EntityType extends Entity> {
    entityByKey: EntityDefinitionMap;
    entityDefinition: EntityDefinition<EntityType>;
    entity: EntityType;
    insideLink?: boolean;
}
export interface EditProps<EntityType extends Entity> {
    EntityForm: (props: EntityFormProps<EntityType>) => ReactElement;
    entityByKey: EntityDefinitionMap;
    entityDefinition: EntityDefinition<EntityType>;
    entity: EntityType;
}

export interface EntityDefinition<EntityType extends Entity> extends RouteDefinition {
    fields: EntityFields;
    labelFields?: string | string[]; // Automatically create a label from the provided field(s). Most entities only use this for labels.
    Label?: (props: EntityLabelProps<EntityType>) => ReactElement; // Custom label component
    creatable?: boolean; // should be true if and only if the corresponding GraphQL resolver has a create{Entity} endpoint.
    softDeletable?: boolean;
    deletable?: boolean; // As in "irreversibly delete forever"
    immutable?: boolean; // Immutable entities cannot be created, deleted, soft-deleted, or updated (no CRUD ops).
    defaultSortField?: string;
    defaultSortOrder?: SortOrder;
    searchable?: boolean; // show search input field in the search tab? Defaults to `true`. (If false, the paginated table is still shown.)
    searchKey?: string; // `pluralize` is used by default. This is available for entities like `metadata` (search endpoint is `findMetadatas`)
    hideSearchTab?: boolean; // hide the search (table) tab for this entity entirely?
    Edit?: (props: EditProps<EntityType>) => ReactElement;
    indexTabs?: IndexTab[]; // any tabs in addition to the default search & create tabs on entity index page
    indexActions?: SubmitOptions<EntityType>[];
    createDetailTabs?: (entity: EntityType) => DetailTab<EntityType>[]; // any tabs in addition to the default edit & relation tabs on entity detail page
    createExternalFieldLink?: (entity: EntityType, fieldKey: string) => string | undefined;
    createExternalLink?: (entity: EntityType) => string | undefined;
    createExternalLinkIcon?: (entity: EntityType) => ReactElement;
    createFieldExtra?: (entity: EntityType, fieldKey: string) => string | boolean | undefined;
    createLinks?: (entity: EntityType) => Link[] | false | undefined;
    createUpdateConfirmationModal?: (
        entityByKey: EntityDefinitionMap,
        entity: EntityType,
        eventType?: EventTypeType
    ) => SubmitModalProps; // If provided, this modal will be used as a confirmation modal for every create/update of this entity.
    queries?: Record<string, DocumentNode>;
    mutations?: Record<string, DocumentNode>;
}

export interface ShorthandEntityDefinition<EntityType extends Entity> extends Omit<EntityDefinition<EntityType>, 'fields'> {
    fields: ShorthandField[];
    idFieldType?: FieldType.number | FieldType.string; // For field hydration. Defaults to `number` (`ID_FIELD.type`)
    hasCreatedField?: boolean; // Should hydration add a 'created' field? Defaults to `true`.
}

export const getNonRelationFields = (entityDefinition: EntityDefinition<any>): Field[] =>
    entityDefinition.fields.filter(f => !isRelation(f)) as Field[];
export const getRelationFields = (entityDefinition: EntityDefinition<any>): RelationField[] =>
    entityDefinition.fields.filter(isRelation) as RelationField[];
export const getOneRelationFields = (entityDefinition: EntityDefinition<any>): OneField[] =>
    entityDefinition.fields.filter(isOne) as OneField[];
export const getManyRelationFields = (entityDefinition: EntityDefinition<any>): ManyField[] =>
    entityDefinition.fields.filter(isMany) as ManyField[];

export const findField = (entityDefinition: EntityDefinition<any>, fieldKey?: string): Field | undefined =>
    getNonRelationFields(entityDefinition).find(({ key }) => key === fieldKey);
export const findOneRelation = (entityDefinition: EntityDefinition<any>, fieldKey?: string): OneField | undefined =>
    getOneRelationFields(entityDefinition).find(({ key }) => key === fieldKey);
export const findRelation = (entityDefinition: EntityDefinition<any>, fieldKey?: string): RelationField | undefined =>
    getRelationFields(entityDefinition).find(({ key }) => key === fieldKey);

export const getSearchKey = (entityDefinition: EntityDefinition<any>) =>
    entityDefinition.searchKey || pluralize(entityDefinition.key);

export const isEditable = ({ fields }: EntityDefinition<any>): boolean => fields.some(({ editable }) => editable);

export const getIdPath = (
    entityDefinitionOrPath: DisplayableRouteDefinition | EntityDefinition<any> | string,
    id: number | string
): string => `${getIndexPath(entityDefinitionOrPath)}/id/${id}`;

export const getIndexPath = (entityDefinitionOrPath: DisplayableRouteDefinition | EntityDefinition<any> | string): string =>
    `/${
        typeof entityDefinitionOrPath === 'string'
            ? entityDefinitionOrPath
            : entityDefinitionOrPath.path || entityDefinitionOrPath.key
    }`;

export const isLabelFieldKey = (entityDefinition: EntityDefinition<any>, labelFieldKey?: string): boolean =>
    !!labelFieldKey && asArray(entityDefinition.labelFields || 'name').includes(labelFieldKey);

// TODO replace usages of this method for manual field creation with `hydrateField` calls
export const getLabel = ({ key, label }: Labeled): string => label || camelOrSnakeCaseToSentence(key);

// TODO #shorthand
export const getEntityDefinitionLabel = ({ key, label, shortLabel }: EntityDefinition<any>, shorten = false): string =>
    (shorten && shortLabel) || getLabel({ key, label });
export const getPageOrEntityDefinitionLabel = (pageOrEntityDefinition: Page | EntityDefinition<any>) =>
    'fields' in pageOrEntityDefinition ? getEntityDefinitionLabel(pageOrEntityDefinition) : getLabel(pageOrEntityDefinition);

import type { EntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { FileEntity } from 'entities/rw/file/File';

export interface FileRelationEntity extends EntityBase {
    type: string;
    objectFile: FileEntity;
    subjectFile: FileEntity;
}

const shorthand: ShorthandEntityDefinition<FileRelationEntity> = {
    key: rw.file.FileRelation,
    fields: [{ key: 'type' }, { key: 'objectFile', one: rw.file.File }, { key: 'subjectFile', one: rw.file.File }],
};

export default shorthand;

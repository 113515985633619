const colors = {
    success: '#52c41a',
    successLight: '#95de64',
    warning: '#fa8c16',
    danger: '#ff4d4f',
    created: '#f6ffed',
    deleted: '#fff1f0',
    updated: '#e6f7ff',
};

export default colors;

import { DatePicker, Input, InputNumber, Select, Space, Switch } from 'antd';
import { FilterConfirmProps } from 'antd/es/table/interface';
import { Field, FieldType } from 'entities/Field';
import moment from 'moment';
import React, { ChangeEvent } from 'react';
import { asArray } from 'util/array';
import { Filter, FilterComparator, FilterDateMode } from 'util/filters';

const getValue = (e: string | number | ChangeEvent<HTMLInputElement>): string =>
    typeof e === 'string' || typeof e === 'number' ? `${e}` : e?.target?.value;

interface Props {
    filter: Filter;
    field: Field;
    onChange: (filter: Filter) => void;
    confirm: (param?: FilterConfirmProps) => void;
    focusInput: (input: HTMLInputElement) => void;
}

export default function FilterInput({ filter, field, onChange, confirm, focusInput }: Props) {
    const { comparator, search, searches, range, dateMode, present } = filter;
    const { type } = field;

    const isRange = comparator === FilterComparator.range;
    const rangeValues = range ? [Number(range[0]), Number(range[1])] : [undefined, undefined];
    const rangePresent = range?.[0] !== undefined;

    if (present !== undefined) {
        return <span style={{ fontStyle: 'italic', padding: '0 1em' }}>{present ? 'Present' : 'Not present'}</span>;
    }

    if (type === FieldType.boolean) {
        return (
            <Switch
                checked={search === 'true'}
                onChange={value => {
                    onChange({ search: value ? 'true' : undefined });
                }}
            />
        );
    }

    if (type === FieldType.date) {
        const showTime = dateMode === 'time';
        const pickerProps = { picker: showTime ? 'date' : (dateMode as FilterDateMode), showTime };

        return (
            <Space>
                <Select
                    value={dateMode}
                    onChange={newDateMode => {
                        onChange({ search, range, dateMode: newDateMode as FilterDateMode });
                    }}
                >
                    {['time', 'date', 'week', 'month', 'year'].map(picker => (
                        <Select.Option key={picker} value={picker}>
                            {picker}
                        </Select.Option>
                    ))}
                </Select>
                {isRange ? (
                    <DatePicker.RangePicker
                        {...pickerProps}
                        // @ts-ignore
                        value={rangeValues.map(value => (value ? moment.unix(value) : undefined))}
                        onChange={moments => {
                            if (!moments || moments.some(m => !m)) return;
                            // Parser doesn't see that two elements are actually guaranteed.
                            // @ts-ignore
                            onChange({ range: moments.map(m => `${m?.unix() || 0}`) });
                        }}
                    />
                ) : (
                    <DatePicker
                        {...pickerProps}
                        value={search ? moment.unix(Number(search)) : undefined}
                        onChange={moment => {
                            onChange({ search: moment ? `${moment.unix()}` : undefined });
                        }}
                    />
                )}
            </Space>
        );
    }

    const isList = type === FieldType.enumList || type === FieldType.jsonPrimitiveList;
    if (type === FieldType.enum || isList) {
        return (
            <Select
                style={{ width: 120 }}
                value={isList ? searches || (search ? [search] : []) : search || undefined}
                onChange={value => {
                    onChange({ searches: asArray(value) });
                }}
                mode={isList ? 'tags' : undefined}
                placeholder="Filter"
            >
                {(field.options || []).map(option => (
                    <Select.Option key={option} value={option}>
                        {option}
                    </Select.Option>
                ))}
            </Select>
        );
    }

    const isNumericType = type === FieldType.number || type === FieldType.entityId;
    const InputComponent = isNumericType ? InputNumber : Input;
    const inputStyle = isNumericType ? { width: 120 } : undefined;

    return isRange ? (
        <span style={{ display: 'flex', flexDirection: 'row' }}>
            <Space>
                <InputComponent
                    // @ts-ignore
                    ref={focusInput}
                    placeholder="Lower bound"
                    value={rangeValues[0]}
                    onChange={e => {
                        onChange({ range: [getValue(e), `${rangeValues[1] || ''}`] });
                    }}
                    onPressEnter={() => {
                        if (rangePresent) confirm();
                    }}
                    min={1}
                    max={rangeValues[1]}
                    style={inputStyle}
                />
                <InputComponent
                    placeholder="Upper bound"
                    value={rangeValues[1]}
                    onChange={e => {
                        onChange({ range: [`${rangeValues[0] || ''}`, getValue(e)] });
                    }}
                    onPressEnter={() => {
                        if (rangePresent) confirm();
                    }}
                    min={rangePresent ? rangeValues[0] : 1}
                    style={inputStyle}
                />
            </Space>
        </span>
    ) : (
        <InputComponent
            // @ts-ignore
            ref={focusInput}
            placeholder="Filter"
            value={search}
            onChange={e => {
                onChange({ search: getValue(e) });
            }}
            onPressEnter={() => confirm()}
            style={inputStyle}
            min={`${1}`}
        />
    );
}

/**
 * A `FieldTree` has 4 main representations:
 *   - A `FieldTree` object
 *   - A URL-encoded string
 *   - A flat string array to pass into `TreeSelect::value` (which requires a flat string array)
 *   - A GraphQL query `DocumentNode`
 *
 * Here is an example `FieldTree` object:
 * {
 *     fields: [
 *         { key: 'id' }, { key: 'created'}, { key: 'deleted' },
 *         {
 *             parentField: 'file',
 *             fields: [
 *                 { key: 'name' },
 *                 { parentField: { entityKey: 'folder', fields: [
 *                     { key: 'name' },
 *                     { parentField: { entityKey: 'project', fields: [
 *                         { key: 'created' },
 *                         { key: 'name' },
 *                     ] } }
 *                 ]}}
 *             ]
 *         },
 *         {
 *             parentField: 'user',
 *             fields: [{ key: 'name' }, { key: 'email' }]
 *         },
 *         {
 *              parentField: 'address',
 *              fields: [{ key: 'address1' }, { key: 'address2' }]
 *         },
 *     ]
 * }
 *
 * Here is the corresponding URL-encoded string:
 * 'id,created,deleted,file[name,folder[name,project[created,name]]],user[name,email],address[address1,address2]'
 *
 * And its `value` string array:
 * [
 *     'id', 'created', 'deleted',
 *     'file:name',
 *     'file:folder:name',
 *     'file:folder:project:created', 'file:folder:project:name',
 *     'user:name', 'user:email',
 *     'address:address1', 'address:address2',
 * ]
 */

import deepmerge from 'deepmerge';
import { getRelationDefinition } from 'entities/Entities';
import { EntityDefinition, findField, findRelation, getLabel, ObjectLiteral } from 'entities/EntityDefinition';
import { Field, FieldBase, FieldType, isOne, RelationField } from 'entities/Field';
import { isPrimitiveArray } from 'util/array';
import { isBoolean, isNumber } from 'util/string';

export interface FieldTree {
    fields: (Field | FieldTree)[];
    parentField?: RelationField;
}

export const isFieldTree = (field?: FieldBase | FieldTree): field is FieldTree => !!field && 'fields' in field;
export const getFieldOrFieldTreeKey = (field: FieldBase | FieldTree): string | undefined =>
    isFieldTree(field) ? field.parentField?.key : field.key;

// Used as a kind of virtual `FieldTree` to represent the structure of JSON-type fields.
export interface JsonFieldTree {
    key: string;
    fields: (Field | JsonFieldTree)[];
    // Does this tree represents an array of objects, instead of just a single object?
    // If `true`, then `fields` represents the union of fields across each element in the array.
    isArray?: boolean;
    searchable?: boolean;
}

// A schema computed once based on all fetched entities,
// in a handy format that allows for treating nested json objects just like nested `FieldTree`s for relations.
export interface JsonExpandedFieldTree {
    fields: (Field | JsonExpandedFieldTree | JsonFieldTree)[];
    parentField?: RelationField;
}

export const objectToJsonFieldTree = (
    key: string,
    object: ObjectLiteral | any[] | undefined,
    searchable = true
): JsonFieldTree => {
    const fieldTypeForJsonValue = (value: any): FieldType => {
        if (Array.isArray(value)) return FieldType.jsonPrimitiveList;
        if (isNumber(value)) return FieldType.number;
        if (isBoolean(value)) return FieldType.boolean;
        return FieldType.string;
    };

    if (!object) return { key, fields: [] };

    const isArray = Array.isArray(object);
    if (isArray && isPrimitiveArray(object)) throw Error('Primitive arrays should be treated as a `FieldType.jsonPrimitiveList`');

    return {
        key,
        fields: Object.entries(isArray ? deepmerge.all(object.filter(v => v)) : object)
            .sort(([k1], [k2]) => k1.localeCompare(k2))
            .map(([key, value]) =>
                // Important to remember that arrays have an 'object' type :)
                typeof value === 'object' && !isPrimitiveArray(value)
                    ? objectToJsonFieldTree(key, value, searchable)
                    : { key, label: getLabel({ key }), type: fieldTypeForJsonValue(value), searchable }
            ),
        isArray,
        searchable,
    };
};

export const createExpandedJsonFieldTree = (fieldTree: FieldTree, mergedJsonObject?: ObjectLiteral): JsonExpandedFieldTree => ({
    fields: fieldTree.fields.map(field => {
        if (isFieldTree(field)) {
            if (!field.parentField) throw Error('Nested field without a parent field'); // TODO this check happens in a few places. Should guarantee this using types.
            return createExpandedJsonFieldTree(field, mergedJsonObject?.[field.parentField.key]);
        }

        const mergedJsonValue = mergedJsonObject?.[field.key];
        // Primitive array values for fields with type `json` are rendered as a single cell value of type `jsonPrimitiveList`,
        // and thus shouldn't be treated as `JsonField`s.
        return !mergedJsonValue || isPrimitiveArray(mergedJsonValue)
            ? field
            : objectToJsonFieldTree(field.key, mergedJsonValue, field.searchable);
    }),
    parentField: fieldTree.parentField,
});

// Top-level JSON fields collapsed by default
export const getDefaultCollapsedFieldPathKeys = (fieldTree: JsonExpandedFieldTree): string[] =>
    fieldTree.fields
        .filter(field => 'fields' in field || field.type === FieldType.json)
        .map(field => (field as Field | JsonFieldTree).key);

export const urlEncodeCollapsedFieldPathKeys = (final: undefined | string[]) => (final ? `[${final.join(',')}]` : undefined);

// '[topLevel,topLevel:nested,otherTopLevel:otherNested:moreNesting]'
export const urlDecodeCollapsedFieldPathKeys = (collapsedFieldPaths?: string): string[] | undefined =>
    collapsedFieldPaths?.slice(1, collapsedFieldPaths?.length - 1)?.split(',');

export interface FieldTreeLeaf {
    field: Field;
    parentField?: RelationField;
}

export const getLeaves = (fieldTree: FieldTree, leafNodes: FieldTreeLeaf[] = []): FieldTreeLeaf[] => {
    fieldTree.fields.forEach(field => {
        if (isFieldTree(field)) {
            getLeaves(field, leafNodes);
        } else {
            leafNodes.push({ field: field, parentField: fieldTree.parentField });
        }
    });
    return leafNodes;
};

export const findFieldInTree = (fieldTree: FieldTree, path: string[]): Field | FieldTree | undefined => {
    if (path?.length === 0) return undefined;

    for (const field of fieldTree.fields) {
        if (isFieldTree(field)) {
            if (field.parentField && field.parentField.key === path[0]) {
                return path.length === 1 ? field : findFieldInTree(field, path.slice(1));
            }
        } else if (field.key === path[0] && (path.length === 1 || field.type === FieldType.json)) {
            return field; // The path might be longer if it digs into a JSON field.
        }
    }
};

export const valueForFieldPath = (object: ObjectLiteral | null, fieldPath: string[]): any | undefined => {
    if (fieldPath.length === 0 || !object) return undefined;
    if (fieldPath.length === 1) return object[fieldPath[0]];
    return valueForFieldPath(object[fieldPath[0]], fieldPath.slice(1));
};

// A simplified version of `FieldTree` that only uses string keys.
// Used as an intermediate step in transforming from a list of field path to a `FieldTree`.
export interface StringFieldTree {
    fields: (string | StringFieldTree)[];
    parentField?: string;
}

/**
 Input (relations represented as letters, leaf values as numbers, although in reality they're all just strings!):
 [a,b,d,1]
 [a,2]
 [a,b,3]
 [4]
 [5]
 [a,b,d,6]
 [a,c,e,7]
Output:
 {
   fields: [4, 5, {
     parentField: a,
     fields: [2, {
       parentField: b,
       fields: [3, { parentField: d, fields: [1, 6] }
     }, {
       parentField: c,
       fields: [{ parentField: e, fields: [7]}]
     }],
   }],
 }
 */
export const fieldPathsToStringFieldTree = (fieldPaths: string[][], parentField?: string): StringFieldTree => {
    // Group fields one-level deep.
    // Rows with a single value (a plain old non-relation field) map to themselves,
    // Rows with multiple values (a relation field at least one level deep) map the parentField key to a new
    // `fieldPaths` 2d array to recurse on during `fields` creation below.
    const fieldPathForField = fieldPaths
        .sort((a, b) => a[0].localeCompare(b[0]))
        .reduce((fieldPathForField, fp) => {
            const k = fp[0];
            if (fp.length === 1) return { ...fieldPathForField, [k]: k };
            const currentNestedFields = fieldPathForField[k];
            if (typeof currentNestedFields === 'string') {
                throw Error(`Non-relation field has same name as relation field at the same nesting? ${fieldPaths}`);
            }
            return { ...fieldPathForField, [k]: [...(currentNestedFields || []), fp.slice(1)] };
        }, {} as Record<string, string | string[][]>);
    return {
        fields: Object.entries(fieldPathForField).map(([fieldOrRelationKey, fieldOrFields]) => {
            if (typeof fieldOrFields === 'string') return fieldOrFields;
            return fieldPathsToStringFieldTree(fieldOrFields, fieldOrRelationKey);
        }),
        parentField: parentField,
    };
};

export const stringFieldTreeToFieldTree = (
    stringFieldTree: StringFieldTree,
    entityDefinition: EntityDefinition<any>,
    parentField?: RelationField
): FieldTree => ({
    fields: stringFieldTree.fields.map(field => {
        if (typeof field === 'string') {
            const f = findField(entityDefinition, field);
            if (!f) throw Error(`Could not find a field with key ${field}`);
            return f;
        }

        const { parentField: parentFieldKey } = field;
        const parentField = findRelation(entityDefinition, parentFieldKey);
        if (!parentField) throw Error(`Could not find a relation field with key ${parentFieldKey}`);

        return stringFieldTreeToFieldTree(field, getRelationDefinition(parentField), parentField);
    }),
    parentField,
});

const sortFieldTree = (fieldTree: FieldTree, entityDefinition: EntityDefinition<any>): FieldTree => ({
    fields: fieldTree.fields
        .sort((a, b) => {
            const aKey = getFieldOrFieldTreeKey(a);
            const bKey = getFieldOrFieldTreeKey(b);
            const aField = findField(entityDefinition, aKey);
            const bField = findField(entityDefinition, bKey);
            if (!aField || !bField) return 0; // shouldn't happen, but not going to freak out
            return entityDefinition.fields.indexOf(aField) - entityDefinition.fields.indexOf(bField);
        })
        .map(field => {
            if (isFieldTree(field)) {
                const { parentField } = field;
                // Only one-relations handled. TODO handle many-relations across the board in FieldTrees.
                return isOne(parentField) ? sortFieldTree(field, getRelationDefinition(parentField)) : undefined;
            }
            return field;
        }) as (Field | FieldTree)[],
    parentField: fieldTree.parentField,
});

export const fieldPathsToFieldTree = (fieldPaths: string[][], entityDefinition: EntityDefinition<any>): FieldTree => {
    const stringFieldTree = fieldPathsToStringFieldTree(fieldPaths);
    const fieldTree = stringFieldTreeToFieldTree(stringFieldTree, entityDefinition);
    return sortFieldTree(fieldTree, entityDefinition);
};

export const urlEncodeFieldTree = (fieldTree?: FieldTree): string | undefined =>
    fieldTree?.fields
        ?.map(field => {
            if (isFieldTree(field)) {
                return field.parentField ? `${field.parentField.key}[${urlEncodeFieldTree(field)}]` : undefined;
            }
            return field.key;
        })
        ?.filter(field => field)
        ?.join(',');

export const urlDecodeFieldTree = (urlEncodedFieldTree: string, entityDefinition: EntityDefinition<any>): FieldTree => {
    type NestedKeys = (string | NestedKeys)[];
    // `nestedKeys` is a potentially nested list of field/relation keys.
    // Each list element (however deeply nested) is a field-key list,
    // and is always preceded by a string element corresponding to its parent relation key.
    const nestedKeysToStringFieldTree = (a: NestedKeys, parentField?: string): StringFieldTree => ({
        fields: a
            .map((aElement, i) => {
                if (typeof aElement === 'string') return a[i + 1] && typeof a[i + 1] !== 'string' ? undefined : aElement;
                return nestedKeysToStringFieldTree(aElement, a[i - 1] as string);
            })
            .filter(f => f) as (string | StringFieldTree)[],
        parentField,
    });

    const fieldKeyChars = /([a-z0-9]+)/gi;
    const fieldKeyAndOpeningBracket = /([a-z0-9]+)\[/g;
    const nestedKeys: NestedKeys = JSON.parse(
        `[${urlEncodedFieldTree.replace(fieldKeyAndOpeningBracket, '$1,[').replace(fieldKeyChars, '"$1"')}]`
    );
    const stringFieldTree = nestedKeysToStringFieldTree(nestedKeys);
    return stringFieldTreeToFieldTree(stringFieldTree, entityDefinition);
};

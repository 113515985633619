import { SortOrder } from 'components/search/SortOrder';
import type { EntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { CREATED_FIELD, FieldType } from 'entities/Field';
import { NotificationType } from 'entities/rw/subscription/EntitySubscription';
import type { NotificationEntity } from 'entities/rw/subscription/Notification';

export interface NotificationRecordEntity extends EntityBase {
    notification: NotificationEntity;
    notificationType: NotificationType;
}

const shorthand: ShorthandEntityDefinition<NotificationRecordEntity> = {
    key: rw.subscription.NotificationRecord,
    mainNav: true,
    immutable: true,
    defaultSortField: CREATED_FIELD.key,
    defaultSortOrder: SortOrder.desc,
    fields: [
        { key: 'notificationType', type: FieldType.enum, options: Object.values(NotificationType), required: true },
        { one: rw.subscription.Notification },
    ],
};

export default shorthand;

import { BellOutlined } from '@ant-design/icons';
import { Button, Tabs, Tooltip, Typography } from 'antd';
import { useAuth } from 'AuthContext';
import ErrorComponent from 'components/error/Error';
import EntityForm from 'components/form/EntityForm';
import GraphQlExplorer from 'components/GraphQlExplorer';
import FullPageLoader from 'components/loading/FullPageLoader';
import SearchPagination from 'components/search/SearchPagination';
import UrlSegmentTabs from 'components/tabs/UrlSegmentTabs';
import ExternalLink from 'components/text/ExternalLink';
import { entityByKey, findEntityDefinition, hydrateField } from 'entities/Entities';
import type { Entity, EntityDefinition, ObjectLiteral } from 'entities/EntityDefinition';
import { getEntityDefinitionLabel, getIdPath, getLabel, getManyRelationFields } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { isOne, ManyField } from 'entities/Field';
import { unsubscribableEntityKeys } from 'entities/rw/subscription/EntitySubscription';
import { getEntityLabel } from 'EntityLabel';
import pluralize from 'pluralize';
import React, { ReactNode } from 'react';
import { useParams } from 'react-router';
import { Link } from 'react-router-dom';
import RouteParams from 'types/RouteParams';
import { useQuery } from 'urql';
import { defaultFieldTree, detailFilter, find } from 'util/graphql';
import { capitalize, isNumber, sentenceToSnakeCase } from 'util/string';

const { TabPane } = Tabs;
const { Title } = Typography;

interface OneToManyProps<EntityType extends Entity> {
    oneEntityDefinition: EntityDefinition<EntityType>;
    manyEntityDefinition: EntityDefinition<EntityType>;
    entity: EntityType;
    manyField: ManyField;
}

const OneToManyAdd = <EntityType extends Entity>({
    oneEntityDefinition,
    manyEntityDefinition,
    entity,
}: OneToManyProps<EntityType>) => {
    const { key: oneEntityKey } = oneEntityDefinition;
    // Handle case where the one-side of the relationship has a different key than the entity-key (specified by the `key` prop on one-relations).
    // E.g. an EntitySubscription can be added to a User, but the field key for the one-relation on EntitySubscription is 'subscriber'.
    const field1 = manyEntityDefinition.fields.find(field => isOne(field) && field.one === oneEntityDefinition.key);
    const oneFieldKey = (field1 && field1.key) || oneEntityKey;
    const initialValues: ObjectLiteral = { [oneFieldKey]: entity };
    if (oneEntityKey === manyEntityDefinition.key && oneEntityKey === rw.file.Folder) {
        // Special-case: Adding child folder
        initialValues[rw.project.Project] = entity[rw.project.Project];
        initialValues.published = entity.published;
    }
    return <EntityForm entityDefinition={manyEntityDefinition} initialValues={initialValues} />;
};

interface OneToManyTabsProps<EntityType extends Entity> {
    oneEntityDefinition: EntityDefinition<EntityType>;
    manyEntityDefinition: EntityDefinition<EntityType>;
    entity: EntityType;
    manyField: ManyField;
    children: ReactNode;
}
const OneToManyTabs = ({ oneEntityDefinition, manyEntityDefinition, entity, manyField, children }: OneToManyTabsProps<any>) => {
    const { pageKey, tab, tab2 } = useParams<RouteParams>();
    const { id } = entity;
    const { searchable = true } = manyEntityDefinition;

    // If you can create the `many` side of this relation, show two tabs: 'Search' and 'Add',
    // where 'Add' has the `one` entity pre-populated.
    const searchTabKey = searchable ? 'search' : 'list';
    return (
        <UrlSegmentTabs activeTab={tab2 || searchTabKey} createPath={newTab2 => `${getIdPath(pageKey, id)}/${tab}/${newTab2}`}>
            <TabPane tab={capitalize(searchTabKey)} key={searchTabKey}>
                {children}
            </TabPane>
            <TabPane tab="Add" key="add">
                <OneToManyAdd
                    oneEntityDefinition={oneEntityDefinition}
                    manyEntityDefinition={manyEntityDefinition}
                    entity={entity}
                    manyField={manyField}
                />
            </TabPane>
        </UrlSegmentTabs>
    );
};

const OneToManyRelation = ({ oneEntityDefinition, entity, manyField }: OneToManyProps<any>) => {
    const { many } = manyField;
    const manyEntityDefinition = entityByKey[many];

    const searchTabContents = (
        <SearchPagination
            entityDefinition={manyEntityDefinition}
            scopeEntityDefinition={oneEntityDefinition}
            scopeEntity={entity}
            manyField={manyField}
        />
    );

    const { creatable, fields } = manyEntityDefinition;
    // Only show OneToManyTabs if there is a direct relation, where the One side is a field on the Many side table,
    // and will actually show up in the create form (no `excludeFromCreate` set)
    return creatable &&
        fields.find(field => isOne(field) && field.one === oneEntityDefinition.key && !field.excludeFromCreate) ? (
        <OneToManyTabs
            oneEntityDefinition={oneEntityDefinition}
            manyEntityDefinition={manyEntityDefinition}
            entity={entity}
            manyField={manyField}
        >
            {searchTabContents}
        </OneToManyTabs>
    ) : (
        searchTabContents
    );
};

export default function EntityDetail() {
    const { authenticatedUser } = useAuth();
    const params = useParams<RouteParams>();
    const { pageKey, id = '' } = params;
    const entityDefinition = findEntityDefinition(pageKey);
    const entityId = isNumber(id) ? Number(id) : decodeURIComponent(id);
    const query = entityDefinition ? find(entityDefinition, entityId, defaultFieldTree(entityDefinition, detailFilter)) : '';
    const [result] = useQuery({ query, pause: !entityDefinition });

    if (!query || !entityDefinition) return <ErrorComponent message={`No page found with key ${pageKey}`} />;

    const entityDefinitionLabel = getEntityDefinitionLabel(entityDefinition);
    if (!entityId) return <ErrorComponent message={`must specify a ${entityDefinitionLabel} id`} />;

    const { fetching, data, error } = result;
    if (fetching && !data) return <FullPageLoader />;
    if (error) return <ErrorComponent message={error.message} />;

    const { key, Edit, createDetailTabs, createExternalLink, createExternalLinkIcon, immutable } = entityDefinition;
    const manyRelationFields = [
        ...getManyRelationFields(entityDefinition),
        ...(!immutable ? [hydrateField({ many: rw.event.Event }) as ManyField] : []),
    ];

    const entity = data[key];
    if (!entity) return <ErrorComponent message={`No ${entityDefinitionLabel} found with ID ${entityId}.`} />;

    const entityLabel = getEntityLabel(entityDefinition, entity);
    const externalLink = createExternalLink?.(entity);
    const externalLinkIcon = createExternalLinkIcon?.(entity);
    const editTabKey = immutable ? 'view' : 'edit';
    const { tab = editTabKey } = params;

    return (
        <>
            <span style={{ display: 'flex', justifyContent: 'space-between' }}>
                <Title style={{ fontSize: '24px', fontWeight: 'normal' }}>
                    {externalLink ? (
                        <ExternalLink href={externalLink}>
                            {externalLinkIcon}
                            {externalLinkIcon && ' '}
                            {entityLabel}
                        </ExternalLink>
                    ) : (
                        entityLabel
                    )}
                </Title>
                {!unsubscribableEntityKeys.includes(key) && (
                    <span>
                        {authenticatedUser && (
                            <Tooltip title={`Subscribe to notifications for this ${entityDefinitionLabel}`} placement="topLeft">
                                <Link
                                    to={`/${rw.subscription.Subscription}/create?subscriber=${authenticatedUser.id}&entityKey=${key}&entityId=${id}`}
                                >
                                    <Button
                                        shape="round"
                                        icon={<BellOutlined />}
                                        style={{
                                            padding: 0,
                                            width: 28,
                                            height: 28,
                                            margin: '0 6px',
                                        }}
                                    />
                                </Link>
                            </Tooltip>
                        )}
                        <GraphQlExplorer
                            query={query}
                            childLabel={`${entityDefinitionLabel} detail page`}
                            buttonStyle={{ marginLeft: 0 }}
                            tooltipPlacement="topLeft"
                        />
                    </span>
                )}
            </span>
            <UrlSegmentTabs activeTab={tab} createPath={newTab => `${getIdPath(pageKey, id)}/${newTab}`}>
                <TabPane key={editTabKey} tab={capitalize(editTabKey)}>
                    {Edit ? (
                        <Edit
                            EntityForm={EntityForm}
                            entityByKey={entityByKey}
                            entityDefinition={entityDefinition}
                            entity={entity}
                        />
                    ) : (
                        <EntityForm entityDefinition={entityDefinition} initialEntity={entity} />
                    )}
                </TabPane>
                {createDetailTabs?.(entity)?.map(({ key, label, Component }) => (
                    <TabPane key={key} tab={capitalize(getLabel({ key, label }))}>
                        <Component entityDefinition={entityDefinition} entity={entity} />
                    </TabPane>
                ))}
                {manyRelationFields
                    .filter(field => !field.hideTab)
                    ?.map(field => {
                        const many = field.many as string;
                        const tabLabel = pluralize(field.label);
                        return (
                            <TabPane key={sentenceToSnakeCase(tabLabel)} tab={capitalize(tabLabel)}>
                                <OneToManyRelation
                                    oneEntityDefinition={entityDefinition}
                                    manyEntityDefinition={entityByKey[many]}
                                    entity={entity}
                                    manyField={field}
                                />
                            </TabPane>
                        );
                    })}
            </UrlSegmentTabs>
        </>
    );
}

import type { LabelEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { OrganizationEntity } from 'entities/rw/organization/Organization';
import type { ProjectEntity } from 'entities/rw/project/Project';

export interface ProjectOrganizationLabelEntity extends LabelEntityBase {
    project: ProjectEntity;
    organization: OrganizationEntity;
}

const shorthand: ShorthandEntityDefinition<ProjectOrganizationLabelEntity> = {
    key: rw.organization.ProjectLabel,
    shortLabel: 'Project label',
    creatable: true,
    softDeletable: true,
    fields: [
        { key: 'name', editable: true, required: true },
        { one: rw.project.Project, required: true },
        { one: rw.organization.Organization, required: true },
    ],
};

export default shorthand;

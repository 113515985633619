import { Input } from 'antd';
import { RWV1_HOST } from 'config';
import type { DeletableEntityBase, EditProps, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { getIdPath } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { FileEntity } from 'entities/rw/file/File';
import type { ProjectEntity } from 'entities/rw/project/Project';
import gql from 'graphql-tag';
import React, { useState } from 'react';
import { useHistory } from 'react-router';
import { useMutation } from 'urql';
import log from 'util/log';

export interface FolderEntity extends DeletableEntityBase {
    name: string;
    isPublished: boolean;
    metadataId: number;
    parent: FolderEntity;
    project: ProjectEntity;
}

const shorthand: ShorthandEntityDefinition<FolderEntity> = {
    key: rw.file.Folder,
    creatable: true,
    softDeletable: true,
    mainNav: true,
    defaultSortField: 'name',
    fields: [
        { key: 'isPublished', label: 'Published', editable: true, type: FieldType.boolean },
        { key: 'metadataId', label: 'Metadata ID', excludeFromTable: true },
        { key: 'name', editable: true, required: true },
        { one: rw.project.Project, editable: true, required: true, includeInSummary: true },
        { key: 'parent', one: rw.file.Folder, editable: true },
        { many: rw.file.Folder, label: 'Child folders' },
        { many: rw.file.File },
        { many: rw.notebook.Session },
        { many: rw.archive.Package },
        { many: rw.archive.File },
    ],
    createExternalLink: ({ id, project }) => `${RWV1_HOST}/project/${project.id}/folder/${id}/files`,
    createExternalFieldLink: ({ id, project }, fieldKey) =>
        fieldKey === 'metadataId' ? `${RWV1_HOST}/project/${project.id}/folders/${id}/metadata/overview` : undefined,
    Edit: ({ EntityForm, entityByKey, entityDefinition, entity }: EditProps<FolderEntity>) => {
        const fileEntityDefinition = entityByKey[rw.file.File];

        const { id } = entity;
        const history = useHistory();
        const [, createFile] = useMutation<FileEntity>(entityDefinition.mutations?.createFile || '');
        const [, uploadFile] = useMutation<FileEntity>(entityDefinition.mutations?.uploadFile || '');
        const [newFileName, setNewFileName] = useState<string | undefined>(undefined);

        return (
            <EntityForm
                entityDefinition={entityDefinition}
                initialEntity={entity}
                submits={[
                    {
                        messageKey: 'upload',
                        disabled: true, // Temp
                        help: 'Coming soon', // Temp
                        children: 'Upload file',
                        uploadProps: {
                            name: 'file',
                            customRequest: async ({ onSuccess, onError, file }: any) => {
                                const result = await uploadFile({ id, file });
                                const { data, error } = result;
                                if (error) {
                                    log.error(`Error uploading file ${file.name}: ${error.message}`);
                                    onError(error);
                                    return;
                                }
                                if (!data) {
                                    const message = `No file data returned from upload for file ${file.name}`;
                                    log.error(message);
                                    onError({ name: 'No data', message });
                                    return;
                                }

                                const { id: newFileId } = data;
                                onSuccess(null, file);
                                history.push(getIdPath(fileEntityDefinition, newFileId));
                            },
                            onChange(_: any) {
                                // const info = _;
                                // if (info.file.status !== 'uploading') {
                                //     console.log(info.file, info.fileList);
                                // }
                                // if (info.file.status === 'done') {
                                //     message.success(`${info.file.name} file uploaded`);
                                // } else if (info.file.status === 'error') {
                                //     message.error(`${info.file.name} file upload failed.`);
                                // }
                            },
                        },
                    },
                    {
                        messageKey: 'create',
                        disabled: true, // Temp
                        help: 'Coming soon', // Temp
                        children: 'Create file',
                        loadingMessage: 'Creating file...',
                        successMessage: 'File created',
                        doAction: async () => {
                            const result = await createFile({ id, name: newFileName });
                            const { data, error } = result;
                            if (error) return;
                            if (!data) return;
                            const { id: newFileId } = data;
                            history.push(`${getIdPath(fileEntityDefinition, newFileId)}/editor`);
                        },
                        modal: {
                            title: 'Create file',
                            okText: 'Create',
                            contents: (
                                <div>
                                    <Input onChange={event => setNewFileName(event.target.value)} />
                                </div>
                            ),
                        },
                    },
                ]}
            />
        );
    },
    mutations: {
        createFile: gql`
            mutation ($id: Float!, $name: String!) {
                createFile(id: $id, name: $name) {
                    id
                }
            }
        `,
        uploadFile: gql`
            mutation ($id: Float!, $file: Upload!) {
                uploadFile(id: $id, file: $file) {
                    id
                }
            }
        `,
    },
};

export default shorthand;

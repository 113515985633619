import { RWV1_HOST } from 'config';
import type { DeletableEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { oikos, rw } from 'entities/EntityKeys';
import { FieldType } from 'entities/Field';
import type { UserEntity } from 'entities/rw/user/User';

export interface CampaignEntity extends DeletableEntityBase {
    name: string;
    description?: string;
    isPublic?: boolean;
    creator: UserEntity;
}

const shorthand: ShorthandEntityDefinition<CampaignEntity> = {
    key: rw.campaign.Campaign,
    creatable: true,
    softDeletable: true,
    mainNav: true,
    defaultSortField: 'name',
    fields: [
        { key: 'isPublic', label: 'Public', editable: true, type: FieldType.boolean },
        { key: 'name', editable: true, type: FieldType.string, required: true },
        { key: 'description', editable: true, type: FieldType.longString },
        { key: 'creator', one: rw.user.User, excludeFromTable: true, excludeFromCreate: true },
        { key: 'roles', many: rw.campaign.Role, label: 'Members', expandTableFieldsFor: rw.user.User },
        { many: rw.organization.CampaignRole, label: 'Organizations', expandTableFieldsFor: rw.organization.Organization },
        { many: rw.campaign.ProjectRole, label: 'Projects', expandTableFieldsFor: rw.project.Project },
        { many: rw.campaign.ProjectLabel, label: 'Project labels', expandTableFieldsFor: rw.project.Project },
        { many: rw.archive.Package },
        { many: rw.archive.Path },
        { many: rw.portal.Campaign, label: 'Portals', expandTableFieldsFor: oikos.Portal },
        {
            key: 'embargos',
            many: rw.campaign.ProjectEmbargo,
            label: 'Project embargos',
            expandTableFieldsFor: rw.project.Project,
        },
    ],
    createExternalLink: ({ id, name }) => `${RWV1_HOST}/campaign/${id}/${name.replace(/ /g, '-')}`,
};

export default shorthand;

import { getRelationDefinition } from 'entities/Entities';
import { Entity, EntityDefinition, getLabel, getSearchKey, isLabelFieldKey } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import {
    Field,
    FieldBase,
    FieldType,
    getFieldOrOneIdKey,
    getRelationEntityKey,
    ID_FIELD,
    isFieldEditable,
    isMany,
    isOne,
    isRelation,
    ManyField,
    RelationField,
} from 'entities/Field';
import { FieldTree, isFieldTree } from 'entities/FieldTree';
import { DocumentNode, print } from 'graphql';
import gql from 'graphql-tag';
import { SearchParams } from 'SearchParams';
import { filtersToGraphQl } from 'util/filters';
import { capitalize } from 'util/string';

const graphQlTypeForField = (field: Field) => {
    switch (field.type) {
        case FieldType.boolean:
            return 'Boolean';
        case FieldType.number:
        case FieldType.durationMillis:
        case FieldType.bytes:
        case FieldType.entityId:
            return 'Float'; // 'Int'
        case FieldType.enum:
            return 'String';
        case FieldType.enumList:
            return '[String!]';
        default:
            return 'String';
    }
};

const withId = (fields: (Field | RelationField | FieldTree)[]): (Field | RelationField | FieldTree)[] =>
    !fields.some(f => !isFieldTree(f) && f.key === ID_FIELD.key) ? [ID_FIELD, ...fields] : fields;

interface FilteredFieldsOptions {
    scopeEntityKeys?: string[];
    includeIds?: boolean;
}
const filteredFields = (
    entityDefinition: EntityDefinition<any>,
    fieldFilter: (field: FieldBase) => boolean = () => true,
    { scopeEntityKeys = [], includeIds = true }: FilteredFieldsOptions = {}
): (Field | RelationField)[] =>
    entityDefinition.fields.filter(
        f =>
            (f.key !== ID_FIELD.key || includeIds) &&
            !('writeOnly' in f && f.writeOnly) &&
            (!f.userScoped || scopeEntityKeys.includes(rw.user.User)) &&
            // If scoped to an entity, exclude any matching relation fields, since they'd be redundant.
            // (E.g. when viewing an organization's projects, we don't need to query for the project's organizations,
            // since the query is already scoped to the organization.)
            (!scopeEntityKeys.length || !isOne(f) || !scopeEntityKeys.includes(f.one)) &&
            (!isMany(f) || (fieldFilter === detailFilter && f.includeInDetail)) && // many-relations only in detail queries
            fieldFilter(f)
    );

interface DefaultFieldTreeOptions {
    scopeEntityKey?: string;
    includeId?: boolean;
    includeChildIds?: boolean;
    expandFieldsForRelation?: string;
    includeEvent?: boolean;
}
export const defaultFieldTree = (
    entityDefinition: EntityDefinition<any>,
    fieldFilter: (field: FieldBase) => boolean = () => true,
    {
        scopeEntityKey,
        includeId = true,
        includeChildIds = true,
        expandFieldsForRelation,
        includeEvent = false,
    }: DefaultFieldTreeOptions = {}
): FieldTree => ({
    fields: [
        ...[
            ...filteredFields(entityDefinition, fieldFilter, {
                scopeEntityKeys: scopeEntityKey !== undefined ? [scopeEntityKey] : undefined,
                includeIds: includeId && !expandFieldsForRelation,
            }),
            ...(includeEvent ? [{ key: rw.event.Event, label: getLabel({ key: rw.event.Event }), one: rw.event.Event }] : []),
        ].map(field => {
            if (isMany(field)) {
                // Detail queries include all of each many-relation's non-relation fields by default.
                return {
                    fields: filteredFields(getRelationDefinition(field), field => !isRelation(field), {
                        scopeEntityKeys: scopeEntityKey !== undefined ? [scopeEntityKey] : undefined,
                    }) as Field[],
                    parentField: field,
                };
            }
            if (!isOne(field)) return field;

            const relationDefinition = getRelationDefinition(field);
            if (field.key === rw.event.Event) {
                // Events are an exception :/
                // We want all the nested effects of an exception to show up in the response, in case this is a preview,
                // and we're showing the event in the response inline to preview before committing.
                // So expand event relations into full-blown detail queries inside the parent detail query.
                return { parentField: field, fields: defaultFieldTree(relationDefinition, detailFilter).fields };
            }

            // For one-relations, if its key matches the optional `expandFieldsForRelation` key, all of its table filters will be included.
            // Otherwise, one-relations only include their label/summary field(s) by default.
            // Only include hidden fields under relations if this is a detail query (e.g. extra data used in title labels).
            const relationFields = filteredFields(
                relationDefinition,
                field =>
                    isOne(field) && expandFieldsForRelation === field.one
                        ? tableFilter(field)
                        : (field.includeInSummary && (fieldFilter === detailFilter || !field.hide)) ||
                          isLabelFieldKey(relationDefinition, field.key),
                {
                    scopeEntityKeys: scopeEntityKey !== undefined ? [scopeEntityKey] : undefined,
                    includeIds: includeChildIds,
                }
            )
                .map(field => {
                    // We're 3 levels deep here.
                    if (!isRelation(field)) return field;

                    // These nested inner relations can show up as children of an `expandFieldsForRelation` parent relation.
                    // At these >=3 levels, only include child label fields by default.
                    const innerRelationDefinition = getRelationDefinition(field);
                    const innerFieldTree = defaultFieldTree(
                        innerRelationDefinition,
                        field => isLabelFieldKey(innerRelationDefinition, field.key),
                        { scopeEntityKey, includeId: false }
                    );
                    innerFieldTree.parentField = field;
                    return innerFieldTree;
                })
                .filter(field => !isFieldTree(field) || field.fields.length > 0); // Don't bring in relations with no included fields.

            return { fields: relationFields, parentField: field };
        }),
    ],
});

// Creates the tree from which an entity's fields can be selected to add/remove to/from a table.
// It recursively includes all the entity's fields, and all of its one-relations' non-relation fields.
export const fullTableFieldTree = (
    entityDefinition: EntityDefinition<any>,
    scopeEntityKeys: string[] = [],
    parentField?: RelationField
): FieldTree => ({
    fields: filteredFields(entityDefinition, () => true, { scopeEntityKeys }).map(field =>
        isRelation(field)
            ? fullTableFieldTree(getRelationDefinition(field), [...scopeEntityKeys, getRelationEntityKey(field)], field)
            : field
    ),
    parentField,
});

const graphQlQueryString = (tree: FieldTree): string => {
    const { fields, parentField } = tree;
    // Must have at least an ID field (TypeGraphQL constraint)
    const innerFields = withId(fields)
        .map(fieldOrQuery => (isFieldTree(fieldOrQuery) ? graphQlQueryString(fieldOrQuery) : fieldOrQuery.key))
        .join(' ');

    return parentField ? `${parentField.key} { ${isMany(parentField) ? `nodes { ${innerFields} }` : innerFields} }` : innerFields;
};

export const graphQlQuery = (tree: FieldTree): DocumentNode => gql`{ ${graphQlQueryString(tree)} }`;

export const detailFilter = (field: FieldBase) => !field.excludeFromDetail;
export const tableFilter = (field: FieldBase) => !field.excludeFromTable;
export const summaryFilter = (field: FieldBase, entityDefinition: EntityDefinition<any>) =>
    isLabelFieldKey(entityDefinition, field.key) || !!field.includeInSummary;

export const crudResponseFieldTree = (entityDefinition: EntityDefinition<any>): FieldTree =>
    defaultFieldTree(entityDefinition, detailFilter, { includeEvent: true });
export const summaryFieldTree = (entityDefinition: EntityDefinition<any>): FieldTree =>
    defaultFieldTree(entityDefinition, field => summaryFilter(field, entityDefinition));

export const crudResponseGraphQl = (entityDefinition: EntityDefinition<any>): DocumentNode =>
    graphQlQuery(crudResponseFieldTree(entityDefinition));

export const fieldsMutation = (entityDefinition: EntityDefinition<any>, isCreate: boolean): DocumentNode => {
    const { key, fields } = entityDefinition;
    const editableFields = fields.filter(field => isFieldEditable(field, isCreate));
    if (!isCreate) editableFields.unshift(ID_FIELD);

    const mutationArguments = editableFields
        .map(field => {
            const { required, graphQlTypeName } = field;
            const type = isRelation(field) ? 'Float' : graphQlTypeName || graphQlTypeForField(field); // Float for numeric relation ID
            // If it's an update, and only one non-id field is editable (two fields total), then it's required even in an update.
            // (Otherwise, the update would be a no-op.)
            const isRequired = field.key === ID_FIELD.key || (isCreate && required) || (!isCreate && editableFields.length === 2);
            return `$${getFieldOrOneIdKey(field)}: ${type}${isRequired ? '!' : ''}`;
        })
        .join(', ');
    const mutationValues = editableFields
        .map(field => {
            const key = getFieldOrOneIdKey(field);
            return `${key}: $${key}`;
        })
        .join(', ');

    return gql`mutation(${mutationArguments}, $preview: Boolean) { ${isCreate ? 'create' : 'update'}${capitalize(
        key
    )}(${mutationValues}, preview: $preview) ${print(crudResponseGraphQl(entityDefinition))} }`;
};

export const createSearchFilter = ({
    page,
    pageSize = 50,
    search,
    sortField,
    sortOrder,
    userId,
    filters,
    columns,
    ...rest
}: SearchParams): string =>
    Object.entries({
        // columns,
        size: pageSize,
        from: !page ? 0 : (page - 1) * pageSize,
        ...(userId ? { userId } : {}),
        ...(search !== undefined ? { search: `"${search}"` } : {}),
        ...(sortField ? { sortField: `"${sortField}"` } : {}),
        sortOrder, // sortOrder is an enum type, so doesn't need to be surrounded by quotes
        ...(filters ? { filters: filtersToGraphQl(filters) } : {}),
        ...rest,
    })
        .filter(([_, value]) => value !== undefined)
        .map(([key, value]) => `${key}: ${value}`)
        .join(', ');

export const find = (entityDefinition: EntityDefinition<any>, id: number | string, fieldTree: FieldTree): DocumentNode =>
    gql`{ ${entityDefinition.key}(id: ${typeof id === 'string' ? `"${id}"` : id}) ${print(graphQlQuery(fieldTree))} }`;

export const search = <EntityType extends Entity, ScopeEntityType extends Entity>(
    entityDefinition: EntityDefinition<EntityType>,
    params: SearchParams,
    scopeEntityDefinition?: EntityDefinition<ScopeEntityType>,
    manyField?: ManyField,
    scopeEntity?: ScopeEntityType
): DocumentNode => {
    return scopeEntityDefinition && manyField && scopeEntity
        ? gql`{${scopeEntityDefinition.key}(id: ${scopeEntity.id}) { id ${manyField.key}(${createSearchFilter(
              params
          )}) { totalCount nodes ${print(graphQlQuery(params.columns))} } } } `
        : gql`{${getSearchKey(entityDefinition)}(${createSearchFilter(params)}) { totalCount nodes ${print(
              graphQlQuery(params.columns)
          )} } }`;
};

import { Button, Modal, Tooltip } from 'antd';
import { TooltipPlacement } from 'antd/es/tooltip';
import GraphQlIcon from 'components/icon/GraphQlIcon';
import { GRAPHQL_HOST } from 'config';
import { ObjectLiteral } from 'entities/EntityDefinition';
import { DocumentNode, print } from 'graphql';
import React, { CSSProperties, ReactNode, useEffect, useState } from 'react';

interface Props {
    query: DocumentNode;
    variables?: ObjectLiteral;
    position?: 'before' | 'after';
    childLabel?: string;
    style?: CSSProperties;
    buttonStyle?: CSSProperties;
    iconClassName?: string;
    iconStyle?: CSSProperties;
    iconOnly?: boolean;
    tooltipPlacement?: TooltipPlacement;
    children?: ReactNode;
}

export default function GraphQlExplorer({
    query,
    variables,
    position = 'before',
    childLabel = 'page',
    style = {},
    buttonStyle = {},
    iconClassName,
    iconStyle,
    iconOnly,
    tooltipPlacement,
    children,
}: Props) {
    const [visible, setVisible] = useState(false);
    const queryString = print(query);

    useEffect(() => {
        if (!visible) return;

        // @ts-ignore
        new window.EmbeddedExplorer({
            target: '.embedded-explorer-modal',
            graphRef: 'rw-graph@production',
            endpointUrl: `${GRAPHQL_HOST}/graphql`,
            persistExplorerState: false,
            initialState: {
                document: queryString,
                variables,
                displayOptions: {
                    showHeadersAndEnvVars: false,
                    docsPanelState: 'closed',
                    theme: 'light',
                },
            },
            // Custom `handleRequest` only needed to specify `credentials: 'include'`, so that the `cookie` header is sent.
            handleRequest: (endpointUrl: string, options: any) => fetch(endpointUrl, { ...options, credentials: 'include' }),
        });
        return () => document.getElementById('apollo-embedded-explorer')?.remove();
    }, [visible, queryString, variables]);

    const icon = <GraphQlIcon className={iconClassName} style={iconStyle} />;
    return (
        <>
            <span onClick={iconOnly ? () => setVisible(true) : undefined} style={style}>
                {position === 'after' && children}
                <Tooltip
                    title={`Explore the GraphQL query driving this ${childLabel}`}
                    placement={tooltipPlacement || 'topRight'}
                >
                    {iconOnly && icon}
                    {!iconOnly && (
                        <Button
                            shape="round"
                            icon={icon}
                            style={{
                                padding: 0,
                                width: 28,
                                height: 28,
                                margin: '0 6px',
                                ...buttonStyle,
                            }}
                            onClick={() => {
                                setVisible(true);
                            }}
                        />
                    )}
                </Tooltip>
                {position === 'before' && children}
            </span>
            {visible && (
                <Modal
                    title="GraphQL Explorer"
                    centered
                    visible={true}
                    onOk={() => setVisible(false)}
                    onCancel={() => setVisible(false)}
                    width="100%"
                >
                    <div className="embedded-explorer-modal" style={{ height: '80vh' }} />
                </Modal>
            )}
        </>
    );
}

import { Card, Table, Tooltip } from 'antd';
import EntityLink from 'components/entity/EntityLink';
import ErrorComponent from 'components/error/Error';
import FullPageLoader from 'components/loading/FullPageLoader';
import type { EditProps, Entity } from 'entities/EntityDefinition';
import { getIdPath } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { Field, RelationField } from 'entities/Field';
import type { EventEntity } from 'entities/rw/event/Event';
import { formatField, getFieldOrOneIdKey } from 'entities/Field';
import { EventType, formatEventType } from 'entities/rw/event/Event';
import { Difference, EventEffectType, formatEventEffectType } from 'entities/rw/event/EventEffect';
import React, { ComponentProps, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useQuery } from 'urql';
import colors from 'util/colors';
import { formatDate } from 'util/format';
import { find, summaryFieldTree } from 'util/graphql';
import { camelOrSnakeCaseToSentence, capitalize } from 'util/string';

const present = (value: unknown) => value !== undefined && value !== null;

interface Props<AffectedEntityType extends Entity> extends EditProps<EventEntity> {
    affectedEntity?: AffectedEntityType;
    isPreview?: boolean;
}

export default function EventView<AffectedEntityType extends Entity>({
    EntityForm,
    entityByKey,
    entityDefinition,
    entity,
    affectedEntity,
    isPreview,
}: Props<AffectedEntityType>) {
    const { created, user, type, entityKey, entityId, effects: effectsResult } = entity;
    const effects = effectsResult.nodes;
    const affectedEntityDefinition = entityByKey[entityKey];
    const [findResult] = useQuery({
        pause: !!affectedEntity || !entityId,
        query: find(affectedEntityDefinition, entityId as number, summaryFieldTree(affectedEntityDefinition)),
    });
    const { fetching, stale, data, error } = findResult;

    if (fetching || stale) return <FullPageLoader />;
    if (error) return <ErrorComponent message={error.message} />;

    if (!affectedEntity) affectedEntity = data?.[entityKey];

    // If the entity ID is present, but the entity is not found, and this event is a delete event, no surprise there!
    // Otherwise, assume the reason for a missing entity is that it has since been deleted.
    // In that case, fall back to showing just the ID.
    const formattedEntityKey = camelOrSnakeCaseToSentence(entityKey);
    const affectedEntityElement = affectedEntity ? (
        <>
            {formattedEntityKey} <EntityLink entityDefinition={affectedEntityDefinition} entity={affectedEntity} />
        </>
    ) : (
        <>
            {type === EventType.delete ? '' : '(since deleted)'} {formattedEntityKey} with ID {entityId}
        </>
    );

    return (
        <EntityForm entityDefinition={entityDefinition} initialEntity={entity} hideFields hideSubmits={isPreview}>
            On {formatDate(created as string, true, true)},{' '}
            <EntityLink entityDefinition={entityByKey[rw.user.User]} entity={user} /> {formatEventType(type, 'past')} the{' '}
            {affectedEntityElement}.
            <br />
            <br />
            <h3 style={{ marginBottom: 14 }}>Effects:</h3>
            {effects.map(({ type: effectType, entityKey: effectEntityKey, entityId, diffsJson }) => {
                const entityDefinition = effectEntityKey === entityKey ? affectedEntityDefinition : entityByKey[effectEntityKey];
                if (!entityDefinition) throw Error(`No entity found with key ${effectEntityKey}`);

                const { fields } = entityDefinition;
                // Assume the field is present
                const findField = (key: string): Field | RelationField =>
                    fields.find(field => key === getFieldOrOneIdKey(field)) as Field | RelationField;
                const fieldKeys = fields.map(getFieldOrOneIdKey);
                const diffs = (JSON.parse(diffsJson) as Difference[]).sort(
                    (a, b) => fieldKeys.indexOf(a.field) - fieldKeys.indexOf(b.field)
                );

                const isCreate = effectType === EventEffectType.create;
                const isDelete = effectType === EventEffectType.delete;
                const isUpdate =
                    effectType === EventEffectType.update ||
                    effectType === EventEffectType.softdelete ||
                    effectType === EventEffectType.restore;
                const title = (
                    <span style={{ fontSize: 15 }}>
                        {capitalize(formatEventEffectType(effectType, 'past'))} {camelOrSnakeCaseToSentence(entityKey)}
                        {entityId && (
                            <>
                                {' '}
                                {isDelete ? `#${entityId}` : <Link to={getIdPath(entityDefinition, entityId)}>#{entityId}</Link>}
                            </>
                        )}
                    </span>
                );

                const columns = [{ title: 'Field', dataIndex: 'field', key: 'field' }];
                if (isDelete || isUpdate) columns.push({ title: 'Old value', dataIndex: 'oldValue', key: 'oldValue' });
                if (isCreate || isUpdate) columns.push({ title: 'New value', dataIndex: 'newValue', key: 'newValue' });

                const dataSource = diffs.map(({ field: fieldKey, oldValue, newValue }) => {
                    const field = findField(fieldKey);
                    const format = (value: any): ReactNode => formatField(value, field, { shouldTruncate: true });
                    const oldValueFormatted = format(oldValue);
                    const newValueFormatted = format(newValue);
                    const isFieldUpdate = present(oldValueFormatted) && present(newValueFormatted);
                    const isFieldCreate = !isFieldUpdate && present(newValueFormatted);
                    const fieldSuffix = isFieldUpdate
                        ? `was updated from ${oldValueFormatted} to ${newValueFormatted}`
                        : isFieldCreate
                        ? `was created with value ${newValueFormatted}`
                        : `with value ${oldValueFormatted} was deleted`;
                    const fieldLabel = field ? capitalize(field.label) : fieldKey;
                    return {
                        key: fieldKey,
                        field: fieldLabel,
                        oldValue: oldValueFormatted,
                        newValue: newValueFormatted,
                        tooltip: `'${capitalize(fieldLabel)}' field ${fieldSuffix}`,
                        color: isFieldUpdate ? colors.updated : isFieldCreate ? colors.created : colors.deleted,
                    };
                });

                return (
                    <Card
                        key={effectEntityKey}
                        title={title}
                        size="small"
                        style={{ width: 'fit-content' }}
                        headStyle={{ background: '#eee' }}
                        bodyStyle={{ padding: 0 }}
                    >
                        <Table
                            size="small"
                            pagination={false}
                            dataSource={dataSource}
                            columns={columns}
                            components={{
                                body: {
                                    row: (props: ComponentProps<any>) => {
                                        const row = dataSource.find(({ key }) => key === props['data-row-key']);
                                        return (
                                            <Tooltip title={row?.tooltip}>
                                                <tr {...props} style={{ background: row?.color }} />
                                            </Tooltip>
                                        );
                                    },
                                },
                            }}
                        />
                    </Card>
                );
            })}
        </EntityForm>
    );
}

import { SortOrder as TableSortOrder } from 'antd/es/table/interface';

export enum SortOrder {
    asc = 'asc',
    desc = 'desc',
}

export const tableSortOrderToSortOrder = (tableSortOrder: TableSortOrder): SortOrder | undefined =>
    tableSortOrder === null ? undefined : tableSortOrder === 'ascend' ? SortOrder.asc : SortOrder.desc;

export const sortOrderToTableSortOrder = (sortOrder?: SortOrder): TableSortOrder =>
    !sortOrder ? null : sortOrder === SortOrder.asc ? 'ascend' : 'descend';

import * as Sentry from '@sentry/react';
import { Alert, Spin } from 'antd';
import { Store } from 'antd/lib/form/interface';
import User, { UserEntity } from 'entities/rw/user/User';
import React, { ComponentProps } from 'react';
import { useHistory } from 'react-router';
import { useMutation, useQuery } from 'urql';

const Spinner = () => (
    <Spin
        size="large"
        style={{ width: '100%', height: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    />
);

interface AuthContextContents {
    authenticatedUser?: UserEntity;
    login?: (store: Store, redirectPath: string) => Promise<void>;
    logout?: () => void;
}

const AuthContext = React.createContext<AuthContextContents>({});

export function AuthProvider({ children, ...props }: ComponentProps<any>) {
    const history = useHistory();
    const [, loginMutation] = useMutation<{ login: UserEntity }>(User.mutations?.login || '');
    const [, logoutMutation] = useMutation(User.mutations?.logout || '');
    const [authenticatedUserResult] = useQuery({ query: User.queries?.getAuthenticatedUser || '' });
    const { fetching, data, error } = authenticatedUserResult;
    if (data?.authenticatedUser) {
        const { id, username, email, firstName, lastName, googleId, hasPassword, roles }: UserEntity = data.authenticatedUser;
        Sentry.setUser({
            ip_address: '{{auto}}',
            id: `${id}`,
            email,
            username,
            firstName,
            lastName,
            googleId,
            hasPassword,
            roles,
        });
    }

    const value = {
        login: async (values: Store, redirectPath: string) => {
            const result = await loginMutation(values);
            if (result.error) throw new Error(`${result.error}`);

            if (redirectPath) history.push(redirectPath);
            else history.go(0);
        },
        authenticatedUser: data?.authenticatedUser,
        logout:
            data?.authenticatedUser &&
            (async () => {
                const result = await logoutMutation();
                if (result.error) throw new Error(`${result.error}`);

                Sentry.setUser(null);
                history.go(0);
            }),
    } as AuthContextContents;

    return (
        <AuthContext.Provider value={value} {...props}>
            {fetching ? <Spinner /> : error ? <Alert message={error.message} type="error" /> : children}
        </AuthContext.Provider>
    );
}

export const useAuth = () => React.useContext(AuthContext);

import { LockOutlined, MailOutlined } from '@ant-design/icons';
import { Button, Col, Divider, Form, Input, Row } from 'antd';
import { Store } from 'antd/lib/form/interface';
import { useAuth } from 'AuthContext';
import Error from 'components/error/Error';
import { GRAPHQL_HOST } from 'config';
import { getIndexPath } from 'entities/EntityDefinition';
import { SignOutRoute } from 'Pages';
import React from 'react';
import GoogleButton from 'react-google-button';
import { Redirect } from 'react-router-dom';
import { useQueryParams } from 'util/url';

const { useForm } = Form;

// TODO register for new account
export default function Login() {
    const [form] = useForm();
    const { redirectPath } = useQueryParams();

    const { authenticatedUser, login } = useAuth();
    if (authenticatedUser) return <Redirect to={getIndexPath(SignOutRoute)} />;
    if (!login) return <></>; // should never happen in practice

    return (
        <Row justify="center">
            <Col>
                <a
                    href={`${GRAPHQL_HOST}/auth/google${
                        redirectPath ? `?redirectPath=${redirectPath.replaceAll('&', encodeURIComponent('&'))}` : ''
                    }`}
                >
                    <GoogleButton />
                </a>
                <Divider>or</Divider>
                <Form
                    style={{ maxWidth: '300px' }}
                    form={form}
                    name="normal_login"
                    onFinish={async (values: unknown) => {
                        const errorFieldsForMessageSegment = {
                            'not found': [{ name: 'email', errors: ['Invalid email or password'] }],
                            'Invalid password': [{ name: 'email', errors: ['Invalid email or password'] }],
                            'with roles': [{ name: 'email', errors: ['User does not have admin permissions'] }],
                            'not a password user': [
                                {
                                    name: 'password',
                                    errors: ['A user with this email exists but does not have a password set. Try Google auth?'],
                                },
                            ],
                        };
                        try {
                            await login(values as Store, redirectPath);
                        } catch (e: unknown) {
                            const { message } = e as Error;
                            const errorFields = Object.entries(errorFieldsForMessageSegment).find(([key]) =>
                                message.includes(key)
                            )?.[1];
                            if (!errorFields) throw e;

                            form.setFields(errorFields);
                        }
                    }}
                >
                    <Form.Item name="email" rules={[{ required: true, message: 'email' }]}>
                        <Input autoFocus prefix={<MailOutlined className="site-form-item-icon" />} placeholder="Email" />
                    </Form.Item>
                    <Form.Item name="password" rules={[{ required: true, message: 'password' }]}>
                        <Input prefix={<LockOutlined className="site-form-item-icon" />} type="password" placeholder="Password" />
                    </Form.Item>
                    <Form.Item>
                        <Button style={{ width: '100%' }} type="primary" htmlType="submit">
                            Sign in
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
}

import { SortOrder } from 'components/search/SortOrder';
import type { EditProps, EntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import { CREATED_FIELD, FieldType } from 'entities/Field';
import type { FileEntity } from 'entities/rw/file/File';
import type { FolderEntity } from 'entities/rw/file/Folder';
import type { NotebookImageTagEntity } from 'entities/rw/notebook/NotebookImageTag';
import type { ProjectEntity } from 'entities/rw/project/Project';
import type { UserEntity } from 'entities/rw/user/User';
import { DocumentNode } from 'graphql';
import gql from 'graphql-tag';
import React from 'react';
import { History } from 'history';

export interface NotebookSessionEntity extends EntityBase {
    name: string;
    key: string;
    address: string;
    startTime?: number;
    notebookLifespan?: number;
    user: UserEntity;
    file: FileEntity;
    folder: FolderEntity;
    project: ProjectEntity;
    notebookImageTag: NotebookImageTagEntity;
    container?: string;
}

export interface NotebookSessionMutations {
    endSession: DocumentNode; // For admins.
}

const shorthand: ShorthandEntityDefinition<NotebookSessionEntity> = {
    key: rw.notebook.Session,
    shortLabel: 'session',
    defaultSortField: CREATED_FIELD.key,
    defaultSortOrder: SortOrder.desc,
    fields: [
        { key: 'name', editable: true, required: true },
        { key: 'key', excludeFromTable: true },
        { key: 'address', excludeFromTable: true },
        { key: 'hostname', excludeFromTable: true },
        { key: 'container', type: FieldType.json, searchable: false, excludeFromTable: true }, // not a db field
        { key: 'startTime', label: 'Startup time', type: FieldType.durationMillis },
        { key: 'notebookLifespan', editable: true, type: FieldType.number, excludeFromTable: true },
        { one: rw.user.User },
        { one: rw.project.Project, excludeFromTable: true },
        { one: rw.file.Folder, excludeFromTable: true },
        { one: rw.file.File, excludeFromTable: true },
        { one: rw.notebook.ImageTag, useShortLabel: true, excludeFromTable: true },
    ],
    Edit: ({ EntityForm, entityDefinition, entity }: EditProps<NotebookSessionEntity>) => {
        const { id, container } = entity;
        const { endSession } = entityDefinition.mutations as unknown as NotebookSessionMutations;

        return (
            <EntityForm
                entityDefinition={entityDefinition}
                initialEntity={entity}
                submits={
                    container
                        ? [
                              {
                                  messageKey: 'end',
                                  children: 'End session',
                                  loadingMessage: 'Ending session...',
                                  successMessage: 'Session ended',
                                  help: 'Destroy the container and clear the Redis cache.',
                                  submitMutation: endSession,
                                  transformMutationValues: () => ({ id }),
                                  onSubmitComplete: (_: any, history: History) => history.go(0),
                                  previewable: false,
                              },
                          ]
                        : []
                }
            />
        );
    },
    mutations: {
        endSession: gql`
            mutation ($id: Float!) {
                endNotebookSession(id: $id) {
                    id
                }
            }
        `,
    },
};

export default shorthand;

import { Tag, Typography } from 'antd';
import BooleanIcon from 'components/icon/BooleanIcon';
import { EntityDefinition } from 'entities/EntityDefinition';
import { Labeled } from 'Pages';
import React, { ReactNode } from 'react';
import ReactJson from 'react-json-view';
import { asArray } from 'util/array';
import { formatBytes, formatDate, formatDurationMillis } from 'util/format';
import { camelOrSnakeCaseToSentence, capitalize, isNumber, truncate } from 'util/string';

const { Text } = Typography;

export enum FieldType {
    string,
    longString,
    htmlString,
    email,
    boolean,
    bytes,
    number,
    date,
    durationMillis,
    password,
    enum,
    enumList,
    json,
    // `json...` types below are used as kind of 'virtual' fields nested under `json`-type fields.
    // They are never declared directly as `EntityDefinition::fields` types.
    // (Just declare an unstructured JSON field as a `json` type, and you should be good.)
    // `Field`s with the below types are derived from a JSON mega-object merged across a page of entities.
    // (See `EntitiesTable.tsx` for details.)
    jsonPrimitiveList, // Treated essentially like a string list (`enumList`), displayed as pill list in table cells.
    entityId, // Displays as an entity search-select in forms. Requires an 'entityKey' value also present in the form (`dependsOnField` below).
}

// TODO icons for any field (e.g. notification type should have a `<NotificationOutlined />` icon)
// TODO make Labeled::label required and remove Omit
// A field's `key` can be used for GraphQL queries/mutations, or for field identification unique across an entity type.
export interface FieldBase extends Omit<Labeled, 'label'> {
    label: string;
    // All fields with either `required` or `editable` are included in create forms.
    required?: boolean; // In create & update forms, required fields must be present to submit.
    default?: any; // Default value
    // Editable fields can be mutated via entity-detail forms (`EntityForm.tsx`).
    // Editable one-to-many relations provide an 'Add' tab under the `many` relation tab in the `one` entity's
    // detail page if the `many` entity has a `create` mutation.
    // This links to a create form for the `many` entity, with the `one` relation field populated.
    // Note that 'Remove' in this context can be achieved by deleting the relation object or un-setting the
    // `one` entity relation field on the `many` entity.
    editable?: boolean;
    searchable?: boolean; // Defaults to `true`.
    // `userScoped` is for fields that only make sense when scoped to the query context of a specific user.
    // Example: A `Project` has a `label` field that will always return `undefined` for queries where
    // `user(id: ...)` is not at the root of the query (this is what "user-scoped" means).
    // If the query _is_ user-scoped, this field will return the label with its `project_id`, and the root query's `user_id`.
    // In practice, all tabs under a specific user are user-scoped.
    // (Note that it would similarly make sense to have a concept of {entity}-scoped queries, where {entity} is any entity type.)
    userScoped?: boolean;
    hide?: boolean;
    hideNull?: boolean;
    writeOnly?: boolean; // Some fields, like `user::password`, can be mutated, but not queried.
    excludeFromTable?: boolean;
    excludeFromDetail?: boolean;
    excludeFromCreate?: boolean;
    // Summaries are used for concise formatted display of an entity, such as inline-search.
    // The label field (field with `name` key, or with the key specified in `labelField`) is always included in the summary.
    // Other fields must opt in (rather than opt out like table and detail inclusion).
    // Display follows order of declaration, with relations displayed before fields.
    // Relations in summaries are shown with the relation-entity's icon and label.
    // If no relations or fields are included, and no name is present, the summary will show the entity's ID.
    includeInSummary?: boolean;
    help?: ReactNode;
    options?: string[]; // For enum type fields
    formatOptions?: boolean; // For enum type fields: In forms, should the values be default-formatted (cased & spaced)?
    formatOption?: (option: string) => string; // For enum type fields: Format the provided (raw) option. Overrides `formatOptions` above.
    // In forms, don't provide an input for this field until the field with key `dependsOnField` has a valid value.
    dependsOnField?: {
        key: string; // Key of field on which this field depends.
        message?: string; // Defaults to 'A value for the field '{dependsOnField}' must be provided before '{thisField}'.'
    };
    graphQlTypeName?: string; // Only needed for enum fields in mutations
}
export interface Field extends FieldBase {
    type: FieldType; // defaults to `FieldType.string`
}

export interface OneField extends FieldBase {
    one: string;
    useShortLabel?: boolean; // Editable one-to-one relations provide an EntitySearchSelect over `from` entities to the `to` entity's edit form.
    crossDb?: boolean; // Is this relation in another DB? (If so, search/sorting/filtering is disabled for nested table columns belonging to this relation.)
}
export interface ManyField extends FieldBase {
    many: string; // Entity key for the many-side of the relation.
    // Instead of just showing `label` field(s) in a parent-scoped table for this nested relation key, show all fields normally shown in the entity's table.
    // Used for e.g. Role tables scoped to a parent. The other (not the parent) side of the Role is expanded, and shown along with the Role's level.
    expandTableFieldsFor?: string;
    includeInDetail?: boolean; // While `Field`s opt-out of the detail query, `OneToMany`s opt-in.
    hideTab?: boolean; // Each of an entity's many-relations are shown as a tab with a search page, unless this is `true`.
}
export type RelationField = OneField | ManyField;

export const isOne = (field?: FieldBase): field is OneField => !!field && 'one' in field;
export const isMany = (field?: FieldBase): field is ManyField => !!field && 'many' in field;
export const isRelation = (field?: FieldBase): field is RelationField => isOne(field) || isMany(field);

// For convenience, you can declare relation fields without their `key`/`label`, and populate their keys with `hydrateFields`
export interface OneFieldShorthand extends Omit<OneField, 'key' | 'label'> {
    key?: string;
    label?: string;
}
export interface ManyFieldShorthand extends Omit<ManyField, 'key' | 'label'> {
    key?: string;
    label?: string;
}
export type RelationFieldShorthand = OneFieldShorthand | ManyFieldShorthand;

// For convenience, you can declare fields without a label or type, and they will default to a string field type.
export interface FieldShorthand extends Omit<Field, 'label' | 'type'> {
    label?: string;
    type?: FieldType;
}
export type ShorthandField = FieldShorthand | RelationFieldShorthand;

export interface IdField extends Field {
    key: 'id';
    label: 'ID';
    type: FieldType.number | FieldType.string;
}
export const ID_FIELD: IdField = {
    key: 'id',
    label: 'ID',
    type: FieldType.number | FieldType.string,
};
export const CREATED_FIELD: Field = {
    key: 'created',
    label: 'Created',
    type: FieldType.date,
    hideNull: true, // Many tables had their `created` field added after many rows were already created.
};
export const DELETED_FIELD: Field = {
    key: 'deleted',
    label: 'Deleted',
    type: FieldType.date,
    hideNull: true, // Assume it's not deleted, and only show if it is.
    excludeFromTable: true,
};

export type EntityFields = [IdField, ...(Field | RelationField)[]];

export const getFieldOrOneIdKey = (field: Field | RelationField) => (isOne(field) ? `${field.key}Id` : field.key);
export const getRelationEntityKey = (field: RelationField): string => (isOne(field) ? field.one : field.many);

// See note above in `Field` wrt create/update edit-ability.
export const isFieldEditable = (field: FieldBase, isCreate = false): boolean => {
    const { editable = false, required = false, excludeFromCreate } = field;
    return (isCreate && !excludeFromCreate && (editable || required)) || (!isCreate && editable);
};

interface FormatFieldOptions {
    entityDefinition?: EntityDefinition<any>;
    field?: Field;
    shouldTruncate?: boolean;
    stringOnly?: boolean;
}

export const formatEnumOption = (option: string, field?: Field) => {
    const { formatOptions, formatOption } = field || {};
    return formatOption?.(option) || (formatOptions ? capitalize(camelOrSnakeCaseToSentence(option)) : option);
};

// Used for table cells and form values, or anywhere where entity field values need to be displayed in a generic way.
export const formatField = (
    value: any,
    field?: Field | RelationField,
    { entityDefinition, shouldTruncate = true, stringOnly }: FormatFieldOptions = {}
): ReactNode => {
    // if (typeof value === 'object' && entityDefinition) return getEntityLabel(entityDefinition, value);
    if (isRelation(field)) return typeof value === 'object' && value.id ? `${Number(value.id)}` : value;

    const type = field?.type;
    if (value === null || value === undefined || (type === FieldType.json && value === 'null')) {
        return stringOnly ? '—' : <span>&#8212;</span>; // null shown as long-dash
    }
    if (value === '') return stringOnly ? '' : <Text code>''</Text>;
    if (type === FieldType.boolean || typeof value == 'boolean') return <BooleanIcon value={value} />;
    if (type === FieldType.bytes) return formatBytes(value);
    if (type === FieldType.durationMillis) return formatDurationMillis(value);
    if (type === FieldType.number || isNumber(value)) return `${Number(value)}`;
    if (type === FieldType.date || (Date.parse(value) && new Date(value).toISOString() === value)) {
        return formatDate(value, !shouldTruncate);
    }
    if (type === FieldType.json) {
        const isObject = typeof value === 'object';
        const stringValue = isObject ? JSON.stringify(value) : `${value}`;
        if (stringOnly) return stringValue;
        return (
            <span onClick={e => e.stopPropagation()}>
                <ReactJson
                    src={isObject ? value : JSON.parse(value)}
                    collapsed={stringValue.length > 300}
                    style={{ minWidth: 340 }}
                    name={false}
                />
            </span>
        );
    }
    if (type === FieldType.enum) value = asArray(value);
    if (Array.isArray(value)) {
        value = value.map(v => formatEnumOption(v, field));
        return stringOnly ? (
            value.join(', ')
        ) : (
            <>
                {value.map((tag: any) => (
                    <Tag key={tag}>{tag}</Tag>
                ))}
            </>
        );
    }
    if (type === FieldType.password) return '<<hidden_user_password>>';
    if (shouldTruncate) return truncate(`${value}`, 150);
    return `${value}`;
};

export const convertStringFieldValue = (field: Field, value?: string): any => {
    const { type } = field;
    if (value === null || value === undefined || value === '' || (type === FieldType.json && value === 'null')) {
        return undefined;
    }
    if (isOne(field)) return { id: Number(value) }; // For one-relations, interpret value as an ID.
    if ([FieldType.boolean, FieldType.number, FieldType.entityId, FieldType.bytes, FieldType.durationMillis].includes(type)) {
        return JSON.parse(value);
    }

    return `${value}`;
};

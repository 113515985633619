import { RoleLabel } from 'components/entity/Role';
import type { ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { ProjectEntity } from 'entities/rw/project/Project';
import type { RoleEntityBase } from 'entities/rw/Role';
import { createRoleFields } from 'entities/rw/Role';
import type { UserEntity } from 'entities/rw/user/User';

export interface ProjectRoleEntity extends RoleEntityBase {
    project: ProjectEntity;
    user: UserEntity;
}

const shorthand: ShorthandEntityDefinition<ProjectRoleEntity> = {
    key: rw.project.Role,
    shortLabel: 'Membership',
    creatable: true,
    softDeletable: true,
    Label: RoleLabel,
    fields: createRoleFields(rw.project.Project, rw.user.User),
};

export default shorthand;

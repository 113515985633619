import { Alert, Row, Space, Tabs } from 'antd';
import Error from 'components/error/Error';
import EntityForm from 'components/form/EntityForm';
import SubmitButton from 'components/form/SubmitButton';
import SearchPagination from 'components/search/SearchPagination';
import UrlSegmentTabs from 'components/tabs/UrlSegmentTabs';
import { findEntityDefinition } from 'entities/Entities';
import { getLabel } from 'entities/EntityDefinition';
import { findDisplayablePage } from 'Pages';
import React, { useState } from 'react';
import { useParams } from 'react-router';
import RouteParams from 'types/RouteParams';
import { capitalize } from 'util/string';

const { TabPane } = Tabs;

export default function EntityIndex() {
    const { pageKey, tab } = useParams<RouteParams>();
    const [errorMessage, setErrorMessage] = useState<string>('');

    const displayablePage = findDisplayablePage(pageKey);
    if (displayablePage) return <displayablePage.Component />;

    const entityDefinition = findEntityDefinition(pageKey);
    if (!entityDefinition) return <Error message={`No page found with key ${pageKey}`} />;

    const { searchable = true, hideSearchTab, indexTabs = [], indexActions } = entityDefinition;
    const activeTab = tab || (searchable ? 'search' : indexTabs[0]?.key || 'search');

    return (
        <>
            <UrlSegmentTabs activeTab={activeTab} createPath={tab => `/${pageKey}/${tab}`}>
                {!hideSearchTab && (
                    <TabPane key="search" tab="Search">
                        <SearchPagination entityDefinition={entityDefinition} />
                    </TabPane>
                )}
                {entityDefinition.creatable && (
                    <TabPane key="create" tab="Create">
                        <EntityForm entityDefinition={entityDefinition} />
                    </TabPane>
                )}
                {indexTabs.map(({ key, label, Component }) => (
                    <TabPane key={key} tab={capitalize(getLabel({ key, label }))}>
                        <Component entityDefinition={entityDefinition} />
                    </TabPane>
                ))}
                {indexActions?.length && (
                    <TabPane key="actions" tab="Actions">
                        <Row style={{ marginTop: 8 }}>
                            <Space align="start">
                                {indexActions.map(submit => (
                                    <SubmitButton
                                        key={submit.messageKey}
                                        entityDefinition={entityDefinition}
                                        mutationValues={{}}
                                        onError={error => setErrorMessage(error.message)}
                                        {...submit}
                                    />
                                ))}
                            </Space>
                        </Row>
                    </TabPane>
                )}
            </UrlSegmentTabs>
            {!!errorMessage && <Alert message={errorMessage} type="error" />}
        </>
    );
}

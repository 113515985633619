import type { LabelEntityBase, ShorthandEntityDefinition } from 'entities/EntityDefinition';
import { rw } from 'entities/EntityKeys';
import type { CampaignEntity } from 'entities/rw/campaign/Campaign';
import type { ProjectEntity } from 'entities/rw/project/Project';

export interface ProjectCampaignLabelEntity extends LabelEntityBase {
    project: ProjectEntity;
    campaign: CampaignEntity;
}

const shorthand: ShorthandEntityDefinition<ProjectCampaignLabelEntity> = {
    key: rw.campaign.ProjectLabel,
    shortLabel: 'Project label',
    creatable: true,
    softDeletable: true,
    fields: [
        { key: 'name', editable: true, required: true },
        { one: rw.project.Project, required: true },
        { one: rw.campaign.Campaign, required: true },
    ],
};

export default shorthand;

import { Divider } from 'antd';
import { EntityIcon } from 'entities/EntityIcons';
import { Field, getRelationEntityKey, RelationField } from 'entities/Field';
import React, { CSSProperties } from 'react';
import { capitalize } from 'util/string';

interface Props {
    parentField?: RelationField;
    field?: Field;
    pathLabels?: string[]; // Including the relation label
    style?: CSSProperties;
}

export default function FieldTreeLeafLabel({
    parentField,
    field,
    pathLabels = parentField ? [parentField.label] : undefined,
    style,
}: Props) {
    const fieldLabel = field ? capitalize(field.label) : '';
    return parentField && pathLabels ? (
        <span style={{ display: 'inline-flex', alignItems: 'center', ...style }}>
            <EntityIcon entityKey={getRelationEntityKey(parentField)} style={{ fontSize: 11 }} />
            {pathLabels.map((ancestorLabel, i) => (
                <span key={ancestorLabel} className="relation-label">
                    <span style={{ fontStyle: 'italic', fontWeight: 'normal', fontSize: 13 }}>{capitalize(ancestorLabel)}</span>
                    {!!(i < pathLabels.length - 1 || fieldLabel) && (
                        <Divider type="vertical" style={{ borderColor: '#888', margin: '0 6px' }} />
                    )}
                </span>
            ))}
            {fieldLabel}
        </span>
    ) : (
        <>{fieldLabel}</>
    );
}

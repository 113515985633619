import { Layout, Menu, Typography } from 'antd';
import { getEntityDefinitionLabel, getIndexPath, getPageOrEntityDefinitionLabel } from 'entities/EntityDefinition';
import { PageIcon } from 'entities/EntityIcons';
import { allPages, findParentPage } from 'Pages';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { capitalize } from 'util/string';

const { Sider } = Layout;
const { Title } = Typography;
const { SubMenu } = Menu;

interface Props {
    pageKey: string;
}

export default function Sidebar({ pageKey }: Props) {
    const pageParent = findParentPage(pageKey);
    const [selectingSubmenu, setSelectingSubmenu] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [openKey, setOpenKey] = useState<string | undefined>(pageParent?.key);
    if (!openKey && pageParent?.key) setOpenKey(pageParent?.key);

    return (
        <Sider theme="light" collapsed={collapsed} onCollapse={setCollapsed} collapsible width="unset">
            <Title level={4} style={{ textAlign: 'center', paddingTop: '0.5em' }}>
                <Link to="/">{collapsed ? 'RWA' : 'RW Admin'}</Link>
            </Title>
            <Menu
                theme="light"
                selectedKeys={pageKey ? [pageKey] : []}
                openKeys={openKey && (!collapsed || selectingSubmenu) ? [openKey] : []}
                mode="inline"
            >
                {allPages.map(page => {
                    if ('entityDefinitions' in page) {
                        const { key, icon, entityDefinitions } = page;
                        return (
                            <SubMenu
                                key={key}
                                icon={icon}
                                title={capitalize(getPageOrEntityDefinitionLabel(page))}
                                onTitleClick={() => {
                                    setOpenKey(key);
                                    setSelectingSubmenu(!selectingSubmenu);
                                }}
                            >
                                {entityDefinitions.map(subPage => {
                                    const { key } = subPage;
                                    return (
                                        <Menu.Item
                                            key={key}
                                            icon={<PageIcon page={subPage} />}
                                            onClick={() => setSelectingSubmenu(false)}
                                        >
                                            <Link to={getIndexPath(subPage)}>
                                                {capitalize(getEntityDefinitionLabel(subPage, true))}
                                            </Link>
                                        </Menu.Item>
                                    );
                                })}
                            </SubMenu>
                        );
                    }
                    const { mainNav, key, path } = page;
                    if (!mainNav) return null;

                    return (
                        <Menu.Item
                            key={path || key}
                            icon={<PageIcon page={page} />}
                            onClick={() => {
                                setOpenKey(undefined);
                                setSelectingSubmenu(false);
                            }}
                        >
                            <Link to={getIndexPath(page)}>{capitalize(getPageOrEntityDefinitionLabel(page))}</Link>
                        </Menu.Item>
                    );
                })}
            </Menu>
        </Sider>
    );
}

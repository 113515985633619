import { UndoOutlined } from '@ant-design/icons';
import { Tooltip, TreeDataNode, TreeSelect } from 'antd';
import FieldTreeLeafLabel from 'components/search/FieldTreeLeafLabel';
import { Entity, EntityDefinition } from 'entities/EntityDefinition';
import { EntityIcon } from 'entities/EntityIcons';
import { getRelationEntityKey } from 'entities/Field';
import { fieldPathsToFieldTree, FieldTree, isFieldTree } from 'entities/FieldTree';
import React from 'react';
import { fullTableFieldTree } from 'util/graphql';
import { capitalize } from 'util/string';

export interface FieldTreeDataNode extends TreeDataNode {
    value: string;
    children?: FieldTreeDataNode[];
}

const fieldTreeToData = (fieldTree: FieldTree, disabledValue?: string, runningPath: string[] = []): FieldTreeDataNode[] => {
    const { parentField } = fieldTree;
    return fieldTree.fields.map(fieldOrTree => {
        if (isFieldTree(fieldOrTree)) {
            const { parentField: childRelation } = fieldOrTree;
            if (!childRelation) {
                throw new Error(`Unexpected child field with nested fields but no relation: ${JSON.stringify(fieldOrTree)}`);
            }

            const relationKey = childRelation.key;
            const thisRunningPath = [...runningPath, relationKey];

            return {
                key: thisRunningPath.join(':'),
                value: thisRunningPath.join(':'),
                title: (
                    <>
                        <EntityIcon entityKey={getRelationEntityKey(childRelation)} />
                        <span style={{ fontStyle: 'italic', fontWeight: 'bold' }}>{capitalize(childRelation.label)}</span>
                    </>
                ),
                children: fieldTreeToData(fieldOrTree, disabledValue, thisRunningPath),
            };
        }

        const value = [...runningPath, fieldOrTree.key].join(':');
        return {
            key: value,
            value,
            title: <FieldTreeLeafLabel parentField={parentField} field={fieldOrTree} pathLabels={runningPath} />,
            disabled: value === disabledValue,
        };
    });
};

export const getLeafNodes = (
    nodes: FieldTreeDataNode[],
    isDefault = false,
    leafNodes: FieldTreeDataNode[] = []
): FieldTreeDataNode[] => {
    nodes.forEach(node => {
        if (!node.children) {
            if (!isDefault || !node.value.endsWith(':id')) leafNodes.push(node);
        } else getLeafNodes(node.children, isDefault, leafNodes);
    });
    return leafNodes;
};

export const fieldTreeToValue = (fieldTree?: FieldTree): string[] | undefined =>
    fieldTree && getLeafNodes(fieldTreeToData(fieldTree)).map(({ value }) => value);

export const valueToFieldTree = <EntityType extends Entity>(
    value: string[],
    entityDefinition: EntityDefinition<EntityType>
): FieldTree =>
    fieldPathsToFieldTree(
        value.map(v => v.split(':')),
        entityDefinition
    );

interface Props<EntityType extends Entity> {
    entityDefinition: EntityDefinition<EntityType>;
    scopeEntityKey?: string;
    value: FieldTree | undefined;
    onChange: (value: FieldTree) => void;
    onReset: () => void;
    showReset?: boolean;
}

export default function FieldTreeSelect<EntityType extends Entity>({
    entityDefinition,
    scopeEntityKey,
    value,
    onChange,
    onReset,
    showReset = false,
}: Props<EntityType>) {
    const treeSelectValue = fieldTreeToValue(value);
    const fullFieldTree = fullTableFieldTree(entityDefinition, scopeEntityKey !== undefined ? [scopeEntityKey] : undefined);
    // Disallow removing the last value (must be at least one value in the table).
    const treeData = fieldTreeToData(fullFieldTree, treeSelectValue?.length === 1 ? treeSelectValue[0] : undefined);
    return (
        <TreeSelect
            style={{ width: '100%' }}
            value={treeSelectValue}
            treeData={treeData}
            treeCheckable
            showArrow
            suffixIcon={
                showReset && (
                    <Tooltip title="Reset to default columns">
                        <UndoOutlined onClick={onReset} />
                    </Tooltip>
                )
            }
            placeholder="Select columns to display in the table"
            onChange={value => onChange(valueToFieldTree(value, entityDefinition))}
        />
    );
}

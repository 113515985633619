// These are used to reference other entities when defining relations in `EntityDefinition`s,
// to avoid circular references during declaration.

export const rw = {
    user: {
        User: 'user',
        Address: 'address',
        Person: 'person',
        Position: 'position',
        FileContact: 'fileContact',
        ContactType: 'contactType',
    },
    organization: {
        Organization: 'organization',
        Role: 'organizationRole',
        CampaignRole: 'campaignOrganizationRole',
        ProjectRole: 'projectOrganizationRole',
        ProjectLabel: 'projectOrganizationLabel',
        VirtualOrganization: 'virtualOrganization',
        ProjectEmbargo: 'organizationProjectEmbargo',
    },
    campaign: {
        Campaign: 'campaign',
        Role: 'campaignRole',
        ProjectRole: 'projectCampaignRole',
        ProjectLabel: 'projectCampaignLabel',
        ProjectEmbargo: 'campaignProjectEmbargo',
    },
    project: {
        Project: 'project',
        Role: 'projectRole',
        Label: 'projectLabel',
    },
    file: {
        Folder: 'folder',
        File: 'file',
        FileHistory: 'fileHistory',
        FileRelation: 'fileRelation',
    },
    archive: {
        Attachment: 'archiveAttachment',
        File: 'archiveFile',
        Package: 'archivePackage',
        Path: 'archivePath',
        SubmissionHistory: 'archiveSubmissionHistory',
    },
    filestore: {
        File: 'filestoreFile',
    },
    notebook: {
        HtmlCache: 'notebookHtmlCache',
        Image: 'notebookImage',
        ImageTag: 'notebookImageTag',
        Metadata: 'notebookMetadata',
        Session: 'notebookSession',
    },
    portal: {
        Campaign: 'campaignPortal',
        Organization: 'organizationPortal',
    },
    event: {
        Event: 'event',
        Effect: 'eventEffect',
    },
    subscription: {
        Subscription: 'entitySubscription',
        Notification: 'notification',
        NotificationRecord: 'notificationRecord',
    },
};

export const oikos = {
    Portal: 'portal',
};

export const allEntityKeys = Object.values(rw).flatMap(group => Object.values(group));

import pluralize from 'pluralize';

export function formatDurationMillis(millis: number): string {
    const seconds = millis / 1000;
    const minutes = Math.floor(seconds / 60);
    if (minutes > 0) return `${minutes}m ${(seconds % 60).toFixed(3)}s`;
    return `${seconds.toFixed(3)}s`;
}

export const formatDate = (date: string, includeTime = true, at = false) =>
    `${new Date(date).toLocaleDateString()}${includeTime ? ` ${at ? 'at ' : ''}${new Date(date).toLocaleTimeString()}` : ''}`;

export const formatBytes = (bytes: number): string => {
    const KB_BYTES = 1024;
    const MB_BYTES = KB_BYTES ** 2;
    const GB_BYTES = KB_BYTES ** 3;

    if (bytes >= GB_BYTES) return `${(bytes / GB_BYTES).toFixed(2)} GB`;
    if (bytes >= MB_BYTES) return `${(bytes / MB_BYTES).toFixed(2)} MB`;
    if (bytes >= KB_BYTES) return `${(bytes / KB_BYTES).toFixed(2)} KB`;
    return `${bytes} ${pluralize('byte', bytes)}`;
};
